import {
  IHasClaimAttachmentSearchInputErrors,
  IClaimAttachmentsSearchRequest,
} from '../../../api/claimAttachments/interface';
import { regex } from './regex';
import { InputValidationProp } from '@uicl/ui-core/dist/Atoms/Input/types';

export const searchInputs = (
  searchRequest: IClaimAttachmentsSearchRequest,
  payerAttachmentErrors: IHasClaimAttachmentSearchInputErrors
) => {
  return [
    {
      label: 'Attachment ID',
      domID: 'test-attachmentId',
      initialValue: searchRequest.attachmentId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPAAttachmentId ? 'Invalid Attachment ID' : undefined,
      validationKey: 'hasErrorPAAttachmentId',
      hasError: payerAttachmentErrors.hasErrorPAAttachmentId,
      initialValidationState: payerAttachmentErrors.hasErrorPAAttachmentId
        ? ('invalid' as InputValidationProp)
        : ('valid' as InputValidationProp),
      minLength: 1,
      maxLength: 80,
      regex: regex.attachmentId,
      key: 'attachmentId',
    },
    {
      label: 'Payer ID',
      domID: 'test-payerId',
      initialValue: searchRequest.payerId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPAPayerID ? 'Invalid Payer ID' : undefined,
      validationKey: 'hasErrorPAPayerID',
      hasError: payerAttachmentErrors.hasErrorPAPayerID,
      initialValidationState: payerAttachmentErrors.hasErrorPAPayerID
        ? ('invalid' as InputValidationProp)
        : ('valid' as InputValidationProp),
      minLength: 5,
      maxLength: 5,
      regex: regex.payerId,
      key: 'payerId',
    },
    {
      label: 'Billing Provider Tax ID',
      domID: 'test-billingProviderTaxId',
      initialValue: searchRequest.billingProviderTaxId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPABillingProviderTaxID
        ? 'Invalid Billing Provider Tax ID'
        : undefined,
      validationKey: 'hasErrorPABillingProviderTaxID',
      hasError: payerAttachmentErrors.hasErrorPABillingProviderTaxID,
      initialValidationState: payerAttachmentErrors.hasErrorPABillingProviderTaxID
        ? ('invalid' as InputValidationProp)
        : ('valid' as InputValidationProp),
      minLength: 1,
      maxLength: 11,
      regex: regex.billingProviderTaxId,
      key: 'billingProviderTaxId',
    },
    {
      label: 'Insured ID',
      domID: 'test-insuredId',
      initialValue: searchRequest.insuredId,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPAInsuredID ? 'Invalid Insured ID' : undefined,
      validationKey: 'hasErrorPAInsuredID',
      hasError: payerAttachmentErrors.hasErrorPAInsuredID,
      initialValidationState: payerAttachmentErrors.hasErrorPAInsuredID
        ? ('invalid' as InputValidationProp)
        : ('valid' as InputValidationProp),
      minLength: 1,
      maxLength: 60,
      regex: regex.insuredId,
      key: 'insuredId',
    },
    {
      label: 'Patient Last Name',
      domID: 'test-patientLastName',
      initialValue: searchRequest.patientLastName,
      width: '23%',
      marginBottom: 10,
      errorMessage: payerAttachmentErrors.hasErrorPatientLastName ? 'Invalid Patient Last Name' : undefined,
      validationKey: 'hasErrorPatientLastName',
      hasError: payerAttachmentErrors.hasErrorPatientLastName,
      initialValidationState: payerAttachmentErrors.hasErrorPatientLastName
        ? ('invalid' as InputValidationProp)
        : ('valid' as InputValidationProp),
      minLength: 1,
      maxLength: 60,
      regex: regex.patientLastName,
      key: 'patientLastName',
    },
  ];
};
