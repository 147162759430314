import { CollapsePanel, CollapsePanelDetails, CollapsePanelTitle } from '@uicl/ui-core/dist';
import React from 'react';
import { IEligibilityOverviewResponse } from 'api/HistoricalTransactionDetailView/interfaces';
import { DefaultExpandList } from '../../../api/HistoricalTransactionDetailView/interfaces';
interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}
const AaaErrorComponent: React.FC<IProps> = ({
  handlePanelChange,
  eligibilityResponse,
  defaultExpand,
  setDefaultExpand,
}) => {
  const renderErrors = () =>
    eligibilityResponse &&
    eligibilityResponse?.aaaErrors.map((result) => {
      return (
        <div style={{ border: '1px solid red', marginBottom: 5, padding: 5 }}>
          An AAA error has occurued <br />
          Code: {result.code} <br />
          Description: {result.description} <br />
          Follow Up Acton: {result.followupAction} <br />
          Location: {result.location}
          {result?.possibleResolutions && result?.possibleResolutions.length > 1 && (
            <div>
              <div> Possible Resolutions:</div>
              {result?.possibleResolutions?.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          )}
        </div>
      );
    });

  return (
    <div data-testid="aaa-error-container">
      {eligibilityResponse?.aaaErrors && eligibilityResponse?.aaaErrors.length > 0 && (
        <CollapsePanel
          dataTestId="test-collapsePanel_errors"
          expanded={defaultExpand.aaaerror}
          onChange={handlePanelChange('aaaerror')}
        >
          <div>
            <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
              AAA Errors
            </CollapsePanelTitle>
          </div>
          <CollapsePanelDetails>{renderErrors()}</CollapsePanelDetails>
        </CollapsePanel>
      )}
    </div>
  );
};

export default AaaErrorComponent;
