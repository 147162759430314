import { IClaimFilterRequestBody } from '../api/claimSearch/interface';
import jwt_decode from 'jwt-decode';

export function isValidToken(token: any) {
  const decodeObject: any = jwt_decode(token);
  if (Date.now() >= decodeObject.exp * 1000) {
    return false;
  } else {
    return true;
  }
}

export function stringToBoolean(value: string) {
  if (value) {
    if (!isNaN(parseInt(value))) {
      return value === '1';
    } else {
      return value.toLocaleLowerCase() === 'true';
    }
  } else {
    return false;
  }
}

export function stringToNumber(value: string) {
  return parseInt(value);
}


// export const validateClaimFilterRequestBody = (data: IClaimFilterRequestBody): { isValid: boolean; errors: Record<string, string> } => {
//   const errors: Record<string, string> = {};

//   if (!/^[a-zA-Z0-9]{5}$/.test(data.payerId)) {
//     errors.payerId = "must be 5 alphanumeric characters.";
//   }

//   if (!/^\d{4,5}$/.test(data.cpsCarrierId)) {
//     errors.cpsCarrierId = "must be 4-5 numeric digits.";
//   }

//   if (!/^[a-zA-Z0-9]{1,35}$/.test(data.patientFirstName)) {
//     errors.patientFirstName = "must be 1-35 alphanumeric characters.";
//   }

//   if (!/^[a-zA-Z0-9]{1,60}$/.test(data.patientLastName)) {
//     errors.patientLastName = "must be 1-60 alphanumeric characters.";
//   }

//   if (!/^\d{10}$/.test(data.billingProviderNpi)) {
//     errors.billingProviderNpi = "must be 10 digits.";
//   }

//   if (!/^\d{10}$/.test(data.renderingProviderNpi)) {
//     errors.renderingProviderNpi = "must be 10 digits.";
//   }

//   if (!/^\d{10}$/.test(data.claimId)) {
//     errors.claimId = "must be 10 digits.";
//   }

//   if (!/^\d{9}$/.test(data.submitterId)) {
//     errors.submitterId = "must be 9 digits.";
//   }

//   if (!/^\d{10}$/.test(data.db2Key)) {
//     errors.db2Key = "must be 10 digits.";
//   }

//   if (!/^[a-zA-Z0-9]{1,50}$/.test(data.controlNumber)) {
//     errors.controlNumber = "must be 1-50 alphanumeric characters.";
//   }

//   return {
//     isValid: Object.keys(errors).length === 0,
//     errors,
//   }
// }


