import React, { ChangeEvent, useEffect, useState, SyntheticEvent } from 'react';
import SearchComponent from '../../components/EraSearch/SearchComponent';
import {
  IEraSearchRequestBody,
  DateFilter,
  IEraSerchResults,
  TSortingFieldEraSearch,
  ESortingDirectionEraSearch,
  IHasEraSearchInputErros,
} from '../../api/EraSearch/interfaces';
import { getEraSearchDetails } from '../../api/EraSearch';
import EraSearchTableData from '../../components/EraSearch/EraSearchTableData';
import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';
import { Button, Next, Previous, Text } from '@uicl/ui-core/dist';
import { initialEraSearchBody } from '../../api/EraSearch/mockResponses';
import EraDrawer from '../../components/EraSearch/EraView835Modal';
import { handleDocumentBodyScroll } from '../../utils/constants';
import { IEraSearchDetailResponse } from '../../api/EraSearchDetailView/interface';
import moment from 'moment';
import { setAppError } from '../../reduxStore/authSlice';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { IEraView835Data } from '../../api/view835/interface';
import { getView835Data } from '../../api/view835/index';
import getCurrentDate from '../../utils/getCurrentDate';

const limit = 20;

const EraSearch = () => {
  const [eraSearchRequestBody, setEraSearchRequestBody] = useState<IEraSearchRequestBody>(initialEraSearchBody);
  const [eraSearchResult, setEraSearchResult] = useState<IEraSerchResults[]>([]);
  const [sortingDirection, setSortingDirection] = useState<ESortingDirectionEraSearch>(
    ESortingDirectionEraSearch.DESCENDING
  );
  const [sortingField, setSortingField] = useState<string>('transactionId');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pagingOptions, setPagingOptions] = useState<IPagingOptions>({} as IPagingOptions);

  const [openEraDetail, setOpenEraDetail] = React.useState(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [eraSearchTransactionResult, setEraSearchTransactionResult] = useState<IEraSearchDetailResponse>();
  const dispatch = useAppDispatch();

  const [view835Content, setView835Content] = useState('');
  const [showValidationMessage, setShowErrorMessage] = useState(false);
  const [showDisplayMessage, setShowDisplayMessage] = useState('');
  const [isView835PopupOpen, setIs835PopupOpen] = useState(false);
  const [reloadView, setReloadView] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  let isView835Clicked = false;

  useEffect(() => {
    //load only when a search button is clicked
    if (firstLoad) {
      handleOnSearch(page);
    }
  }, [sortingDirection, sortingField, page, reloadView, firstLoad]);

  const handleOnReset = () => {
    const copy = { ...initialEraSearchBody };

    copy.dateRangeFilter = {
      field: 'checkEftDate',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
    setFirstLoad(false);
    setShowErrorMessage(false);
    setReloadView((prev) => !prev);
    setEraSearchRequestBody(copy);
    setPage(1)
  };

  const handleOnSearch = async (newSearchOffset?: number) => {
 
    
    try {
      //transaction id if present, start date cannot go over 15months from current date and end date cannot go 3 days over the current date
      const copy = { ...eraSearchRequestBody };

      setShowErrorMessage(false);
      setShowDisplayMessage('');

      //inputcheck used to check if there are values.
      //Not validating for transactionid.
      const currentdate = getCurrentDate();
      const start = moment(copy.dateRangeFilter.startDate);
      const end = moment(copy.dateRangeFilter.endDate);
      const endDateDiff = end.diff(currentdate, 'days', true);
      const startDateDiff = start.diff(currentdate, 'days', true);
      const startMonthiff = start.diff(currentdate, 'months', true);

      const endMonth = moment(copy.dateRangeFilter.endDate);

      const monthDiff = start.diff(endMonth, 'days', true);
      // const endMonthiff = start.diff(endMonth, 'months', true);

      const daysInMonths = moment(copy.dateRangeFilter.startDate).daysInMonth();
      //end date validation can only be between 3 days from current date
      if (endDateDiff >= 3) {
        setShowErrorMessage(true);
        setShowDisplayMessage('The end date provided is not valid');
        setFirstLoad(false);
        return;
      }
      if (copy.transactionId != '') {
        //expect a different validation

        if (startMonthiff < -15) {
          setShowErrorMessage(true);
          setShowDisplayMessage("The start and end dates provided must be within 15 months of today's date");
          setFirstLoad(false);
          return;
        }
      } else {
        // if there are no values in the input field, you can search only within a day date range
        if (
          copy.payerId == '' &&
          copy.billingNpi == '' &&
          copy.checkEftAmount == null &&
          copy.checkEftNumber == '' &&
          copy.insuredId == '' &&
          copy.taxId == ''
        ) {
          if (startDateDiff != endDateDiff) {
            setShowErrorMessage(true);
            setShowDisplayMessage('Please narrow your search to 1 day, or enter additional search filters');
            setFirstLoad(false);
            return;
          }
        } else {
          // start date cannot go back over 30days
          if (daysInMonths >= 31) {
            //has 31 days
            if (monthDiff < -30) {
              setShowErrorMessage(true);
              setShowDisplayMessage('Please narrow your search to 30 days');
              setFirstLoad(false);
              return;
            }
          } else {
            //has 30 days
            if (monthDiff < -29) {
              setShowErrorMessage(true);
              setShowDisplayMessage('Please narrow your search to 30 days');
              setFirstLoad(false);
              return;
            }
          }

          //start date cannot be 3 days greater than end date
          if (startDateDiff > 3) {
            setShowErrorMessage(true);
            setShowDisplayMessage('The start date provided is not valid');
            setFirstLoad(false);
            return;
          }

          //start date cannot be greater than end date
          if (startDateDiff > endDateDiff) {
            setShowErrorMessage(true);
            setShowDisplayMessage('The start provided must not be greater than end date');
            setFirstLoad(false);
            return;
          }
        }
      }

      const offset = (page - 1) * limit;

      copy.checkEftAmount = copy.checkEftAmount == null ? null : parseFloat(copy.checkEftAmount!.toString());


      const requestBody = {
        ...copy,
        sorting: {
          direction: sortingDirection,
          fields: [sortingField],
        },
        offset: newSearchOffset == 0 ? newSearchOffset : offset,
        limit,
      };

      if(newSearchOffset == 0){
        setPage(1)
      } 

      setShowDisplayMessage('');
      setShowErrorMessage(false);
      const response = await getEraSearchDetails(requestBody, dispatch);
      setEraSearchResult(response.results);
      setTotalCount(response.totalCount);
      setPagingOptions(response.pagingOptions);
      setFirstLoad(true);
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
    }
  };

  const handleOnChange = (e?: ChangeEvent<HTMLInputElement>, dateFilterOption?: DateFilter) => {
    const copy = { ...eraSearchRequestBody };

    if (e) {
      const {
        target: { name, value },
      } = e;
      if (name == 'checkEftAmount') {
        copy[name] = value == '' ? null : (value as any);
      } else {
        copy[name] = value;
      }
    }

    setEraSearchRequestBody(copy);
  };

  const parseEraView835Data = async (item: IEraView835Data) => {
    const view835Data_Content = await getView835Data(item.transactionId, dispatch);
    const atobstring = atob(view835Data_Content.eraDataResponse);
    const parts = atobstring.split('~');
    const decodedstring = parts.join('~' + '\n');
    return decodedstring;
  };

  const onClickOfView835 = async (e: any, item: IEraView835Data) => {
    try {
      isView835Clicked = true;
      setOpenEraDetail(false);
      const response = await parseEraView835Data(item);
      setView835Content(response);
      setIs835PopupOpen(true);
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
    }
  };

  const onOpenView835FromDrawer = () => {
    try {
      openEraDetail ? null : setOpenEraDetail(false);

      setIs835PopupOpen(true);
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
    }
  };

  const onClickThrough = async (e: any, item: IEraView835Data) => {
    try {
      if (isView835Clicked) return;
      e.stopPropagation();
      const response = await parseEraView835Data(item);
      handleDocumentBodyScroll(false);
      setOpenEraDetail(true);
      console.log("onClickThrough",item.transactionId);
      
      setTransactionId(item.transactionId);

      setView835Content(response);
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
    }
  };

  const handlePageNext = () => {
    setPage((prev) => prev + 1);
  };

  const handlePagePrevious = () => {
    /* istanbul ignore next */
    setPage((prev) => prev - 1);
  };

  const handleClosePopup = () => {
    setIs835PopupOpen(false);
    isView835Clicked = false;
  };

  const handleSavePopup = () => {
    const content = view835Content;
    const blob = new Blob([content], { type: 'text/plain' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'View835.txt';
    document.body.appendChild(link);
    link.click();
    handleClosePopup();
  };
  const onCloseDrawer = () => {
    handleDocumentBodyScroll(true);
    setOpenEraDetail(false);
  };
  return (
    <div data-testid="era-search-page-testid" style={{ marginLeft: 100 }}>
      <div style={{ marginTop: 70, height: '80vh' }}>
        <SearchComponent
          eraSearchRequestBody={eraSearchRequestBody}
          handleOnReset={handleOnReset}
          handleOnSearch={handleOnSearch}
          handleOnChange={handleOnChange}
        />
        {showValidationMessage && (
          <p
            itemID="pErrorMessage"
            style={{ display: 'flex', justifyContent: 'center', color: 'red' }}
            data-testid="eraErrorMessage"
          >
            {showDisplayMessage}
          </p>
        )}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {/* @ts-ignore */}
          {firstLoad && (
            <Text variant="small" className="pagination-text-color">
              {pagingOptions.offset} of {totalCount}
            </Text>
          )}

          <div style={{ display: 'flex' }}>
            <Button
              buttonType="deEmphasized"
              dataTestId={'left-arrow'}
              domID={'left-arrow'}
              icon={Previous}
              size="small"
              type="button"
              className="action-buttons"
              onClick={handlePagePrevious}
              disabled={page === 1 ? true : false}
            />
            <Button
              buttonType="deEmphasized"
              dataTestId={'right-arrow'}
              domID={'right-arrow'}
              icon={Next}
              size="small"
              type="button"
              className="action-buttons"
              disabled={pagingOptions.morePages ? false : true}
              onClick={handlePageNext}
            />
          </div>
        </div>
        {firstLoad && (
          <div>
            <EraSearchTableData
              eraSearchResult={eraSearchResult}
              onClickThrough={onClickThrough}
              onClickOfView835={onClickOfView835}
              setSortingDirection={setSortingDirection}
              setSortingField={setSortingField}
              openEraDetail={openEraDetail}
              onCloseDrawer={onCloseDrawer}
              eraSearchTransactionResult={eraSearchTransactionResult}
              transactionId={transactionId}
              setView835Content={setView835Content}
              onOpenView835FromDrawer={onOpenView835FromDrawer}
              setEraSearchTransactionResult={setEraSearchTransactionResult}
            />
          </div>
        )}
        <EraDrawer isOpen={isView835PopupOpen} onClose={handleClosePopup} onSave={handleSavePopup}>
          {view835Content}
        </EraDrawer>
      </div>
    </div>
  );
};

export default EraSearch;
