import React from 'react';

interface IProps {
  style?: object;
}

const Directions: React.FC<IProps> = ({ style }) => {
  return (
    <ul style={style}>
      <li>Fill in Account Info (required)</li>
      <li>Add Users (at least 1 required)</li>
      {/* <li>Add Legal Entities (at least 1 required)</li>*/}
      {/*TODO: in the future, maybe we link to a wiki*/}
    </ul>
  );
};

export default Directions;
