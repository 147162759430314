import { IUserPermission } from '../../api/user/interface';
import { IPermissionResourceType, IPermissionResourceTypes } from './interfaces';

export const checkUserPermission = (
  permissionResourceType: IPermissionResourceType,
  userPermissions: IUserPermission[]
): boolean => {
  return userPermissions.some(
    (userPermission) =>
      userPermission.resourceType.toLowerCase() === permissionResourceType.resourceType.toLowerCase() &&
      userPermission.resourcePermissions.some(
        (rp) => rp?.toLowerCase() === permissionResourceType.resourcePermission.toLowerCase()
      )
  );
};

export const permissionResourceTypes = {
  searchAttachments: {
    resourceType: 'AttachmentSearch',
    resourcePermission: 'Read',
  },
  readAttachmentDetails: {
    resourceType: 'AttachmentDetails',
    resourcePermission: 'Read',
  },
  readAttachmentFiles: {
    resourceType: 'AttachmentFiles',
    resourcePermission: 'Read',
  },
} as IPermissionResourceTypes;
