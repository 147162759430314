import React, { memo } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { IEligibilityOverviewResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { historicalFrequencyLimitationsTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  isFixedHeader: boolean;
}
const HistoricalFrequencyLimitationsDetailData: React.FC<IProps> = ({ eligibilityResponse, isFixedHeader }) => {
  const handleMapping = () =>
    eligibilityResponse?.frequencyLimitations
      ? eligibilityResponse?.frequencyLimitations.map((item) => {
          return {
            procedureCode: item.procedureCode,
            plan: item.plan,
            restriction: item.restriction,
            lastVisit: item.lastVisit,
          };
        })
      : [];

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft: 50, paddingRight: 20 }}>
      <Grid
        dataTestId="eligibility-frequencylimitationsdetail-grid"
        domID="eligibility-frequencylimitations-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={historicalFrequencyLimitationsTableColumns}
        isFixedHeader={isFixedHeader}
        emptyGridMessage="Not Returned by Payer"
      />
    </div>
  );
};

export default memo(HistoricalFrequencyLimitationsDetailData);
