export const attachmentSearchColumns = new Set([
  {
    dataName: 'patientFirstName',
    text: 'Patient First Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'patientLastName',
    text: 'Patient Last Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'attachmentId',
    text: 'Attachment ID',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'requestDate',
    text: 'Date',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'dateOfService',
    text: 'Date of Service',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'amount',
    text: 'Amount',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'billingProvider',
    text: 'Billing Last Name',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'actions',
    text: '',
    sortable: false,
    isSorted: 0,
  },
]);
