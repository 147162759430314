import React from "react";
import { Row, Col, Input, Text } from "@uicl/ui-core/dist";
import { IEligibilityInquiryRequest } from "../../api/eligibilityInquiry/interface";

interface IProps{
    eligibilityInquiryRequestBody: IEligibilityInquiryRequest;
    handleInputChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
}

const PayerDetailsComponent:React.FC<IProps> = ({ eligibilityInquiryRequestBody, handleInputChange }) => {
    return (<>
        <Row>
            <Col sm={3}>
                <Text variant="largeBold" className="eligibility-inquiry-header">Payer</Text>
            </Col>
        </Row>
        <Row>
            <Col sm={3}>
                <Input
                    aria-label="Payer"
                    dataTestId="test-payerId"
                    domID="payer"
                    name="payerId"
                    label="Payer *"
                    size="medium"
                    maxLength={5}
                    placeholder="Payer ID"
                    errorMessage="Invalid Payer ID"
                    value={eligibilityInquiryRequestBody.payerId}
                    regex="^[a-zA-Z0-9]{5}$"
                    onChange={handleInputChange} />
            </Col>
        </Row>
    </>);
};

export default PayerDetailsComponent;
