import React, { memo } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { IEligibilityOverviewResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { historicalCoverageTableColumns } from './HistoricalGridDetailsTableColumns';

interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  isFixedHeader: boolean;
}
const HistoricalCoverageDetailData: React.FC<IProps> = ({ eligibilityResponse, isFixedHeader }) => {
  const handleMapping = () =>
    eligibilityResponse?.coverage
      ? eligibilityResponse?.coverage.map((item) => {
          return {
            procedureCode: item.procedureDetail,
            inNetwork: item.inNetwork,
            outNetwork: item.outOfNetwork,
            deductibleApplies: item.isDeductibleApplies ? 'Yes' : ' ',
            message: item.message,
          };
        })
      : [];
  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft: 50, paddingRight: 20 }}>
      <Grid
        dataTestId="eligibility-coveragedetail-grid"
        domID="eligibility-coverage-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={historicalCoverageTableColumns}
        isFixedHeader={isFixedHeader}
        emptyGridMessage="Not Returned by Payer"
      />
    </div>
  );
};

export default memo(HistoricalCoverageDetailData);
