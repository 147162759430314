import moment, { Moment } from 'moment';

export class DateWrapper {
  private momentInstance: Moment | null = null;
  private dateFormat?: string;

  constructor(date?: string, dateFormat?: string) {
    if (date && dateFormat) {
      this.momentInstance = moment(date, dateFormat);
    } else if (date) {
      this.momentInstance = moment(date);
    } else {
      this.momentInstance = moment();
    }
  }

  /**
   * Sets the month. Accepts numbers from 1 to 12, but subtracts 1 to adapt to moment's
   *
   * @param month - The month to set (1-12).
   */
  public setMonth(month: string): void {
    const monthNumber = Number.parseInt(month);

    if (!this.momentInstance) {
      throw new Error('Moment instance is not initialized.');
    }

    if (isNaN(monthNumber)) {
      throw new Error('Month must be in range 1-12.');
    }

    // Validate month range
    if (monthNumber < 1 || monthNumber > 12) {
      throw new Error('Month must be in range 1-12.');
    }

    // Subtract 1 from month to match moment's 0-index months
    this.momentInstance.month(monthNumber - 1);
  }

  /**
   * Sets the year.
   *
   * @param year - The year to set.
   */
  public setYear(year: number): void {
    if (!this.momentInstance) {
      throw new Error('Moment instance is not initialized.');
    }

    // No specific validation for year is done here; moment will handle invalid values as per its own logic
    this.momentInstance.year(year);
  }

  /**
   * Sets the day of the month from a string.
   * Parses the string as an integer and uses it as the day value.
   *
   * @param day - The day of the month to set, as a string.
   */
  public setDay(day: string): void {
    if (!this.momentInstance) {
      throw new Error('Moment instance is not initialized.');
    }

    const dayNumber = parseInt(day, 10);

    // Validate day range and if it's a valid number
    if (isNaN(dayNumber) || dayNumber < 1 || dayNumber > 31) {
      throw new Error('Day must be a valid number in the range of 1-31.');
    }

    this.momentInstance.date(dayNumber); // This sets the day of the month
  }

  public formatDateString(dateFormat: string): string {
    if (!this.momentInstance) {
      throw new Error('Moment instance is not initialized.');
    }

    return this.momentInstance.format(dateFormat);
  }

  public formatToDate(): Date {
    if (!this.momentInstance) {
      throw new Error('Moment instance is not initialized.');
    }

    return new Date(this.momentInstance.format(this.dateFormat));
  }
}

export default DateWrapper;
