import React, { Dispatch, memo, SyntheticEvent, useEffect, useState } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { eraSearchTableColumns } from './EraSearchTableColumns';
import { ESortingDirectionEraSearch, IEraSerchResults } from '../../api/EraSearch/interfaces';
import { Download } from '@uicl/ui-core/dist';
import { IEraSearchDetailResponse } from '../../api/EraSearchDetailView/interface';
import EraSearchDetailDrawer from './EraSearchDetailDrawer';
import { getEraTransactionDetails } from '../../api/EraSearchDetailView';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { setAppError } from '../../reduxStore/authSlice';
import moment from 'moment';
enum tableSortingDirection {
  SORT_ASCENDING = 'SORT_ASCENDING',
  SORT_DESCENDING = 'SORT_DESCENDING',
}

interface IProps {
  onClickThrough: (e: any, row: any) => void;
  eraSearchResult: IEraSerchResults[];
  onClickOfView835: (e: any, item: IEraSerchResults) => void;
  setSortingDirection: Dispatch<React.SetStateAction<ESortingDirectionEraSearch>>;
  setSortingField: Dispatch<React.SetStateAction<string>>;
  onCloseDrawer: () => void;
  eraSearchTransactionResult?: IEraSearchDetailResponse | undefined;
  openEraDetail: boolean;
  setView835Content: Dispatch<React.SetStateAction<string>>;
  setEraSearchTransactionResult: Dispatch<React.SetStateAction<IEraSearchDetailResponse | undefined>>;
  onOpenView835FromDrawer: () => void;
  transactionId: string;
}

const EraSearchTableData: React.FC<IProps> = ({
  onClickThrough,
  eraSearchResult,
  onClickOfView835,
  setSortingField,
  setSortingDirection,
  onCloseDrawer,
  eraSearchTransactionResult,
  openEraDetail,
  setEraSearchTransactionResult,
  transactionId,
  setView835Content,
  onOpenView835FromDrawer,
}) => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const handleGetEraDetail = async () => {
      try {
        console.log('handleGetEraDetail on drwaer open', transactionId);

        setIsFetching(true);
        const response = await getEraTransactionDetails(transactionId, dispatch);

        setEraSearchTransactionResult(response);

        setIsFetching(false);
      } catch (err) {
        //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
        const error = err as Error;
        dispatch(setAppError({ message: error.message, isOpen: true }));

        setIsFetching(false);
      }
    };
    if (openEraDetail) {
      setEraSearchTransactionResult(undefined);
      handleGetEraDetail();
    }
  }, [transactionId, openEraDetail]);

  const onSort = (column: any, direction: any, records: any) => {
    const sorting = direction.sortingKey.split('|');
    const fieldName = sorting[0];
    const directionName =
      sorting[1] == tableSortingDirection.SORT_ASCENDING
        ? ESortingDirectionEraSearch.ASCENDING
        : ESortingDirectionEraSearch.DESCENDING;

    setSortingDirection(directionName);
    setSortingField(fieldName);
  };

  const handleMapping = () =>
    eraSearchResult.map((item, index) => {
      return {
        checkEftDate: moment(item.checkEftDate).format('MM/DD/YYYY'),
        transactionId: parseInt(item.transactionId),
        payerId: item.payerId,
        payeeName: item.payeeName,
        charged: item.charged,
        paid: item.paid,
        checkEftNumber: item.checkEftNumber,
        is835Data: {
          domID: 'view835-button-' + index.toString(),
          dataTestId: 'view835-button-' + index.toString(),
          label: 'View 835',
          name: <Download />,
          type: 'button',
          buttonType: 'deEmphasized',
          onClick: (e: SyntheticEvent) => onClickOfView835(e, item),
        },
      };
    });

  return (
    <div style={{ marginTop: 40, paddingBottom: 50 }}>
      <Grid
        dataTestId="era-search-grid"
        domID="era-search-grid"
        isConfigurable={false}
        initialSortingKey="transactionId|SORT_DESCENDING"
        onClickThrough={onClickThrough}
        onSortGridColumn={onSort}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={eraSearchTableColumns}
        maxHeight="500px"
        isFixedHeader={true}
      />

      {openEraDetail && (
        <EraSearchDetailDrawer
          onCloseDrawer={onCloseDrawer}
          eraSearchTransactionResult={eraSearchTransactionResult}
          isFetching={isFetching}
          setView835Content={setView835Content}
          onOpenView835FromDrawer={onOpenView835FromDrawer}
        />
      )}
    </div>
  );
};

export default memo(EraSearchTableData);
