import React from 'react';
import { INotificationState } from '../../reduxStore/notificationSlice/interfaces';

interface IProps {
  notification: INotificationState;
}

const NotificationBodyComponent: React.FC<IProps> = ({ notification }) => {
  return (
    <div data-testid={`notification-${notification.id}-body-test`}>
      <div>{notification.description}</div>

      {notification.additionalInfo && notification.additionalInfo.length > 0 && (
        <div style={{ marginTop: '1rem' }}>
          <div>Additional Info:</div>
          <ul>
            {notification.additionalInfo.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NotificationBodyComponent;
