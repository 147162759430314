import React from 'react';
import { Text, Button } from '@uicl/ui-core/dist';
import { useNavigate } from 'react-router-dom';

interface IProps {
  createAnotherAccountHandler?: any | undefined;
}

// TODO: pass in onClick handler as defined in th parent.
// TODO: onClick => reset all state in the form (don't forget errorResponse). then set fromState to FormState.RequiresUserInput
const Success: React.FC<IProps> = ({ createAnotherAccountHandler }) => {
  const navigate = useNavigate();

  return (
    <div
      data-testid="onboardingPageSuccess-testid"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '91vh',
      }}
    >
      <Text variant="large">Successfully Onboarded Account!</Text>
      <div style={{ padding: '1%' }}>
        <Button
          dataTestId="create-another-account"
          domID="create-another-account"
          name="Create Another Account"
          size="medium"
          type="button"
          onClick={createAnotherAccountHandler}
        />
      </div>
    </div>
  );
};

export default Success;
