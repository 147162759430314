import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../../api/claimView/interface';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const ClaimFormComponent: React.FC<IProps> = ({ claimViewData }) => {
  const renderYN = (value: boolean) => {
    return value ? 'Y' : 'N';
  };
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimFormContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="large">Claim Form Questions</Text>
      </div>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'ClaimFormComponent-occupant-accident'}>
          <Text color="secondary" variant="small">
            Occupant Accident
          </Text>

          <Text>{renderYN(claimViewData.claimFormQuestions.wasOccupationalAccident)}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'ClaimFormComponent-auto-accident'}>
            <Text color="secondary" variant="small">
              Auto Accident
            </Text>

            <Text>{renderYN(claimViewData.claimFormQuestions.wasAutoAccident)}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>

        <ClaimGridDiv data-testId={'ClaimFormComponent-orthodontics-accident'}>
          <Text color="secondary" variant="small">
            Orthodontics
          </Text>

          <Text>{renderYN(claimViewData.claimFormQuestions.wasOrthodontics)}</Text>
        </ClaimGridDiv>
      </InsuredStyledDiv>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'ClaimFormComponent-benefits-assigned'}>
          <Text color="secondary" variant="small">
            Benefits Assigned
          </Text>

          <Text>{renderYN(claimViewData.claimFormQuestions.wasBenefitAssigned)}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'ClaimFormComponent-prosthesis'}>
            <Text color="secondary" variant="small">
              Prosthesis
            </Text>

            <Text>{renderYN(claimViewData.claimFormQuestions.wasProsthesis)}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv />
      </InsuredStyledDiv>
    </div>
  );
};

export default ClaimFormComponent;
