import { IApiClient, IApiResponse } from './interfaces';
import { AxiosInstance } from 'axios';
import { IApiConfiguration } from './apiInstance';
import { handleApiResponse } from './apiResponseHandlers';

export default class ApiClientWrapper implements IApiClient {
  private readonly client: AxiosInstance;
  private readonly dispatch?: any;
  private readonly apiConfiguration?: IApiConfiguration;

  constructor(client: AxiosInstance, dispatch?: any, apiConfiguration?: IApiConfiguration) {
    this.client = client;
    this.dispatch = dispatch;
    this.apiConfiguration = apiConfiguration;
  }

  delete(url: string, config?: any): Promise<IApiResponse> {
    return this.client.delete(url, config).then(
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration),
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration)
    );
  }

  get(url: string, config?: any): Promise<IApiResponse> {
    return this.client.get(url, config).then(
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration),
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration)
    );
  }

  patch(url: string, data?: any, config?: any): Promise<IApiResponse> {
    return this.client.patch(url, data, config).then(
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration),
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration)
    );
  }

  post(url: string, data?: any, config?: any): Promise<IApiResponse> {
    return this.client.post(url, data, config).then(
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration),
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration)
    );
  }

  put(url: string, data?: any, config?: any): Promise<IApiResponse> {
    return this.client.put(url, data, config).then(
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration),
      (r) => handleApiResponse(r, this.dispatch, this.apiConfiguration)
    );
  }
}
