/**
 * Higher order component that injects an instance of WebStorageManager into
 * the wrapped component.
 */

import React from 'react';
import webStorageManager from './webstorage-manager';
import { WEB_STORAGE_CONFIG } from './constants';

const withWebStorage = Component =>
  class WithWebStorage extends React.Component {
    store = webStorageManager.configure(WEB_STORAGE_CONFIG);

    render() {
      const { children, ...rest } = this.props;
      return (
        <Component store={this.store} {...rest}>
          {children}
        </Component>
      );
    }
  };

export default withWebStorage;
