import React from 'react';
import ClaimAttachments from './ClaimAttachments';

const SearchClaimAttachments = () => {
  return (
    <div data-testid="claimattachment-search-page-testid" style={{ marginTop: 70, height: '100vh' }}>
      <ClaimAttachments />
    </div>
  );
};

export default SearchClaimAttachments;
