import React, { useEffect } from 'react';
import integrations from '../../integrations';
import withStorage from '../../utils/withWebStorage';
import { useAuth } from 'react-oidc-context';
import { raiseErrorNotification } from '../../api/errorHandling';
import { useAppDispatch } from '../../reduxStore/reduxHooks';

interface IProps {
  location: any;
  store: any;
}

// Redirect to login page
const Login: React.FC<IProps> = ({ location, store }) => {
  const dispatch = useAppDispatch();
  const { signinRedirect } = useAuth();

  useEffect(() => {
    if (location?.state && location?.state?.from && store) {
      store.add('targetLocation', location.state.from);
    }
    const login = () => {
      signinRedirect({
        redirect_uri: integrations.sentinel.redirect_uri,
      }).catch((error) => {
        raiseErrorNotification(dispatch, { detail: error, title: 'Error Logging In' });
      });
    };

    login();
  }, []);

  return <div />;
};

export default withStorage(Login);
