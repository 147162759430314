import React, { SyntheticEvent } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { attachmentSearchColumns } from './AttachmentSearchTableColumns';
import { convertToDollar, DateFormat, DisplayText, handleDocumentBodyScroll } from '../../../utils/constants';
import { DateWrapper } from '../../../utils/DateWrapper';
import { Download } from '@uicl/ui-core/dist';
import { IClaimAttachmentsSearchResults } from '../../../api/claimAttachments/interface';
import PayerAttachmentDetail from '../Detail';

interface IProps {
  data: IClaimAttachmentsSearchResults[] | undefined;
}

const AttachmentsSearchTableData: React.FC<IProps> = ({ data }) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedSearchResult, setSelectedSearchResult] = React.useState<IClaimAttachmentsSearchResults>();

  const handleMapping = () =>
    data?.map((item, index) => {
      const requestDate: DateWrapper = new DateWrapper(item.requestDate);
      const dateOfService = new DateWrapper(item.dateOfService);
      return {
        patientFirstName: item?.patientFirstName,
        patientLastName: item?.patientLastName,
        attachmentId: item?.attachmentId,
        requestDate: requestDate.formatDateString(DateFormat.DisplayDate),
        dateOfService: dateOfService.formatDateString(DateFormat.DisplayDate),
        amount: convertToDollar((item?.amount as number) ?? 0), // need the null coalesce because amount can be undefined
        billingProvider: item?.billingProvider,
        actions: {
          domID: 'download-button-' + index.toString(),
          dataTestId: 'download-button-' + index.toString(),
          label: 'Download',
          name: <Download />,
          type: 'button',
          buttonType: 'deEmphasized',
          onClick: () => '',
        },
      };
    }) ?? [];

  const openAttachmentDetails = (e: SyntheticEvent, row: IClaimAttachmentsSearchResults) => {
    setSelectedSearchResult(row);
    handleDocumentBodyScroll(true);
    setOpenDrawer(true);
  };

  return (
    <div style={{ width: '90vw', height: '40vw' }}>
      <Grid
        selectionKey={'attachmentId'}
        dataTestId="attachment-search-grid"
        domID="attachment-search-grid"
        isConfigurable={false}
        records={handleMapping()}
        supportSelection={false}
        columns={attachmentSearchColumns}
        emptyGridMessage={DisplayText.NoPayerAttachmentsFound}
        onClickThrough={openAttachmentDetails}
        isFixedHeader={true}
      />
      {openDrawer && selectedSearchResult && (
        <PayerAttachmentDetail
          attachmentSearchResult={selectedSearchResult as IClaimAttachmentsSearchResults}
          closeDrawer={() => setOpenDrawer(false)}
        />
      )}
    </div>
  );
};

export default AttachmentsSearchTableData;
