import { IRawClaimData } from '../../../api/attachments/interface';
import { IClaimViewResponse } from '../../../api/claimView/interface';

export const CreateRawClaimData = (claimViewResponse: IClaimViewResponse) => {
  const renderingProviderNpi = claimViewResponse?.providerInfo?.renderingProvider?.npi
    ? claimViewResponse?.providerInfo?.renderingProvider?.npi
    : claimViewResponse?.providerInfo?.billingProvider?.npi;
  return {
    vendorId: parseInt(claimViewResponse?.channelId),
    vendorTransactionId: '0',
    vendorCustomerId: '0',
    siteId: '0',
    billingProviderTaxId: claimViewResponse?.providerInfo?.billingProvider?.taxId,
    billingProviderNpi: claimViewResponse?.providerInfo?.billingProvider?.npi,
    // rendering provider doesn't have a taxId
    renderingProviderTaxId: claimViewResponse?.providerInfo?.billingProvider?.taxId,
    renderingProviderNpi: renderingProviderNpi,
  } as IRawClaimData;
};
