export interface IInitialStateNotificationSlice {
  lastNotificationId: number;
  notifications: INotificationState[];
}

export interface IAddNotificationPayload {
  title: string;
  icon: IconType;
  description: string;
  additionalInfo?: string[];
}

export interface IArchiveNotificationPayload {
  notificationId: string | number;
}

export interface IMarkNotificationAsReadPayload {
  notificationId: string | number;
}

export interface IMarkNotificationAsShownPayload {
  notificationId: string | number;
}

export enum IconType {
  Info,
  Warning,
}

//Use this for internal state tracking of notifications. Use INotification for interacting with the notification center via the Masthead.
export interface INotificationState {
  id: string;
  isRead: boolean;
  isShown: boolean;
  title: string;
  dateTime: number;
  icon: IconType;
  description: string;
  additionalInfo?: string[];
}
