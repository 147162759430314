import { REACT_APP_REACTOR_DAR_ID, REACT_APP_REACTOR_URL } from '../config';

/**
 * CustomEvent needs to be a set of key:value pairs. These are what ends up in the metrics dashboards
 * The DAR_ID gets set here.
 * @param customEvent
 */
export const sendCustomEvent = (customEvent: object) => {
  const event = {
    ...customEvent,
    namespace: REACT_APP_REACTOR_DAR_ID,
  };

  try {
    // script takes some time to register the event function; not available immediately
    if (window?.Rakanto?.event) window.Rakanto.event('sendCustomData', event);
  } catch (e) {
    console.error('Error sending Custom Reactor Event', e);
  }
};

export const loadOptumReactor = () => {
  // copy pasted from their documentation + converting it to a named function
  const loadRakantoScript = (cx: any, darids: any, demarcs: any) => {
    window['RakantoObject'] = 'Rakanto';
    (window['Rakanto'] =
      window['Rakanto'] ||
      function () {
        (window['Rakanto'].q = window['Rakanto'].q || []).push(
          // eslint-disable-next-line prefer-rest-params
          [Date.now()].concat(Array.prototype.slice.call(arguments))
        );
      }),
      (window['Rakanto'].l = new Date()),
      (window['Rakanto'].demarcs = demarcs);
    let scriptTag, m;
    (scriptTag = document.createElement('script')),
      (scriptTag.src = cx),
      (scriptTag.id = 'rakanto'),
      (scriptTag.async = true),
      darids ? scriptTag.setAttribute('data-px-darids', darids) : null,
      (m = document.getElementsByTagName('script')[0]),
      m?.parentNode?.insertBefore(scriptTag, m);
  };

  if (REACT_APP_REACTOR_URL && REACT_APP_REACTOR_DAR_ID)
    loadRakantoScript(REACT_APP_REACTOR_URL, REACT_APP_REACTOR_DAR_ID, undefined);
};
