import { apiInstance } from '../apiInstance';
import { IClaimSearchRequestBody, IClaimSearchResponse } from './interface';

export const handleClaimSearchApi = (
  requestBody: IClaimSearchRequestBody,
  dispatch?: any
): Promise<IClaimSearchResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .post(`/claim/processed/search`, requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
 