import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../../api/claimView/interface';
import { handleParseAddress } from '../../../../utils/constants';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const ProviderComponent: React.FC<IProps> = ({ claimViewData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="providerContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="large">Provider</Text>
      </div>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'ProviderComponent-billing-name'}>
          <Text color="secondary" variant="small">
            Billing Provider
          </Text>

          <Text>
            {claimViewData.providerInfo.billingProvider.name.firstName}{' '}
            {claimViewData.providerInfo.billingProvider.name.lastName}
          </Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'ProviderComponent-tax-id-type'}>
            <Text color="secondary" variant="small">
              Tax ID Type
            </Text>

            <Text>{claimViewData.providerInfo.billingProvider.taxIdType}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv data-testId={'ProviderComponent-tax-id'}>
          <Text color="secondary" variant="small">
            Tax ID
          </Text>

          <Text>{claimViewData.providerInfo.billingProvider.taxId}</Text>
        </ClaimGridDiv>
      </InsuredStyledDiv>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'ProviderComponent-billing-address'}>
          <Text color="secondary" variant="small">
            Address
          </Text>

          <Text>{handleParseAddress(claimViewData.providerInfo.billingProvider.address)}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv data-testId={'ProviderComponent-rendering-npi'}>
          <ClaimGridCenterDiv>
            <Text color="secondary" variant="small">
              Rendering NPI
            </Text>

            <Text>{claimViewData.providerInfo.renderingProvider.npi}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>

        <ClaimGridDiv data-testId={'ProviderComponent-rendering-provider'}>
          <Text color="secondary" variant="small">
            Rendering Provider
          </Text>

          <Text>
            {claimViewData.providerInfo.renderingProvider.name.firstName}{' '}
            {claimViewData.providerInfo.renderingProvider.name.lastName}
          </Text>
        </ClaimGridDiv>
      </InsuredStyledDiv>

      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'ProviderComponent-billing-npi'}>
          <Text color="secondary" variant="small">
            Billing NPI
          </Text>

          <Text>{claimViewData.providerInfo.billingProvider.npi}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'ProviderComponent-rendering-address'}>
            <Text color="secondary" variant="small">
              Address
            </Text>

            <Text>{handleParseAddress(claimViewData.providerInfo.renderingProvider.address)}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv />
      </InsuredStyledDiv>
    </div>
  );
};

export default ProviderComponent;
