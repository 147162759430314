import React from "react";
import { Row, Col, Input, Text, SelectDropdown } from "@uicl/ui-core/dist";
import { IEligibilityInquiryRequest } from "../../api/eligibilityInquiry/interface";
import { IGenderProps } from "../../Routes/EligibilityInquiry/interface";

interface IProps {
    eligibilityInquiryRequestBody: IEligibilityInquiryRequest;
    handleInputChange: (e: any) => void;
    genderOptions: IGenderProps[];
    initialGenderValue:IGenderProps;
}

const SubscriberDetailsComponent: React.FC<IProps> = ({ eligibilityInquiryRequestBody, handleInputChange, genderOptions, initialGenderValue }) => {
    return (<>
        <Row>
            <Col sm={3}>
                <Text variant="largeBold" className="eligibility-inquiry-header">Subscriber Details</Text>
            </Col>
        </Row>
        <Row>
            <Col sm={3}>
                <Input
                    aria-label="subscriberFirstName"
                    dataTestId="test-subscriberFirstName"
                    domID="subscriberFirstName"
                    name="subscriberDetails.firstName"
                    label="First Name *"
                    size="medium"
                    placeholder="First Name"
                    errorMessage="Invalid First Name"
                    maxLength={35}
                    regex="^[^\t\n]{0,35}$"
                    value={eligibilityInquiryRequestBody?.subscriberDetails?.firstName}
                    onChange={handleInputChange} />
            </Col>
            <Col sm={3}>
                <Input
                    aria-label="subscriberLastName"
                    dataTestId="test-subscriberLastName"
                    domID="subscriberLastName"
                    name="subscriberDetails.lastName"
                    label="Last Name *"
                    size="medium"
                    placeholder="Last Name"
                    errorMessage="Invalid Last Name"
                    maxLength={60}
                    regex="^[^\t\n]{0,60}$"
                    value={eligibilityInquiryRequestBody?.subscriberDetails?.lastName}
                    onChange={handleInputChange} />
            </Col>
        </Row>
        <Row>
            <Col sm={2}>
                <Input
                    domID="subscriberDob"
                    aria-label="subscriberDob"
                    dataTestId="test-subscriberDob"
                    name="subscriberDetails.dateOfBirth"
                    label="Date of Birth *"
                    size="medium"
                    mask="99/99/9999"
                    placeholder="MM/DD/YYYY"
                    regex="^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$"
                    errorMessage="Invalid Date of Birth"
                    value={eligibilityInquiryRequestBody?.subscriberDetails?.dateOfBirth}
                    onChange={handleInputChange} />
            </Col>
            <Col sm={1}>
                <SelectDropdown
                    domID="subscriberGender"
                    aria-label="subscriberGender"
                    dataTestId="test-subscriberGender"
                    label="Gender"
                    size="medium"
                    isSearchable={false}
                    isClearable={false}
                    initialValue={initialGenderValue}
                    options={genderOptions}
                    /* istanbul ignore next */
                    onChange={(e) => handleInputChange({ target: { name: 'subscriberDetails.gender', value: e.value } })} />
            </Col>
            <Col sm={3}>
                <Input
                    aria-label="subscriberInsuredId"
                    dataTestId="test-subscriberInsuredId"
                    domID="subscriberInsuredId"
                    name="subscriberDetails.insuredId"
                    label="Insured ID *"
                    size="medium"
                    placeholder="Insured ID"
                    errorMessage="Invalid Insured ID"
                    regex="^[^\t\n]{0,80}$"
                    value={eligibilityInquiryRequestBody?.subscriberDetails?.insuredId}
                    onChange={handleInputChange} />
            </Col>
        </Row>
    </>);
};
export default SubscriberDetailsComponent;