import React from 'react';
import { Close, IconButton } from '@uicl/ui-core/dist';
import LoadingPage from '../../../../components/LoadingSpinner';
import { EClaimSearchDrawerMode } from '../../../../api/claimSearch/interface';
import ClaimDrawer from '../ClaimDrawer';
import { IClaimViewResponse } from '../../../../api/claimView/interface';
import { IAttachmentFileInfo } from '../../../../api/attachments/interface';
import Drawer from '../../../../components/Drawer';
import ViewAttachments from '../../../ClaimAttachments/View';

interface IProps {
  claimDrawerMode: EClaimSearchDrawerMode;
  onCloseDrawer: () => void;
  claimViewData: IClaimViewResponse;
  attachmentResponse: IAttachmentFileInfo[];
  isFetching: boolean;
}

const ViewClaimDetail: React.FC<IProps> = ({
  claimDrawerMode,
  onCloseDrawer,
  claimViewData,
  attachmentResponse,
  isFetching,
}) => {
  const renderView = () => {
    switch (claimDrawerMode) {
      case EClaimSearchDrawerMode.VIEWCLAIM:
        return <ClaimDrawer onCloseDrawer={onCloseDrawer} claimViewData={claimViewData} isFetching={isFetching} />;
      case EClaimSearchDrawerMode.VIEWATTACHMENT:
        return <ViewAttachments claimViewResponseData={claimViewData} attachmentData={attachmentResponse} />;
      default:
        return 'No view to render';
    }
  };
  return (
    <div data-testid="claim-drawer">
      <Drawer percentageView={EClaimSearchDrawerMode.VIEWCLAIM === claimDrawerMode} containerWidth={'60vw'}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton dataTestId="close-drawer-button" icon={Close} size="medium" onClick={onCloseDrawer} />
        </div>
        {isFetching ? <LoadingPage /> : renderView()}
      </Drawer>
    </div>
  );
};
export default ViewClaimDetail;
