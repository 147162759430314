/* 
INTEGRATIONS
––––––––––––––––––––––––––––––––––––––
This file is the source of truth for what integrations are 
enabled and how they are configured. 

*/

import { REACT_APP_SENTINEL_URL, REACT_APP_SENTINEL_CLIENT_ID, REACT_APP_SENTINEL_REDIRECT_URL } from './config';

export const integrations = {
  sentinel: {
    authority: REACT_APP_SENTINEL_URL,
    client_id: REACT_APP_SENTINEL_CLIENT_ID,
    userinfo: `${REACT_APP_SENTINEL_URL}/protocol/openid-connect/userinfo`,
    token_endpoint: `${REACT_APP_SENTINEL_URL}/protocol/openid-connect/token`,
    introspectToken: `${REACT_APP_SENTINEL_URL}/protocol/openid-connect/token/introspect`,
    redirect_uri: REACT_APP_SENTINEL_REDIRECT_URL,
    response_type: 'code',
    scope: 'openid profile email',
    post_logout_redirect_uri: REACT_APP_SENTINEL_REDIRECT_URL,
    automaticSilentRenew: true,
  },
};

export default integrations;
