import React from 'react';
import { InsuredStyledDiv, EraGridDiv, EraGridCenterDiv, EraGridEndDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IEraSearchDetailResponse } from 'api/EraSearchDetailView/interface';

interface IProps {
  eraSearchDetailData: IEraSearchDetailResponse;
}
const PayeeIdentificationComponent: React.FC<IProps> = ({ eraSearchDetailData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="payeeidentificationContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="largeBold">Payee Identification</Text>
      </div>
      <InsuredStyledDiv>
        <EraGridDiv>
          <Text color="secondary" variant="small">
            Payee
          </Text>

          <Text variant="smallBold">{eraSearchDetailData.payeeIdentification.payee}</Text>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridCenterDiv></EraGridCenterDiv>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridEndDiv>
            <Text color="secondary" variant="small">
              NPI
            </Text>

            <Text variant="smallBold">{eraSearchDetailData.payeeIdentification.npi}</Text>
          </EraGridEndDiv>
        </EraGridDiv>
      </InsuredStyledDiv>

      <InsuredStyledDiv>
        <EraGridDiv>
          <Text color="secondary" variant="small">
            Address
          </Text>

          <Text variant="smallBold">{eraSearchDetailData.payeeIdentification.address}</Text>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridCenterDiv></EraGridCenterDiv>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridEndDiv>
            <Text color="secondary" variant="small">
              Tax ID
            </Text>

            <Text variant="smallBold">{eraSearchDetailData.payeeIdentification.taxId}</Text>
          </EraGridEndDiv>
        </EraGridDiv>
      </InsuredStyledDiv>
    </div>
  );
};

export default PayeeIdentificationComponent;
