import React, { SyntheticEvent, useState } from 'react';
import LoadingPage from '../../LoadingSpinner';
import Drawer from '../../Drawer';
import { Button, Text } from '@uicl/ui-core/dist';
import { TabContainer } from '@uicl/ui-core/dist';
import { Disclaiamer, x12TraceType } from '../../../utils/constants';
import { IEligibilityOverviewResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import OverViewTabSection from './OverViewTabSection';
import PrintPreview from './PrintPreview';
import { DefaultExpandList } from '../../../api/HistoricalTransactionDetailView/interfaces';
interface IProps {
  onCloseDrawer: () => void;
  onPrintPreviewClick: () => void;
  onClosePrintPreview: () => void;
  transactionId: string;
  timeOfTransaction: string;
  handleFileData: (traceType: string) => void;
  isFetching: boolean;
  tabContent: string;
  traceType: string;
  onSave: () => void;
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  openPrintPreview: boolean;
  isFixedHeader: boolean;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}

const HistoricalTransactionDetailDrawer: React.FC<IProps> = ({
  onCloseDrawer,
  onPrintPreviewClick,
  onClosePrintPreview,
  transactionId,
  timeOfTransaction,
  handleFileData,
  isFetching,
  tabContent,
  traceType,
  onSave,
  eligibilityResponse,
  openPrintPreview,
  isFixedHeader,
  defaultExpand,
  setDefaultExpand,
  handlePanelChange,
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleOnChange = (e: SyntheticEvent, state: any) => {
    const selectedTab = x12TraceType.at(state.tabIndex);
    if (selectedTab) {
      setTabIndex(state.tabIndex);
      handleFileData(selectedTab.value);
    }
  };

  const renderButtonSection = () => {
    return (
      <div className="no-print" style={{ display: 'flex' }}>
        {traceType == 'Overview' && (
          <div style={{ marginRight: 10 }}>
            <Button onClick={onPrintPreviewClick} buttonType="emphasized" dataTestId="print-preview_btn">
              Print Preview
            </Button>
          </div>
        )}
        {traceType != 'Overview' && (
          <div style={{ marginRight: 10 }}>
            <Button onClick={onSave} buttonType="emphasized" dataTestId="save-btn">
              Save
            </Button>
          </div>
        )}
        <Button
          onClick={onCloseDrawer}
          buttonType="emphasized"
          domID="close-historical-transaction-view"
          dataTestId="close-historical-transaction-view"
        >
          Close
        </Button>
      </div>
    );
  };

  const renderHeaderView = () => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <Text color="secondary" variant="xLarge">
            Transaction ID {transactionId}
          </Text>
          {renderButtonSection()}
        </div>
        <div style={{ justifyContent: 'space-between', marginTop: 10, marginLeft: 5 }}>
          <Text color="secondary" variant="small">
            {timeOfTransaction}
          </Text>
        </div>
      </div>
    );
  };

  const handleMapping = () =>
    x12TraceType.map((item, index) => {
      return {
        domID: 'tab_' + index,
        label: item.key,
        tabContent: (
          <div className="tab-content-spacing">
            {traceType == 'Overview' && (
              <OverViewTabSection
                handlePanelChange={handlePanelChange}
                defaultExpand={defaultExpand}
                setDefaultExpand={setDefaultExpand}
                isFixedHeader={isFixedHeader}
                eligibilityResponse={eligibilityResponse}
              />
            )}
            {traceType != 'Overview' && tabContent}
          </div>
        ),
      };
    });

  const renderView = () => {
    return (
      <div>
        <TabContainer
          className="tab_container_class"
          contentClass="tab_container_content_class"
          dataTestId="tab_container_testid"
          domID="tab_container_id"
          initialTab={tabIndex}
          onTabChange={(e, state) => {
            handleOnChange(e, state);
          }}
          tabs={handleMapping()}
        />
      </div>
    );
  };
  const renderfooterView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Text color="secondary" variant="small">
          {Disclaiamer}
        </Text>
      </div>
    );
  };
  return (
    <Drawer percentageView={true} containerWidth={'60vw'}>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div>
          {renderHeaderView()}
          {renderView()}
          {renderfooterView()}
          {openPrintPreview && (
            <PrintPreview
              defaultExpand={defaultExpand}
              handlePanelChange={handlePanelChange}
              setDefaultExpand={setDefaultExpand}
              isFixedHeader={isFixedHeader}
              onClosePrintPreview={onClosePrintPreview}
              transactionId={transactionId}
              timeOfTransaction={timeOfTransaction}
              eligibilityResponse={eligibilityResponse}
            ></PrintPreview>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default HistoricalTransactionDetailDrawer;
