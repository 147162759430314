import { useEffect } from 'react';

const useCustomScript = (url: string, onloadFunction: any) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        script.onload = onloadFunction;

        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export default useCustomScript;