import { CollapsePanel, CollapsePanelDetails, CollapsePanelTitle } from '@uicl/ui-core/dist';
import React from 'react';
import { IEligibilityOverviewResponse } from 'api/HistoricalTransactionDetailView/interfaces';
import { DefaultExpandList } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { Text } from '@uicl/ui-core/dist';
import moment from 'moment';
import { InsuredStyledDiv, HistGridDiv, HistGridCenterDiv } from './styles';
interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}
const DependentComponent: React.FC<IProps> = ({
  eligibilityResponse,
  defaultExpand,
  setDefaultExpand,
  handlePanelChange,
}) => {
  const renderDependents = () =>
    eligibilityResponse &&
    eligibilityResponse?.dependent.map((result) => {
      return (
        <div>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Patient Name
              </Text>
              <Text variant="smallBold">{result.patientName}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Relationship
                </Text>
                <Text variant="smallBold">{result.relationship}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Date of Birth
                </Text>
                <Text variant="smallBold">
                  {result.dateOfBirth != '' && moment(result.dateOfBirth, 'YYYYMMDD').format('MM/DD/yyyy')}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Group Number
              </Text>
              <Text variant="smallBold">{result.groupNumber}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Group Name
                </Text>
                <Text variant="smallBold">{result.groupName}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Gender
                </Text>
                <Text variant="smallBold">{result.gender}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
        </div>
      );
    });
  return (
    <div data-testid="dependent-container">
      <CollapsePanel
        dataTestId="test-collapsePanel_dependents"
        expanded={defaultExpand.dependents}
        onChange={handlePanelChange('dependents')}
      >
        <div>
          <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
            Dependents
          </CollapsePanelTitle>
        </div>
        <CollapsePanelDetails>
          {eligibilityResponse?.dependent.length == 0 && (
            <InsuredStyledDiv>
              <HistGridDiv>
                <Text color="secondary" variant="small">
                  Not Returned by Payer
                </Text>
              </HistGridDiv>
            </InsuredStyledDiv>
          )}
          {renderDependents()}
        </CollapsePanelDetails>
      </CollapsePanel>
    </div>
  );
};

export default DependentComponent;
