import React, { useEffect } from 'react';
import PrivateRoute from './PrivateRoute';
import ErrorBoundary from './ErrorBoundary';
import Welcome from '../Routes/Welcome';
import Login from './Login';
import PageRedirect from './PageRedirect';
import NotFound from './NotFound';
import Unauthorized from './Unauthorized';
import PrivacyPolicy from '../components/Footer/PrivacyPolicy';
import TermsOfUse from '../components/Footer/TermsOfUse';
import Claims from './Claims/ProcessedSearch';
import Health from './Health';
import Logout from './Login/Logout';
import Header from '../components/Header';
import Onboarding from './Onboarding';
import Footer from '../components/Footer';
import AuthContainer from '../components/AuthContainer';
import Error from '../Routes/Error';
import EraSearch from './EraSearch';
import SearchClaimAttachments from './ClaimAttachments/Search';
import HistoricalTransactions from '../Routes/HistoricalTransactions';
import EligibilityInquiry from './EligibilityInquiry';
import ResourcePermissionDenied from './ResourcePermissionDenied';
import { RouteNames } from './Navigation';
import { permissionResourceTypes } from '../reduxStore/userPermissionsSlice/checkUserPermission';
import { REACT_APP_STAGE } from '../config';
import { useLocation } from 'react-router-dom';
import { sendCustomEvent } from '../utils/optumReactorService';

const Layout = () => {
  // Implemented Reactor here to track all route changes
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.length > 1) {
      sendCustomEvent({ pathname: location.pathname });
    }
  }, [location]);

  return (
    <>
      <AuthContainer>
        <Header />
        <Footer />
      </AuthContainer>
    </>
  );
};

const router = [
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: RouteNames.Home,
        element: (
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.Logout,
        element: <Logout />,
      },
      {
        path: RouteNames.Login,
        element: <Login />,
      },
      {
        path: RouteNames.Redirect,
        element: <PageRedirect />,
      },
      {
        path: RouteNames.Unauthorized,
        element: <Unauthorized />,
      },
      {
        path: RouteNames.PermissionDenied,
        element: (
          <PrivateRoute>
            <ResourcePermissionDenied />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.Error,
        element: <Error />,
      },
      {
        path: RouteNames.Claims,
        element: (
          <PrivateRoute>
            <Claims />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.HistoricalTransactions,
        element: (
          <PrivateRoute>
            <HistoricalTransactions />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.EligibilityInquiry,
        element: (
          <PrivateRoute>
            <EligibilityInquiry />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.EraSearch,
        element: (
          <PrivateRoute>
            <EraSearch />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.ClaimAttachmentSearch,
        element: (
          <PrivateRoute permissionResourceType={permissionResourceTypes.searchAttachments}>
            <SearchClaimAttachments />
          </PrivateRoute>
        ),
      },
      {
        path: RouteNames.PrivacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: RouteNames.TermsOfUse,
        element: <TermsOfUse />,
      },
      {
        path: RouteNames.Health,
        element: <Health />,
      },
      {
        path: RouteNames.Onboarding,
        element: (
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: (
          <PrivateRoute>
            <NotFound />
          </PrivateRoute>
        ),
      },
    ],
  },
];

export default router;
