 import { apiInstance } from '../apiInstance';
import { IEraSearchDetailResponse } from './interface';
 

 
export const getEraTransactionDetails = (
    transactionId: string,
    dispatch?: any
  ): Promise<IEraSearchDetailResponse> => {
    return new Promise((resolve, reject) => {
      apiInstance(dispatch)
        .get(`/era/${transactionId}`, )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  