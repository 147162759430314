import { Button, Next, Previous, Text } from '@uicl/ui-core/dist';
import React from 'react';

interface IProps {
  previousPageHandler: any;
  nextPageHandler: any;
  isFirstPage: boolean;
  isLastPage: boolean;
  currentPage: number;
  totalPages: number;
}

const PagingControls: React.FC<IProps> = ({
  previousPageHandler,
  nextPageHandler,
  isFirstPage,
  isLastPage,
  currentPage,
  totalPages,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div data-testId="page-text">
        <Text variant="small" className="pagination-text-color">
          Page {currentPage} of {totalPages}
        </Text>
      </div>
      <div style={{ display: 'flex' }}>
        <Button
          buttonType="deEmphasized"
          dataTestId={'left-arrow'}
          domID={'left-arrow'}
          icon={Previous}
          size="small"
          type="button"
          className="action-buttons"
          onClick={previousPageHandler}
          disabled={isFirstPage}
        />
        <Button
          buttonType="deEmphasized"
          dataTestId={'right-arrow'}
          domID={'right-arrow'}
          icon={Next}
          size="small"
          type="button"
          className="action-buttons"
          onClick={nextPageHandler}
          disabled={isLastPage}
        />
      </div>
    </div>
  );
};

export default PagingControls;
