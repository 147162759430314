import React from 'react';
import { Text } from '@uicl/ui-core/dist';
import styled from 'styled-components';

const Health = () => {
  return (
    <StyledDiv data-testid="health-page">
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100px' }} />
        <div style={{ width: '100%' }}>
          <Text color="primary">Hey There!!! The App is Healthy {new Date().toLocaleString()}</Text>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export default Health;
