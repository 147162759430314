import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';
import { EClaimStatus } from '../../Routes/Claims/ProcessedSearch/ClaimStatus';

interface IClaimFilters {
  field: string;
  startDate: string;
  endDate: string;
}

export interface IClaimSearchResponse {
  filters: IClaimFilters;
  totalCount: number;
  resultCount: number;
  pagingOptions: IPagingOptions;
  results: IClaimSearch[];
}

export interface IClaimSearchRequestBody {
  legalEntityId: string;
  dateRangeFilter: IClaimFilters;
  sorting: {
    direction: string;
    fields: string[];
  };
  offset: number;
  limit: number;
}

export interface IClaimSearch {
  id: string;
  patientName: string;
  dateOfService: string;
  processedOn: string;
  renderingProvider: string;
  amount: string;
  payer: string;
  status: EClaimStatus;
  reason: string;
  attachmentId: string;
}

export interface IInitialStateClaimSlice {
  pagingOptions: IPagingOptions | undefined;
  totalCount: number;
  resultCount: number;
  claimSearchData: IClaimSearch[];
}

export enum EClaimSearchDrawerMode {
  VIEWCLAIM = 'VIEWCLAIM',
  VIEWATTACHMENT = 'VIEWATTACHMENT',
}


export interface IClaimFilterRequestBody {
  payerId: string;
  cpsCarrierId: string;
  claimId: string;
  db2Key: string;
  patientLastName: string;
  patientFirstName: string;
  submitterId: string;
  claimStatus: string;
  patientInsuredId: string;
  controlNumber: string;
  billingProviderNpi: string;
  renderingProviderNpi: string;
  [key: string] : string
}

