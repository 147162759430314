import { Card, Group } from '@uicl/ui-core/dist';
import React from 'react';
import { IAttachmentPatientInfo } from '../../../../api/claimAttachments/interface';
import { TextWithLabel } from '../../../../utils/TextWithLabel';
import { DateFormat, FlexDiv } from '../../../../utils/constants';
import DateWrapper from '../../../../utils/DateWrapper';

interface IProps {
  patientDetails?: IAttachmentPatientInfo;
}

const PatientDetails: React.FC<IProps> = ({ patientDetails }) => {
  const Details = (
    <FlexDiv>
      <TextWithLabel
        text={patientDetails?.patientFirstName + ' ' + patientDetails?.patientLastName}
        label={'Patient Name'}
        labelVariant={'xxSmall'}
      />
      <TextWithLabel
        text={new DateWrapper(patientDetails?.patientDateOfBirth).formatDateString(DateFormat.DisplayDate)}
        label={'Date of Birth'}
        labelVariant={'xxSmall'}
      />
      <TextWithLabel text={patientDetails?.insuredId} label={'Insured ID'} labelVariant={'xxSmall'} />
    </FlexDiv>
  );

  return (
    <Group
      dataTestId={'patient-details'}
      title={'Patient Details'}
      initialIsCollapsed
      isAccordion
      description={'All details of the patient associated to this claim'}
    >
      <Card children={Details} />
    </Group>
  );
};

export default PatientDetails;
