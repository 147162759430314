import { IHasClaimAttachmentSearchInputErrors } from '../api/claimAttachments/interface';

export const isFormIsValid = (payerAttachmentError: IHasClaimAttachmentSearchInputErrors) => {
  return !(
    payerAttachmentError.hasErrorPAPayerID ||
    payerAttachmentError.hasErrorPAAttachmentId ||
    payerAttachmentError.hasErrorPABillingProviderTaxID ||
    payerAttachmentError.hasErrorPAInsuredID ||
    payerAttachmentError.hasErrorDateRangePicker ||
    payerAttachmentError.hasErrorPatientLastName
  );
};
