import React from 'react';
import moment from 'moment';
import { InsuredStyledDiv, HistGridDiv, HistGridCenterDiv, HistFirstGridDiv, HistFirstCenterGridDiv } from './styles';
import { CollapsePanel, CollapsePanelDetails, CollapsePanelTitle, Text } from '@uicl/ui-core/dist';
import { IEligibilityOverviewResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { DefaultExpandList } from '../../../api/HistoricalTransactionDetailView/interfaces';
import Dependentomponent from './DependentComponent';

interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}
const CoverageInformationComponent: React.FC<IProps> = ({
  eligibilityResponse,
  defaultExpand,
  setDefaultExpand,
  handlePanelChange,
}) => {
  return (
    <div data-testid="coverageInfoContainer">
      <CollapsePanel
        dataTestId="test-collapsePanel_payer"
        expanded={defaultExpand.payer}
        onChange={handlePanelChange('payer')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Payer
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistFirstGridDiv>
              <Text color="secondary" variant="small">
                Payer Name
              </Text>
              <Text variant="smallBold">{eligibilityResponse?.payer?.payerName}</Text>
            </HistFirstGridDiv>
            <HistFirstCenterGridDiv>
              <Text color="secondary" variant="small">
                Payer ID
              </Text>
              <Text variant="smallBold">{eligibilityResponse?.payer?.payerId}</Text>
            </HistFirstCenterGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_provider"
        expanded={defaultExpand.provider}
        onChange={handlePanelChange('provider')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Provider
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Provider Name
              </Text>
              <Text variant="smallBold">{eligibilityResponse?.provider?.providerName}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  NPI
                </Text>
                <Text variant="smallBold">{eligibilityResponse?.provider?.NPI}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Tax ID
                </Text>
                <Text variant="smallBold">{eligibilityResponse?.provider?.taxId}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Address
              </Text>
              <Text variant="smallBold">
                {eligibilityResponse?.provider?.address?.address1
                  ? eligibilityResponse?.provider?.address?.address1 + ' '
                  : ''}
                {eligibilityResponse?.provider?.address?.address2}
              </Text>
              <Text variant="smallBold">
                {eligibilityResponse?.provider?.address?.city
                  ? eligibilityResponse?.provider?.address?.city + ', '
                  : ''}
                {eligibilityResponse?.provider?.address?.state} {eligibilityResponse?.provider?.address?.postalCode}
              </Text>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_subscriber"
        expanded={defaultExpand.subscriber}
        onChange={handlePanelChange('subscriber')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Subscriber
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Insured Name
              </Text>
              <Text variant="smallBold">{eligibilityResponse?.subscriber?.insuredName}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Insured ID
                </Text>
                <Text variant="smallBold">{eligibilityResponse?.subscriber?.insuredId}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Date of Birth
                </Text>
                <Text variant="smallBold">
                  {eligibilityResponse?.subscriber?.dateOfBirth != '' &&
                    moment(eligibilityResponse?.subscriber?.dateOfBirth, 'YYYYMMDD').format('MM/DD/yyyy')}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Group Number
              </Text>
              <Text variant="smallBold">{eligibilityResponse?.subscriber?.groupNumber}</Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Group Name
                </Text>
                <Text variant="smallBold">{eligibilityResponse?.subscriber?.groupName}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Gender
                </Text>
                <Text variant="smallBold">{eligibilityResponse?.subscriber?.gender}</Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Address
              </Text>
              <Text variant="smallBold">
                {eligibilityResponse?.subscriber?.address?.address1
                  ? eligibilityResponse?.subscriber?.address?.address1 + ' '
                  : ''}
                {eligibilityResponse?.subscriber?.address?.address2}
              </Text>
              <Text variant="smallBold">
                {eligibilityResponse?.subscriber?.address?.city
                  ? eligibilityResponse?.subscriber?.address?.city + ', '
                  : ''}
                {eligibilityResponse?.subscriber?.address?.state} {eligibilityResponse?.subscriber?.address?.postalCode}
              </Text>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <div>
        <Dependentomponent
          eligibilityResponse={eligibilityResponse}
          defaultExpand={defaultExpand}
          setDefaultExpand={setDefaultExpand}
          handlePanelChange={handlePanelChange}
        />
      </div>
      <CollapsePanel
        dataTestId="test-collapsePanel_coveragetype"
        expanded={defaultExpand.coveragetype}
        onChange={handlePanelChange('coveragetype')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Coverage Type
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Dental
              </Text>
              <Text variant="smallBold">{eligibilityResponse?.coverageType}</Text>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_dependent"
        expanded={defaultExpand.coveragedates}
        onChange={handlePanelChange('coveragedates')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Coverage Dates
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Subscriber Coverage Dates
              </Text>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Plan Begin Date
              </Text>
              <Text variant="smallBold">
                {eligibilityResponse?.coverageDates?.subscriberCoverageDates?.planBeginDate != '' &&
                  moment(eligibilityResponse?.coverageDates?.subscriberCoverageDates?.planBeginDate, 'YYYYMMDD').format(
                    'MM/DD/yyyy'
                  )}
              </Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Plan End Date
                </Text>
                <Text variant="smallBold">
                  {eligibilityResponse?.coverageDates?.subscriberCoverageDates?.planEndDate != '' &&
                    moment(eligibilityResponse?.coverageDates?.subscriberCoverageDates?.planEndDate, 'YYYYMMDD').format(
                      'MM/DD/yyyy'
                    )}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Policy Effective
                </Text>
                <Text variant="smallBold">
                  {eligibilityResponse?.coverageDates?.subscriberCoverageDates?.policyEffective != '' &&
                    moment(
                      eligibilityResponse?.coverageDates?.subscriberCoverageDates?.policyEffective,
                      'YYYYMMDD'
                    ).format('MM/DD/yyyy')}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Dependent Coverage Dates
              </Text>
            </HistGridDiv>
          </InsuredStyledDiv>
          <InsuredStyledDiv>
            <HistGridDiv>
              <Text color="secondary" variant="small">
                Plan Begin Date
              </Text>
              <Text variant="smallBold">
                {eligibilityResponse?.coverageDates?.dependentCoverageDates?.planBeginDate != '' &&
                  moment(eligibilityResponse?.coverageDates?.dependentCoverageDates?.planBeginDate, 'YYYYMMDD').format(
                    'MM/DD/yyyy'
                  )}
              </Text>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Plan End Date
                </Text>
                <Text variant="smallBold">
                  {eligibilityResponse?.coverageDates?.dependentCoverageDates?.planEndDate != '' &&
                    moment(eligibilityResponse?.coverageDates?.dependentCoverageDates?.planEndDate, 'YYYYMMDD').format(
                      'MM/DD/yyyy'
                    )}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
            <HistGridDiv>
              <HistGridCenterDiv>
                <Text color="secondary" variant="small">
                  Policy Effective
                </Text>
                <Text variant="smallBold">
                  {eligibilityResponse?.coverageDates?.dependentCoverageDates?.policyEffective != '' &&
                    moment(
                      eligibilityResponse?.coverageDates?.dependentCoverageDates?.policyEffective,
                      'YYYYMMDD'
                    ).format('MM/DD/yyyy')}
                </Text>
              </HistGridCenterDiv>
            </HistGridDiv>
          </InsuredStyledDiv>
        </CollapsePanelDetails>
      </CollapsePanel>
    </div>
  );
};

export default CoverageInformationComponent;
