import { IHistoricalTransactionsSearchRequestBody, IHistoricalTransactionsSearchResponse } from "./interfaces"
import { apiInstance } from '../apiInstance';

export const getHistoricalTransactionsSearchDetails = (
    historicalRequestBody: IHistoricalTransactionsSearchRequestBody,
    dispatch?: any
  ): Promise<IHistoricalTransactionsSearchResponse> => {
    return new Promise((resolve, reject) => {
      apiInstance(dispatch)
        .post(`/eligibility/search/`, historicalRequestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  