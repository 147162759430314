import styled from 'styled-components';

export const DrawerBox = styled.div`
  height: 100%;
  margin-top: 20px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
`;

export const DrawerContatiner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DrawerContent = styled.div<{
  percentageView: boolean;
  containerWidth?: string;
}>`
  width: ${(props) => (props.containerWidth ? `${props.containerWidth}` : '800px')};
  background-color: #fff;
  padding: 30px;
  background-color: #fff;
  min-height: ${(props) => (props.percentageView ? '100%' : '100vh')};
`;

export const HistStyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
`;

export const InsuredStyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left:40px;
  padding-bottom: 10px;
  padding-left:80px;

`;

export const HistGridDiv = styled.div`
  width: 45%;
`;

export const HistGridCenterDiv = styled.div`
  margin-left: 35px;
  margin-right: 5px;
`;

export const HistGridEndDiv = styled.div``;
export const HistFirstGridDiv = styled.div`
  width: 35%;
`;
export const HistFirstCenterGridDiv = styled.div`
  width: 62%;
`;