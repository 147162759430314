import {
  DEFAULT_OPTIONS,
  INVALID_NAMESPACE,
  INVALID_STORAGE,
} from './modules/constants';
import { iterateStorage } from './modules/helpers';
import {
  isValidStorage,
  isValidString,
  serialize,
  deserialize,
} from './modules/utilities';

class WebStorageManager {
  constructor(options = {}) {
    this.configure(options);
  }

  /**
   * Configures the store instance
   * @param {Object} options
   */
  configure(options) {
    // merge options
    options = { ...DEFAULT_OPTIONS, ...options };

    // validate configuration options
    const { storage, namespace } = options;

    if (!isValidStorage(storage)) {
      throw new Error(INVALID_STORAGE);
    }

    if (!isValidString(namespace)) {
      throw new Error(INVALID_NAMESPACE);
    }

    // save current configuration properties
    this.config = { storage, namespace: namespace.trim(), created: new Date() };

    // set access to storage
    this.storage = storage;

    // set namespace prefix
    this.namespace = `${namespace.trim()}.`;

    return this;
  }

  // PRIVATE METHODS
  _getNamespacedKey(key) {
    return `${this.namespace}${key.trim()}`;
  }

  _getOriginalKey(namespacedKey) {
    return namespacedKey.replace(this.namespace, '');
  }

  _getKeys(namespaced = false) {
    const keys = [];
    const callback = key => keys.push(key);
    this.iterator(callback, namespaced);

    return keys;
  }

  _removeKeys() {
    const namespaced = true;
    this._getKeys(namespaced).forEach(key => {
      try {
        this.storage.removeItem(key);
      } catch (err) { /* empty */ }
    });
  }

  // PUBLIC API

  /**
   * Applies the given function to each member
   * @param {Function} callback
   * @param {Boolean} namespaced Whether the key should include the namespace. Defaults to false
   */
  iterator(callback, namespaced = false) {
    iterateStorage(this, (key, value) => {
      const _key = namespaced ? key : this._getOriginalKey(key);
      return callback(_key, value);
    });
  }

  /**
   * Inserts or updates key with the provided value
   * @param {string} key
   * @param {Object | string | number | null } value
   */
  add(key, value) {
    const namespacedKey = this._getNamespacedKey(key);
    const val = serialize(value);

    try {
      this.storage.setItem(namespacedKey, val);
    } catch (err) { /* empty */ }
  }

  /**
   * Inserts key with the provided value when key does not already exist
   * @param {string} key
   * @param {Object | string | number | null } value
   */
  addIfNotPresent(key, value) {
    if (!this.getValue(key)) {
      this.add(key, value);
      return true;
    }

    return false;
  }

  /**
   * Returns key for given index, or null
   * @param {number} index
   */
  getKey(index) {
    let key = null;
    try {
      key = this.storage.key(index);
    } catch (err) { /* empty */ }

    if (key) {
      return this._getOriginalKey(key);
    }

    return key;
  }

  /**
   * Returns the value for the given key, or null when not found
   * @param {string} key
   */
  getValue(key) {
    const namespacedKey = this._getNamespacedKey(key);
    let value = null;
    try {
      value = this.storage.getItem(namespacedKey);
    } catch (err) { /* empty */ }

    return deserialize(value);
  }

  /**
   * Returns all data stored in the current namespace as a list of key/value pairs
   */
  getData() {
    const data = [];
    const callback = (key, value) => data.push({ key, value });
    this.iterator(callback);

    return data;
  }

  /**
   * Removes value for given key stored in the current namespace
   * @param {string} key
   */
  remove(key) {
    const namespacedKey = this._getNamespacedKey(key);

    try {
      this.storage.removeItem(namespacedKey);
    } catch (err) { /* empty */ }
  }

  /**
   * Removes all keys and values stored in the current namespace
   */
  clear() {
    this._removeKeys();
  }

  /**
   * Returns list of keys stored in the current namespace
   */
  keys() {
    return this._getKeys();
  }

  /**
   * Returns the total number of objects stored in the current namespace
   */
  size() {
    return this.keys().length;
  }
}

const instance = new WebStorageManager();

export default instance;
