/**
 * Iterates over the provided store and calls the provided function
 * with each namespaced key and value pair
 * @param {webStorageManager} store
 * @param {Function} cb
 */


import { deserialize } from './utilities';

export const iterateStorage = (store, cb) => {
  const { storage, namespace } = store;
  Object.keys(storage)
    .filter(key => key.startsWith(namespace))
    .forEach(key => cb(key, deserialize(storage[key])));
};
