import React, { memo } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { historicalPlanProvisionsTableColumns } from './HistoricalGridDetailsTableColumns';
import { IEligibilityOverviewResponse } from 'api/HistoricalTransactionDetailView/interfaces';

interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  isFixedHeader: boolean;
}
const HistoricalPlanProvisionsDetailData: React.FC<IProps> = ({ eligibilityResponse, isFixedHeader }) => {
  const handleMapping = () =>
    eligibilityResponse?.planProvisions?.map((item) => {
      return {
        provisions: item,
      };
    }) || [];

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft: 50, paddingRight: 20 }}>
      <Grid
        dataTestId="eligibility-planprovisionsdetail-grid"
        domID="eligibility-planprovisions-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={historicalPlanProvisionsTableColumns}
        isFixedHeader={isFixedHeader}
        emptyGridMessage="Not Returned by Payer"
      />
    </div>
  );
};

export default memo(HistoricalPlanProvisionsDetailData);
