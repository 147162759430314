import React, { useEffect, useState } from 'react';
import { useLocation, redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { isValidToken } from '../../utils/helpers';
import { TokenData } from '../../model/UserDetail';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import {
  clearAuthStoredData,
  setAuthToken,
  setAuthenticated,
} from '../../reduxStore/authSlice';
import {
  clearUserStoredData,
  setTokenData,
  setUser,
} from '../../reduxStore/userSlice';
import { IUserPayload } from '../../reduxStore/userSlice/interfaces';

const PageRedirect = () => {
  const [isInvalidToken, setInvalidToken] = useState<boolean>(false);
  const [second, setSecond] = useState<number>(3);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const user = window.sessionStorage.getItem('user') as any;
  const dispatch = useAppDispatch();

  /* istanbul ignore next */
  const redirectToClaims = () => {
    redirect('/PrintFileAndResubmisson/ClaimsFileSubmission');
  };

  const redirectToCreateAccount = () => {
    window.location.href = '/createaccount';
  };

  const invalidToken = () => {
    setInvalidToken(true);
  };

  useEffect(() => {
    if (token && isValidToken(token)) {
      dispatch(setAuthToken({ token: token, isAuthenticated: true }));
      const userDetail = new TokenData().deserialize(jwt_decode(token));

      dispatch(setTokenData(userDetail));
      dispatch(setUser(user as IUserPayload));
      if (user.accountIsActivated == true) {
        dispatch(setAuthenticated(true));
        redirectToClaims();
      } else {
        redirectToCreateAccount();
      }
    } else {
      invalidToken();
    }
  }, []);

  useEffect(() => {
    if (isInvalidToken) {
      const interval = setInterval(() => {
        const seconds = second - 1;
        if (seconds < 0) {
          clearStoreData();
          redirect('/');
        }
        setSecond(seconds);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isInvalidToken, second]);

  function clearStoreData() {
    dispatch(clearAuthStoredData());
    dispatch(clearUserStoredData());
  }

  return !isInvalidToken ? (
    <p data-testid="page-invalid-token">Loading...</p>
  ) : (
    <p data-testid="page-redirect-testid">
      Invalid token. Redirecting to Home page after {second} seconds
    </p>
  );
};

export default PageRedirect;
