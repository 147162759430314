import React, { memo } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { IEligibilityDeductiblesResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { historicalMaximumsTableColumns } from './HistoricalGridDetailsTableColumns';
import { convertToDollar } from '../../../utils/constants';

interface IProps {
  eligibilityDeductibleMaximumResponse: IEligibilityDeductiblesResponse | undefined;
  isFixedHeader: boolean;
}
const HistoricalMaximumsDetailData: React.FC<IProps> = ({ eligibilityDeductibleMaximumResponse, isFixedHeader }) => {
  const handleMapping = () =>
    eligibilityDeductibleMaximumResponse?.maximum.map((item) => {
      return {
        maximum: item.maximumType,
        amountInfo: item.timePeriodQualifier,
        inNetwork: item.amountDetails.inNetwork
          ? convertToDollar(item.amountDetails.inNetwork)
          : 'Not Returned by Payer',
        outOfNetwork: item.amountDetails.outOfNetwork
          ? convertToDollar(item.amountDetails.outOfNetwork)
          : 'Not Returned by Payer',
      };
    }) || [];

  return (
    <div>
      <div style={{ marginTop: 10, paddingBottom: 50, paddingLeft: 50, paddingRight: 20 }}>
        <Grid
          dataTestId="eligibility-maximumsdetail-grid"
          domID="eligibility-maximums-grid"
          isConfigurable={false}
          records={handleMapping()}
          selectAllCheckboxDomID="select-all-test-id"
          selectionKey="text"
          supportSelection={false}
          // @ts-ignore
          columns={historicalMaximumsTableColumns}
          isFixedHeader={isFixedHeader}
        />
      </div>
    </div>
  );
};

export default memo(HistoricalMaximumsDetailData);
