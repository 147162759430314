import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAttachmentFileResponse } from '../../api/claimAttachments/interface';

export interface IAttachmentImagesState {
  attachmentImagesMap: { [id: string]: IAttachmentFileResponse };
}

export interface IPutAttachmentFileResponse {
  attachmentFileResponse: IAttachmentFileResponse;
  attachmentId: string;
}

const initialState = {
  attachmentImagesMap: {},
} as IAttachmentImagesState;

const expiration = 10000;

export const attachmentImagesSlice = createSlice({
  name: 'attachmentImagesSlice',
  initialState,
  reducers: {
    // TODO when we get into Redux ToolKit we need to revisit this
    addAttachmentFileResponse: (state, action: PayloadAction<IPutAttachmentFileResponse | undefined>) => {
      const { attachmentFileResponse, attachmentId } = action?.payload as IPutAttachmentFileResponse;

      if (attachmentFileResponse) {
        const attachmentFileId = attachmentFileResponse.fileMetadata?.attachmentFileId;
        const fileName = attachmentFileResponse.fileMetadata?.fileName;
        const identifier = attachmentId + '-' + attachmentFileId + '-' + fileName;
        const expiresIn = new Date().valueOf() + expiration;

        state.attachmentImagesMap[identifier] = { ...attachmentFileResponse, expiresIn };
      }
    },
  },
});
export const { addAttachmentFileResponse } = attachmentImagesSlice.actions;
export default attachmentImagesSlice.reducer;
