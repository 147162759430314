import React, { useEffect } from 'react';
import {
  IAttachmentDetailResponse,
  IAttachmentFileResponse,
  IClaimAttachmentsSearchResults,
} from '../../../api/claimAttachments/interface';
import { useAppDispatch } from '../../../reduxStore/reduxHooks';
import LoadingPage from '../../../components/LoadingSpinner';
import { getAttachmentDetails, getFileAttachment } from '../../../api/claimAttachments';
import AttachmentDetailContainer from './AttachmentDetailContainer';
import Drawer from '../../../components/Drawer';
import { Close, IconButton } from '@uicl/ui-core/dist';

interface IProps {
  closeDrawer: () => void;
  attachmentSearchResult: IClaimAttachmentsSearchResults;
}

// TODO this component should eventually become a route that fetches the data based on URL id
//  need to take a look at the attachmentSearchResult fields referenced with the API Details
const PayerAttachmentDetail: React.FC<IProps> = ({ closeDrawer, attachmentSearchResult }) => {
  const dispatch = useAppDispatch();

  const [attachmentDetails, setAttachmentDetails] = React.useState<IAttachmentDetailResponse>(
    {} as IAttachmentDetailResponse
  );
  const [attachmentFiles, setAttachmentFiles] = React.useState<IAttachmentFileResponse[]>(
    [] as IAttachmentFileResponse[]
  );
  const [isLoadingDetail, setIsLoadingDetail] = React.useState(true);
  const [isLoadingImages, setIsLoadingImages] = React.useState(true);
  const [isDetailError, setIsDetailError] = React.useState(false);
  const [isImageError, setIsImageError] = React.useState(false);

  // get the attachment details (everything besides the image)
  useEffect(() => {
    const handleGetAttachmentDetail = async () => {
      if (attachmentSearchResult?.attachmentId) {
        setIsLoadingDetail(true);
        setIsDetailError(false);
        try {
          const response = await getAttachmentDetails(attachmentSearchResult.attachmentId, dispatch);
          setAttachmentDetails(response);
        } catch (e) {
          setIsDetailError(true);
        } finally {
          setIsLoadingDetail(false);
        }
      }
    };

    handleGetAttachmentDetail();
  }, [attachmentSearchResult]);

  // get the attachment images
  useEffect(() => {
    if (attachmentDetails?.attachmentFileOverview?.fileOverviews) {
      setIsLoadingImages(true);
      setIsImageError(false);
      const attachmentId = attachmentSearchResult?.attachmentId;
      const attachmentFilePromises = attachmentDetails?.attachmentFileOverview?.fileOverviews.map((attachment) => {
        if (attachmentId && attachment?.attachmentFileId)
          return getFileAttachment(attachmentId, attachment?.attachmentFileId, false, dispatch);

        return new Promise<IAttachmentFileResponse>(() => null);
      });

      Promise.all(attachmentFilePromises)
        .then(
          (attachmentFiles) => {
            setAttachmentFiles(attachmentFiles.filter((s) => s !== null));
          },
          (_) => setIsImageError(true)
        )
        .finally(() => setIsLoadingImages(false));
    }
  }, [attachmentDetails]);

  // pass state to container, handle showing/hiding the container once the data is ready
  return (
    <>
      <div data-testid="unsubmitted-claim-drawer">
        <Drawer percentageView={true} containerWidth={'90vw'}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton dataTestId="close-drawer-button" icon={Close} size="medium" onClick={closeDrawer} />
          </div>
          {isLoadingDetail ? (
            <LoadingPage />
          ) : (
            <>
              {!isDetailError && (
                <AttachmentDetailContainer
                  closeDrawer={closeDrawer}
                  attachmentDetails={attachmentDetails}
                  attachmentDetailImages={attachmentFiles}
                  attachmentSearchResult={attachmentSearchResult}
                  isLoadingImages={isLoadingImages}
                  isImageError={isImageError}
                />
              )}
              {isDetailError && (
                <p data-testid={'claim-attachment-details-error'}>
                  There was an error fetching the attachment details. Please try again.
                </p>
              )}
            </>
          )}
        </Drawer>
      </div>
    </>
  );
};

export default PayerAttachmentDetail;
