import React from 'react';

const ErrorBoundary: React.FC = () => {
  return (
    <h1 id="errorbound" data-testid="errorbound">
      Something went wrong.
    </h1>
  );
};

export default ErrorBoundary;
