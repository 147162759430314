import { apiInstance } from '../apiInstance';
import {
  IAttachmentContainer,
  ICreateAttachmentContainerRequest,
  ICreateAttachmentContainerResponse,
  IPayerRulesResponse,
  IRawClaimData,
} from './interface';
import CryptoJS from 'crypto-js';

export const SaveAttachment = (attachmentId: string, rawClaimData: IRawClaimData, requestBody: any, dispatch?: any) => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .post(`attachments/${attachmentId}/attachmentfiles`, requestBody, {
        headers: attachDNHeader(rawClaimData),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CreateAttachmentContainer = (
  attachmentContainerRequest: ICreateAttachmentContainerRequest,
  dispatch?: any
): Promise<ICreateAttachmentContainerResponse> => {
  return new Promise((resolve, reject) => {
    attachmentContainerRequest.billingProviderTaxId = '';
    apiInstance(dispatch)
      .post(`attachments`, attachmentContainerRequest, {
        headers: attachDNHeader(attachmentContainerRequest.rawClaimData),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetAttachmentContainer = (
  attachmentId: number,
  rawClaimData: IRawClaimData,
  dispatch?: any
): Promise<IAttachmentContainer> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`attachments/${attachmentId}`, {
        headers: attachDNHeader(rawClaimData),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//NOTE: This is currently disabled on the backend until the attachment can be properly guarded against unauthorized access.
export const GetAttachment = (
  attachmentId: number,
  attachmentFileId: number,
  rawClaimData: IRawClaimData,
  dispatch?: any
) => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`attachments/${attachmentId}/attachmentfiles/${attachmentFileId}`, {
        headers: attachDNHeader(rawClaimData),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const CommitAttachmentContainer = (attachmentId: string, rawClaimData: IRawClaimData, dispatch?: any) => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .patch(`attachments/${attachmentId}`, null, {
        headers: attachDNHeader(rawClaimData),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetPayerRules = (
  payerId: string,
  procedureCodes: string,
  rawClaimData: IRawClaimData,
  dispatch?: any
): Promise<IPayerRulesResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch, { notificationFilter: (apiResult) => apiResult?.status !== 400 })
      // set up from the AttachmentsMapper because &procedureCodes repeats for each code
      .get(`/payerrules?payerId=${payerId}${procedureCodes}`, {
        headers: attachDNHeader(rawClaimData),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const attachDNHeader = (data: any) => {
  return {
    'X-DN-AttachmentMetadata': encodeData(data),
  };
};

const encodeData = (data: any): string => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(data)));
};
