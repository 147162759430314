import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';

interface IDateFilter {
  startDate: string;
  endDate: string;
}

interface ISorting {
  direction: string;
  fields: string[];
}
interface IFilters {
  transactionId: string;
  payerId: string;
  vendorIds: string[];
  taxId: string[];
  insuredId: string;
  patientLastName: string;
  patientFirstName: string;
  isErrorsOnly: boolean;
}

export type TSortingFieldTransactionsSearch =
  | 'transactionId'
  | 'taxId'
  | 'patientLastName'
  | 'patientFirstName'
  | 'insuredId'
  | 'payerName'
  | 'errorCode'
  | 'categoryCode'
  | 'category'
  | 'hippaCode'
  | 'timeOfTransaction'
  | 'vendorId'
  | 'userName'
  | 'renderingNpi'
  | 'totalSeconds'
  | 'payerSeconds'
  | 'instance'
  | 'payerTimeStamp'
  | 'payerPayloadID';

export enum ESortingDirectionTransactionsSearch {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}
export interface IHistoricalTransactionsSearchRequestFrontBody {
  limit: number;
  offset: number;
  transactionId: string;
  payerId: string;
  vendorIds: string;
  taxId: string;
  insuredId: string;
  patientLastName: string;
  patientFirstName: string;
  isErrorsOnly: boolean;
  transactionTime: string;
  sorting: ISorting;
  dateRangeFilter: {
    field: string;
    startDate: string;
    endDate: string;
  };
  [key: string]: string | number | IDateFilter | ISorting | null | boolean | string[];
}
export interface IHistoricalTransactionsSearchRequestBody {
  limit: number;
  offset: number;
  filters: IFilters;
  currentTransactionDurationInHours: number;
  sorting: ISorting;
  dateRangeFilter: {
    field: string;
    startDate: string;
    endDate: string;
  };
  [key: string]: string | number | IDateFilter | ISorting | IFilters | null | boolean | string[];
}
export interface IHistoricalTransactionsSearchResponse {
  filters: {
    field: string;
    startDate: string;
    endDate: string;
  };
  totalCount: number;
  resultCount: number;
  pagingOptions: IPagingOptions;
  results: IHistoricalTransactionsSerchResults[];
}

export interface IHistoricalTransactionsSerchResults {
  transactionId: string;
  patientLastName: string;
  patientFirstName: string;
  insuredId: string;
  payerId: string;
  payerName: string;
  errorCode: string;
  categoryCode: string;
  category: string;
  hippaCode: string;
  timeOfTransaction: string;
  vendorId: string;
  userName: string;
  taxId: string;
  renderingNpi: string;
  totalSeconds: string;
  payerSeconds: string;
  instance: string;
  payerTimeStamp: string;
  payerPayloadID: string;
}

export enum HDateFilter {
  STRART = 'start',
  END = 'end',
}

export interface DateFilter {
  option: HDateFilter;
  value: string;
}
