import { apiInstance } from '../apiInstance';
import { IClaimViewResponse } from './interface';
import {IClaimSearch} from "../claimSearch/interface";

export const getProcessedClaimDetail = (claim: IClaimSearch, dispatch?: any): Promise<IClaimViewResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
        .get(`/claim/processed/${claim.id}?processedOn=${claim.processedOn}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
  });
};
