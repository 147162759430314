import styled from 'styled-components';

export const StyledDiv = styled.div`
  height: 100%;
  padding: 1rem;
`;

export const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
