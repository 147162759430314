import React from 'react';
import { InsuredStyledDiv, EraGridDiv, EraGridCenterDiv, EraGridEndDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IEraSearchDetailResponse } from 'api/EraSearchDetailView/interface';
import { convertToDollar } from '../../../utils/constants';
import moment from 'moment';

interface IProps {
  eraSearchDetailData: IEraSearchDetailResponse;
}
const FinancialInfoComponent: React.FC<IProps> = ({ eraSearchDetailData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="financialInfoContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="largeBold">Financial Information</Text>
      </div>
      <InsuredStyledDiv>
        <EraGridDiv>
          <Text color="secondary" variant="small">
            Check/EFT Date
          </Text>

          <Text variant="smallBold">
            {moment(eraSearchDetailData.financialInformation.checkEftDate).format('M/DD/YYYY')}
          </Text>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridCenterDiv>
            <Text color="secondary" variant="small">
              Check/EFT No.
            </Text>

            <Text variant="smallBold">{eraSearchDetailData.financialInformation.checkEftNumber}</Text>
          </EraGridCenterDiv>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridEndDiv>
            <Text color="secondary" variant="small">
              Payment Amount
            </Text>

            <Text variant="smallBold">{convertToDollar(eraSearchDetailData.financialInformation.paymentAmount)}</Text>
          </EraGridEndDiv>
        </EraGridDiv>
      </InsuredStyledDiv>
    </div>
  );
};

export default FinancialInfoComponent;
