import React, { useState, useCallback, useRef } from 'react';
import { Text } from '@uicl/ui-core/dist';
import { Chart } from '@uicl/ui-core/dist';
import { IEligibilityInquiryRequest, IEligibilityInquiryRequestBody } from '../../api/eligibilityInquiry/interface';
import { Container, ListPaneHeader, FormFooter, ButtonGroup, Button } from '@uicl/ui-core/dist';
import moment from 'moment';
import PayerDetailsComponent from '../../components/EligibilityInquiry/PayerDetailsComponent';
import ProviderDetailsComponent from '../../components/EligibilityInquiry/ProviderDetailsComponent';
import SubscriberDetailsComponent from '../../components/EligibilityInquiry/SubscriberDetailsComponent';
import DependentDetailsComponent from '../../components/EligibilityInquiry/DependentDetailsComponent';
import EncounterDetailsComponent from '../../components/EligibilityInquiry/EncounterDetailsComponent';
import { IGenderProps } from './interface';
import { postEligibilityInquiry } from '../../api/eligibilityInquiry';
import { IEligibilityOverviewResponse } from '../../api/HistoricalTransactionDetailView/interfaces';
import OverViewTabSection from '../../components/HistoricalTransactions/HistoricalTransactionDetailDrawer/OverViewTabSection';
import { useReactToPrint } from 'react-to-print';

const EligibilityInquiry = () => {
  const [isOrganization, setIsOrganization] = useState(true);
  const [isServiceType, setIsServiceType] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Required fields are missing. Review the form above and complete any missing required fields before continuing.'
  );
  const [overviewResponse, setOverviewResponse] = useState<IEligibilityOverviewResponse>();
  const [isOverview, setIsOverview] = useState(false);
  const [isNpi, setIsNpi] = useState(true);
  const initiateEligibilityInquiry: Readonly<IEligibilityInquiryRequest> = {
    payerId: '',
    providerDetails: {
      organizationName: '',
      firstName: '',
      lastName: '',
      npi: '',
      taxId: '',
      serviceProviderNumber: '',
    },
    subscriberDetails: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      insuredId: '',
    },
    dependentDetails: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      groupNumber: '',
    },
    encounterDetails: {
      dateOfService: {
        field: 'dateOfService',
        startDate: moment().format('MM/DD/YYYY'),
        endDate: moment().format('MM/DD/YYYY'),
      },
      serviceTypeCode: '35',
      procedureId: 'D',
    },
  };

  const genderOptions: IGenderProps[] = [
    { value: '', label: 'Select' },
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
  ];

  const initialSelectedValue: IGenderProps = { value: '', label: 'Select' };
  const [initialGenderValue, setInitialGenderValue] = useState(initialSelectedValue);
  const [eligibilityInquiryRequestBody, setEligibilityInquiryRequestBody] =
    useState<IEligibilityInquiryRequest>(initiateEligibilityInquiry);

  const handleInputChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      const keys = name.split('.');
      const allowNumbers = ['providerDetails.npi','providerDetails.taxId'];
     if(allowNumbers.includes(name)){
      if(/^\d*$/.test(value)){
        elibilityInquiry(keys,value);
      }
     }
     else{
      elibilityInquiry(keys,value);

     }
      
    },
    [eligibilityInquiryRequestBody]
  );

  const elibilityInquiry = (keys: any,value: any) =>{
    setEligibilityInquiryRequestBody((prevState) => {
      const newState = { ...prevState };
      let currentLevel: any = newState;

      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel = currentLevel[keys[i]];
      }

      currentLevel[keys[keys.length - 1]] = value;
      return newState;
    });
  }
 
  const handleGoBack = () => {
    setIsOverview(false);
  };
  const handleNewSearch = () => {
    setIsOverview(false);
    handleClearFields();
  };
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClearFields = useCallback(() => {
    setEligibilityInquiryRequestBody(initiateEligibilityInquiry);
    setHasError(false);
    setErrorMessage(
      'Required fields are missing. Review the form above and complete any missing required fields before continuing.'
    );
    setInitialGenderValue({ value: '', label: 'Select' });
  }, [initiateEligibilityInquiry]);

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const validateRequiredFields = (fields: string[]) => {
    return fields.filter((field) => !getNestedValue(eligibilityInquiryRequestBody, field));
  };

  const validateOrganization = () => {
    if (isOrganization) {
      return !eligibilityInquiryRequestBody.providerDetails.organizationName;
    } else {
      return (
        !eligibilityInquiryRequestBody.providerDetails.firstName ||
        !eligibilityInquiryRequestBody.providerDetails.lastName
      );
    }
  };

  const validateNpiOrTaxId = () => {
    if (isNpi) {
      return !eligibilityInquiryRequestBody.providerDetails.npi;
    } else {
      return (
        !eligibilityInquiryRequestBody.providerDetails.taxId 
      );
    }
  };

  const validateEncounterDetails = () => {
    if (isServiceType) {
      return (
        !eligibilityInquiryRequestBody.encounterDetails.serviceTypeCode ||
        !eligibilityInquiryRequestBody.encounterDetails.dateOfService.startDate ||
        !eligibilityInquiryRequestBody.encounterDetails.dateOfService.endDate
      );
    } else {
      return (
        !eligibilityInquiryRequestBody.encounterDetails.procedureId ||
        eligibilityInquiryRequestBody.encounterDetails.procedureId.length !== 5 ||
        !eligibilityInquiryRequestBody.encounterDetails.dateOfService.startDate ||
        !eligibilityInquiryRequestBody.encounterDetails.dateOfService.endDate
      );
    }
  };

  const validateDependentDetails = () => {
    const dependentDetails = eligibilityInquiryRequestBody?.dependentDetails;
    if (
      dependentDetails.firstName ||
      dependentDetails.lastName ||
      dependentDetails.dateOfBirth ||
      dependentDetails.groupNumber ||
      dependentDetails.gender
    ) {
      return !dependentDetails.firstName || !dependentDetails.lastName || !dependentDetails.dateOfBirth;
    }
    return false;
  };

  const validateForm = () => {
    const requiredFields = [
      'payerId',
      'subscriberDetails.firstName',
      'subscriberDetails.lastName',
      'subscriberDetails.dateOfBirth',
      'subscriberDetails.insuredId',
    ];

    const missingFields = validateRequiredFields(requiredFields);
    if (missingFields.length > 0) {
      return true;
    }

    if (validateOrganization()) {
      return true;
    }
    if(validateNpiOrTaxId()){
      return true;
    }
    if (validateDependentDetails()) {
      return true;
    }
    if (validateEncounterDetails()) {
      return true;
    }

    return false;
  };

  const handleOnsubmit = async () => {
    setHasError(false);
    if (validateForm()) {
      setHasError(true);
      return;
    }
    const eligibilityInquiryRequestBodyCopy = convertToRequestBody(eligibilityInquiryRequestBody);
    console.log('request body:', eligibilityInquiryRequestBodyCopy);
    try {
      const response = await postEligibilityInquiry(eligibilityInquiryRequestBodyCopy);
      setOverviewResponse(response);
      setIsOverview(true);
    } catch {
      setIsOverview(false);
    }
  };

  const convertToRequestBody = (request: IEligibilityInquiryRequest): IEligibilityInquiryRequestBody => {
    return {
      controlNumber: '123456789',
      payerId: request.payerId,
      providerDetails: request.providerDetails,
      subscriberDetails: request.subscriberDetails,
      dependentDetails: request.dependentDetails?.firstName && request.dependentDetails?.lastName ? [request.dependentDetails] : [], // Wrap in an array
      encounterDetails: request.encounterDetails,
    };
  };
  const listOfPanels = {
    payer: true,
    provider: true,
    subscriber: true,
    dependents: true,
    coveragetype: true,
    coveragedates: true,
    deductiblemaximum: true,
    planprovision: true,
    coverage: true,
    frequencylimitation: true,
    agelimitation: true,
    aaaerror: true,
  };
  const [isFixedHeader, setisFixedHeader] = useState(false);
  const [defaultExpand, setDefaultExpand] = useState(listOfPanels);
  const handlePanelChange =
    (
      panel:
        | 'payer'
        | 'provider'
        | 'subscriber'
        | 'dependents'
        | 'coveragetype'
        | 'coveragedates'
        | 'deductiblemaximum'
        | 'planprovision'
        | 'coverage'
        | 'frequencylimitation'
        | 'agelimitation'
        | 'aaaerror'
    ) =>
    (event: React.SyntheticEvent, expanded: boolean) => {
      const copy = { ...defaultExpand };
      copy[panel] = expanded;
      setDefaultExpand(copy);
    };
  const Disclaiamer =
    ' Disclaimer: This Optum Dental eligibility report is for informational purposes only. The information is derived directly from the payer indicated on the' +
    ' report and is not to be construed as a guarantee of payment.';

  const renderFormFooter = useCallback(
    () => (
      <FormFooter style={{ justifyContent: 'flex-start', padding: '2rem 1rem' }}>
        <div style={{ width: '100%' }}>
          {hasError && (
            <Text variant="small" color="error">
              {errorMessage}
            </Text>
          )}
        </div>
        {/*@ts-ignore*/}
        <ButtonGroup size="small">
          <Button
            buttonType="emphasized"
            dataTestId="test-submitEligibilityInquiry"
            domID="submitEligibilityInquiry"
            size="small"
            name="Submit"
            onClick={handleOnsubmit}
          />
          <Button buttonType="standard" dataTestId="test-cancel" domID="cancel" size="small" name="Cancel" />
          <Button
            buttonType="diminished"
            dataTestId="test-clearFields"
            domID="clearFields"
            size="small"
            name="Clear Fields"
            onClick={handleClearFields}
          />
        </ButtonGroup>
      </FormFooter>
    ),
    [handleOnsubmit, handleClearFields]
  );
  const renderDisclaimerView = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginLeft: 37, marginBottom: 40 }}
      >
        <Text color="secondary" variant="small">
          {Disclaiamer}
        </Text>
      </div>
    );
  };
  return (
    <div data-testid="eligibility-inquiry-page-testid" style={{ marginLeft: 60 }}>
      {isOverview && (
        <div className="printable-section " ref={componentRef} id="printable-section">
          <div style={{ marginTop: 40 }}>
            <div style={{ padding: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <div className="printable-section " ref={componentRef}>
                  <Text color="secondary" variant="xLarge">
                    Transaction ID {overviewResponse?.transactionId}
                  </Text>
                </div>
                <div className="no-print" style={{ display: 'flex', gap: '10px' }}>
                  <Button onClick={handleGoBack} buttonType="emphasized" dataTestId="test-back" domID="goback">
                    Go Back
                  </Button>
                  <Button onClick={handlePrint} buttonType="emphasized" dataTestId="button_print">
                    Print
                  </Button>
                  <Button onClick={handleNewSearch} buttonType="emphasized" dataTestId="button_newsearch">
                    New Search
                  </Button>
                </div>
              </div>
            </div>

            <OverViewTabSection
              handlePanelChange={handlePanelChange}
              defaultExpand={defaultExpand}
              setDefaultExpand={setDefaultExpand}
              isFixedHeader={isFixedHeader}
              eligibilityResponse={overviewResponse}
            />
            {renderDisclaimerView()}
          </div>
        </div>
      )}
      {!isOverview && (
        <div style={{ marginTop: 40 }}>
          <ListPaneHeader
            domID="pageHeaderEligibilityInquiry"
            dataTestId="test-pageHeaderEligibilityInquiry"
            className="listPanelHeader"
            title="Inquiry"
            icon={Chart}
          ></ListPaneHeader>
          <div>
            <Container>
              <div style={{ marginBottom: '40px' }}>
                <PayerDetailsComponent
                  eligibilityInquiryRequestBody={eligibilityInquiryRequestBody}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div style={{ marginBottom: '40px' }}>
                <ProviderDetailsComponent
                  eligibilityInquiryRequestBody={eligibilityInquiryRequestBody}
                  handleInputChange={handleInputChange}
                  isOrganization={isOrganization}
                  setIsOrganization={setIsOrganization}
                  isNpi={isNpi}
                  setIsNpi={setIsNpi}
                />
              </div>
              <div style={{ marginBottom: '40px' }}>
                <SubscriberDetailsComponent
                  eligibilityInquiryRequestBody={eligibilityInquiryRequestBody}
                  handleInputChange={handleInputChange}
                  genderOptions={genderOptions}
                  initialGenderValue={initialGenderValue}
                />
              </div>
              <div style={{ marginBottom: '40px' }}>
                <DependentDetailsComponent
                  eligibilityInquiryRequestBody={eligibilityInquiryRequestBody}
                  handleInputChange={handleInputChange}
                  genderOptions={genderOptions}
                  initialGenderValue={initialGenderValue}
                />
              </div>
              <EncounterDetailsComponent
                eligibilityInquiryRequestBody={eligibilityInquiryRequestBody}
                handleInputChange={handleInputChange}
                isServiceType={isServiceType}
                setIsServiceType={setIsServiceType}
              />
              {renderFormFooter()}
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

export default EligibilityInquiry;
