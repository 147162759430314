export const RouteNames = {
  Home: '/',
  Login: '/login',
  Logout: '/logout',
  Error: '/error',
  Redirect: '/redirect',
  Unauthorized: '/unauthorized',
  PermissionDenied: '/permission-denied',
  Claims: '/claims',
  ClaimAttachmentSearch: '/claim-attachments-search',
  HistoricalTransactions: '/historical-transactions',
  EligibilityInquiry: '/eligibility-inquiry',
  EraSearch: '/era-search',
  PrivacyPolicy: '/privacy-policy',
  TermsOfUse: '/terms-of-use',
  Health: '/health',
  Onboarding: '/onboarding',
  AccountSelection: '/account-selection',
};
