import React from 'react';
import { CollapsePanel, CollapsePanelDetails, CollapsePanelTitle } from '@uicl/ui-core/dist';
import { IEligibilityOverviewResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import HistoricalDeductiblesAndMaximumsDetailData from './DeductiblesGridComponent';
import HistoricalPlanProvisionsDetailData from './PlanProvisionsGridComponent';
import HistoricalMaximumsDetailData from './MaximumsGridComponent';
import HistoricalCoverageDetailData from './CoverageDetailGridComponent';
import HistoricalAgeLimitationsDetailData from './AgeLimitationsGridComponent';
import HistoricalFrequencyLimitationsDetailData from './FrequencyLimitationsDetailsGridComponent';
import { DefaultExpandList } from '../../../api/HistoricalTransactionDetailView/interfaces';
interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  isFixedHeader: boolean;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}

const HistoricalTransactionsGridsComponent: React.FC<IProps> = ({
  handlePanelChange,
  eligibilityResponse,
  isFixedHeader,
  defaultExpand,
  setDefaultExpand,
}) => {
  return (
    <div data-testid="historicalTransactionsGridsContainer">
      <CollapsePanel
        dataTestId="test-collapsePanel_deductiblesandmaximumsdetail"
        expanded={defaultExpand.deductiblemaximum}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Deductibles & Maximums
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <HistoricalDeductiblesAndMaximumsDetailData
            isFixedHeader={isFixedHeader}
            eligibilityDeductibleMaximumResponse={eligibilityResponse?.deductibleMaximums}
          />
          <HistoricalMaximumsDetailData
            isFixedHeader={isFixedHeader}
            eligibilityDeductibleMaximumResponse={eligibilityResponse?.deductibleMaximums}
          />
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_planprovisionsdetail"
        expanded={defaultExpand.planprovision}
        onChange={handlePanelChange('planprovision')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Plan Provisions
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <HistoricalPlanProvisionsDetailData isFixedHeader={isFixedHeader} eligibilityResponse={eligibilityResponse} />
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_coveragedetail"
        expanded={defaultExpand.coverage}
        onChange={handlePanelChange('coverage')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
        Patient Responsibility
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <HistoricalCoverageDetailData isFixedHeader={isFixedHeader} eligibilityResponse={eligibilityResponse} />
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_frequencylimitations"
        expanded={defaultExpand.frequencylimitation}
        onChange={handlePanelChange('frequencylimitation')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Frequency Limitations
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <HistoricalFrequencyLimitationsDetailData
            isFixedHeader={isFixedHeader}
            eligibilityResponse={eligibilityResponse}
          />
        </CollapsePanelDetails>
      </CollapsePanel>
      <CollapsePanel
        dataTestId="test-collapsePanel_agelimitations"
        expanded={defaultExpand.agelimitation}
        onChange={handlePanelChange('agelimitation')}
      >
        <CollapsePanelTitle aria-controls="collapse-details" domID="collapse-title">
          Age Limitations
        </CollapsePanelTitle>
        <CollapsePanelDetails>
          <HistoricalAgeLimitationsDetailData isFixedHeader={isFixedHeader} eligibilityResponse={eligibilityResponse} />
        </CollapsePanelDetails>
      </CollapsePanel>
    </div>
  );
};
export default HistoricalTransactionsGridsComponent;
