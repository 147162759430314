import React from 'react';
import { Text, Grid } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../../api/claimView/interface';

const columns = new Set([
  {
    dataName: 'dateOfService',
    text: 'Date Of Service',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'procedureCode',
    text: 'Procedure Code',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'quantity',
    text: 'Qty',
    sortable: true,
    isSorted: 0,
  },
  {
    dataName: 'chargeAmount',
    text: 'Charge',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'description',
    text: 'Description',
    sortable: false,
    isSorted: 0,
  },
  {
    dataName: 'tooth',
    text: 'Tooth',
    sortable: false,
    isSorted: 0,
  },
]);

interface IProps {
  claimViewData: IClaimViewResponse;
}

const ClaimTreatmentComponent: React.FC<IProps> = ({ claimViewData }) => {
  const handleMapRecords = claimViewData.claimTreatments.map((item) => {
    return {
      ...item,
      chargeAmount: `$${item.chargeAmount}`,
    };
  });

  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimTreatmentContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="large">Claim Treatments</Text>
      </div>
      <div style={{ marginTop: 10 }}>
        <Grid
          columns={columns}
          dataTestId="test-ClaimTreatmentDetailsGridTable"
          domID="test-id"
          records={handleMapRecords}
          selectionKey="caseId"
        />
      </div>
    </div>
  );
};

export default ClaimTreatmentComponent;
