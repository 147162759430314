export enum ProviderVerificationDocumentType {
  W9 = 1,
  PhotoIdentity = 2,
  Signature = 3,
  PDFApplication = 4,
}

export enum InputTypeEnum {
  TextBox = 1,
  Dropdown = 2,
  DateRangePicker = 3,
  DatePicker = 4,
}

export enum EraEftDateTypeEnum {
  PaymentDate = 0,
  ServiceDate = 1,
  ProcessedDate = 2,
}

export enum EraEftSortTypeEnum {
  None = 0,
  PayeeName = 1,
  DisplayDate = 2,
  PayerName = 3,
  CheckNumber = 4,
  TotalCharge = 5,
  TotalPaid = 6,
  ClaimCount = 7,
}
export enum claimTypeEnum {
  None = 0,
  PatientName = 1,
  ProcessedOn = 2,
  RenderingProvider = 3,
  Amount = 4,
  Payer = 5,
  Status = 6,
}
export enum EraEftSortDirectionEnum {
  None = 0,
  Ascending = 1,
  Descending = 2,
}
export enum customRuleTypeEnum {
  None = 0,
  PayerId = 1,
  PayerName = 2,
  PayerStatus = 3,
  ProcedureCode = 4,
  AttachmentTypeRequired = 5,
  Comments = 6,
}
export enum CustomRuleSortDirectionEnum {
  None = 0,
  Ascending = 1,
  Descending = 2,
}

export enum AdvanceProviderMenuEnum {
  PersonalInfo = 1,
  License = 2,
  Dea = 3,
  Cds = 4,
  MedicalNumber = 5,
  Education = 6,
  Speciality = 7,
  HospitalAffiliation = 8,
  Libality = 9,
  WorkHistory = 10,
  CredentialPersonInfo = 11,
  CredentialPersonAdditionalInfo = 12,
}

export enum AdvanceProviderHeaderEnum {
  BasicProvider = 1,
  AdvanceProvider = 2,
  Document = 3,
}

export enum currentHeaderEnum {
  currentHeader = 1,
}

export enum ReviewEnum {
  PersonalInfo = 1,
  ProfessionalIDs = 2,
  Education = 3,
  Speciality = 4,
  HospitalAffiliation = 5,
  ProLiabilityInsuarance = 6,
  WorkHistory = 7,
  DocumentUpload = 8,
  Disclosure = 9,
  SelfAssessment = 10,
  LegalEntityAndLocation = 11,
}

export enum DocumentUpload {
  Pdf = 'pdf',
  Doc = 'doc',
  Docx = 'docx',
  Bmp = 'bmp',
  Png = 'png',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
}

export enum AdvanceLocationTab {
  BasicInformation = 0,
  AdditionalInformtion = 1,
  BillingInformation = 2,
  OperationHours = 3,
}

export enum ClaimStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
}

export enum Menu {
  DASHBOARD = 1,
  ELIGIBILITY = 2,
  ELIGIBILITY_INQUIRY = 2.1,
  DENTAL_BENEFITS_ADVISOR = 2.2,
  CLAIMING = 3,
  DIRECT_CLAIMS_ENTRY = 3.1,
  CLAIMS = 3.2,
  CLAIM_FILE_SUBMISSION = 3.3,
  ERA_EFT_SEARCH = 3.4,
  SIMPLE_ATTACH = 4,
  SUBMIT_ATTACHMENTS = 4.1,
  ATTCHMENT_HISTORY = 4.2,
  PAYER_ENROLLMENT = 5,
  PAYER_ELIGIBILITY = 5.1,
  PAYER_ERA = 5.2,
  PAYER_EFT = 5.3,
  CREDENTIALING = 6,
  REPORT = 7,
  DENTICAL_REPORT = 7.1,
  NEBRASKA_REPORT = 7.2,
  ADMINISTRATION = 8,
  MANAGE_PRACTICE_SETUP = 8.1,
  MANAGE_ACCOUNT = 8.2,
  BILLING_HISTORY = 8.3,
  CLAIM_DOWNLOAD = 8.4,
  MANAGE_RULE = 8.5,
}

export enum Permission {
  ADDLEGALENTITY = 'ADDLEGALENTITY',
}

export enum diagnosisCodeQualifier {
  AB = 'AB',
  B = 'B',
}
export enum booleanValues {
  positive = 1,
  negative = 2,
}

export enum genderValues {
  male = 1,
  female = 2,
  other = 3,
}

export enum genderLabels {
  male = 'MALE',
  female = 'FEMALE',
  other = 'OTHER',
}

export enum capitalizeGenderLabels {
  male = 'Male',
  female = 'Female',
  other = 'Other',
}

export enum genderCodes {
  male = 'M',
  female = 'F',
  other = 'O',
  UnDifferentiable = 'U',
}

export enum orthoFlags {
  yes = 'Y',
  no = 'N',
}

export enum ComponentFlags { // TODO better name?
  PatientInfo = 'patientInfo',
  SubscriberInfoAddress = 'subscriberInfoAddress',
  SubscriberInfoGender = 'subscriberInfoGender',
  SubscriberInfo = 'subscriberInfo',
  SubscriberInfoDOB = 'subscriberInfoDOB',
  SubscriberNameInfo = 'subscriberNameInfo',
  Gender = 'gender',
  StateCode = 'stateCode',
  ZipCode = 'zipCode',
}

export enum DateFlags {
  Date = 'date',
  Month = 'month',
  Year = 'year',
}

export enum ERelationshipToSubscriber {
  SELF = 'Self',
  SPOUSE = 'Spouse',
  DEPENDENT = 'Dependent',
  OTHER = 'Other',
}

export type TRelationshipToSubscriber = ERelationshipToSubscriber;
export type TGender = EGender;
export type TCoverageType = ECoverageType;

export enum EGender {
  UNKNOWN = 'Unknown',
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum ECoverageType {
  UNKNOWN = 'Unknown',
  MEDICAL = 'Medical',
  DENTAL = 'Dental',
}
