import React, { Dispatch, useRef } from 'react';
import { Button, Text } from '@uicl/ui-core/dist';
import { IEraSearchDetailResponse } from 'api/EraSearchDetailView/interface';
import LoadingPage from '../../LoadingSpinner';
import Drawer from '../../Drawer';
import PayerIdentificationComponent from './PayerIdentificationComponent';
import PayeeIdentificationComponent from './PayeeIdentificationComponent';
import FinancialInfoComponent from './FinancialInfoComponent';
import ClaimPaymentInfoComponent from './ClaimPaymentInfoComponent';
import { useReactToPrint } from 'react-to-print';

interface IProps {
  onCloseDrawer: () => void;
  eraSearchTransactionResult: IEraSearchDetailResponse | undefined;
  isFetching: boolean;
  setView835Content: Dispatch<React.SetStateAction<string>>;
  onOpenView835FromDrawer: () => void;
}

const EraSearchDetailDrawer: React.FC<IProps> = ({
  onCloseDrawer,
  eraSearchTransactionResult,
  isFetching,
  onOpenView835FromDrawer,
}) => {
  const componentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderButtonSection = () => {
    return (
      <div className="no-print" style={{ display: 'flex' }}>
        <div style={{ marginRight: 10 }}>
          <Button onClick={handlePrint} buttonType="emphasized" dataTestId="print-btn">
            Print
          </Button>
        </div>
        <div style={{ marginRight: 10 }}>
          {' '}
          <Button onClick={onOpenView835FromDrawer} buttonType="standard" dataTestId="view835-era-details-view">
            View 835
          </Button>
        </div>

        <Button onClick={onCloseDrawer} buttonType="emphasized" dataTestId="close-era-details-view">
          Close
        </Button>
      </div>
    );
  };
  const renderHeaderView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Text color="secondary" variant="xLarge">
          ERA Details
        </Text>
        {renderButtonSection()}
      </div>
    );
  };

  const renderView = () => {
    return eraSearchTransactionResult ? (
      <div id="scrollableDiv">
        <div className="printable-section" ref={componentRef} id="printable-section">
          <PayerIdentificationComponent eraSearchDetailData={eraSearchTransactionResult} />
          <PayeeIdentificationComponent eraSearchDetailData={eraSearchTransactionResult} />
          <FinancialInfoComponent eraSearchDetailData={eraSearchTransactionResult} />
          <ClaimPaymentInfoComponent eraSearchDetailData={eraSearchTransactionResult} />
        </div>
      </div>
    ) : (
      <div id="scrollableDiv">There is an error with this transaction</div>
    );
  };

  return (
    <Drawer percentageView={true} containerWidth={'60vw'}>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div>
          <div>{renderHeaderView()}</div>

          {renderView()}
        </div>
      )}
    </Drawer>
  );
};

export default EraSearchDetailDrawer;
