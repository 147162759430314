import { FlexDiv } from '../../../../utils/constants';
import { TextWithLabel } from '../../../../utils/TextWithLabel';
import React from 'react';
import { IAttachmentProviderInfo, IClaimAttachmentsSearchResults } from '../../../../api/claimAttachments/interface';
import { Group, Card } from '@uicl/ui-core/dist';

interface IProps {
  providerDetails?: IAttachmentProviderInfo;
  attachmentSearchResult?: IClaimAttachmentsSearchResults;
}

const ProviderDetails: React.FC<IProps> = ({ providerDetails, attachmentSearchResult }) => {
  const Details = (
    <FlexDiv>
      <TextWithLabel
        text={attachmentSearchResult?.billingProvider}
        label={'Billing Provider'}
        labelVariant={'xxSmall'}
      />
      <TextWithLabel text={providerDetails?.providerName} label={'Provider Name'} labelVariant={'xxSmall'} />
      <TextWithLabel text={providerDetails?.taxId} label={'Tax ID'} labelVariant={'xxSmall'} />
      <TextWithLabel text={providerDetails?.npi} label={'Billing Provider NPI'} labelVariant={'xxSmall'} />
    </FlexDiv>
  );
  return (
    <Group
      dataTestId={'provider-details'}
      title={'Provider Details'}
      initialIsCollapsed
      isAccordion
      description={'All details of the provider associated to this claim'}
    >
      <Card children={Details} />
    </Group>
  );
};

export default ProviderDetails;
