import { apiInstance } from '../apiInstance';
import { IEraSearchRequestBody, IEraSearchResponse } from './interfaces';

export const getEraSearchDetails = (
  eraSearchRequestBody: IEraSearchRequestBody,
  dispatch?: any
): Promise<IEraSearchResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .post(`/era/search/`, eraSearchRequestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
