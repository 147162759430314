import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAttachmentFileInfo } from '../../api/attachments/interface';
import { IInitialStateAttachmentSlice, IPayerBusinessRule } from './interface';
import DateWrapper from '../../utils/DateWrapper';
import { DateFormat } from '../../utils/constants';
import { EAttachmentType, EOrientationType } from '../../api/attachments/enums';

const newAttachmentFileInfoInitialState = {
  orientationType: EOrientationType.NOT_APPLICABLE,
  documentTypeCode: EAttachmentType.EOB_COB,
  imageDate: new DateWrapper(undefined).formatDateString(DateFormat.UTCDateFormat),
} as IAttachmentFileInfo;

const initialState = {
  payerBusinessRules: [],
  attachments: [],
  newAttachmentFileInfo: newAttachmentFileInfoInitialState,
} as IInitialStateAttachmentSlice;

export const attachmentSlice = createSlice({
  name: 'attachmentSlice',
  initialState,
  reducers: {
    updateAttachmentMetadata: (state, action: PayloadAction<IPayload>) => {
      state.newAttachmentFileInfo[action.payload.key] = action.payload.value;
    },
    setAttachmentsData: (state, action: PayloadAction<IAttachmentFileInfo[]>) => {
      state.attachments = action.payload;
    },
    setAttachmentData: (state, action: PayloadAction<IUpdateAttachmentFileInfo>) => {
      const { attachment, index } = action.payload;
      state.attachments[index] = attachment;
    },
    setPayerRulesData: (state, action: PayloadAction<IPayerBusinessRule[]>) => {
      state.payerBusinessRules = action.payload;
    },
    addAttachmentFileInfo: (state) => {
      state.attachments.push(state.newAttachmentFileInfo);
    },
    setNewAttachmentFileInfo: (state, action: PayloadAction<IAttachmentFileInfo>) => {
      state.newAttachmentFileInfo = action.payload;
    },
    resetNewAttachmentFileInfo: (state) => {
      state.newAttachmentFileInfo = newAttachmentFileInfoInitialState;
    },
  },
});

export interface IPayload {
  key: string;
  value: string;
}

interface IUpdateAttachmentFileInfo {
  attachment: IAttachmentFileInfo;
  index: number;
}

export const {
  setPayerRulesData,
  updateAttachmentMetadata,
  setAttachmentsData,
  setAttachmentData,
  addAttachmentFileInfo,
  setNewAttachmentFileInfo,
  resetNewAttachmentFileInfo,
} = attachmentSlice.actions;

export const Keys = {
  documentTypeCode: 'documentTypeCode',
  orientationType: 'orientationType',
  imageDate: 'imageDate',
  narrative: 'narrative',
};

export default attachmentSlice.reducer;
