import React, { Dispatch, memo, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../api/claimView/interface';
import { claimSearchColumns } from './TableColumns';
import { ESortingDirection, TSortingField } from './interface';
import { EClaimSearchDrawerMode, IClaimSearch } from '../../../api/claimSearch/interface';
import { getProcessedClaimDetail } from '../../../api/claimView';
import { setAppError } from '../../../reduxStore/authSlice';
import { useAppDispatch } from '../../../reduxStore/reduxHooks';
import { Paperclip } from '@uicl/ui-core/dist';
import ViewClaimDetail from './ViewClaimDetail';
import { GetAttachmentContainer } from '../../../api/attachments';
import { CreateRawClaimData } from '../../ClaimAttachments/View/AttachmentMapper';
import { IAttachmentFileInfo } from '../../../api/attachments/interface';
import ClaimStatus from './ClaimStatus';
import moment from 'moment/moment';

enum tableSortingDirection {
  SORT_ASCENDING = 'SORT_ASCENDING',
  SORT_DESCENDING = 'SORT_DESCENDING',
}

interface IProps {
  openClaimDetail: boolean;
  onClickThrough: (e: any, row: any) => void;
  onCloseDrawer: () => void;
  claimViewData: IClaimViewResponse;
  setSortingDirection: Dispatch<React.SetStateAction<ESortingDirection>>;
  setSortingFieldArray: Dispatch<React.SetStateAction<TSortingField[]>>;
  sortingFieldArray: TSortingField[];
  claimSearchData: IClaimSearch[];
  setClaimViewData: Dispatch<React.SetStateAction<IClaimViewResponse>>;
  selectedClaim: IClaimSearch | undefined;
  setSelectedClaim: Dispatch<SetStateAction<IClaimSearch | undefined>>;
  setClaimDrawerMode: Dispatch<SetStateAction<EClaimSearchDrawerMode>>;
  claimDrawerMode: EClaimSearchDrawerMode;
  onAttachmentClickThrough: (e: any, row: any) => void;
}

const TableData: React.FC<IProps> = ({
  openClaimDetail,
  onClickThrough,
  onCloseDrawer,
  claimViewData,
  setSortingDirection,
  setSortingFieldArray,
  sortingFieldArray,
  claimSearchData,
  setClaimViewData,
  selectedClaim,
  setSelectedClaim,
  claimDrawerMode,
  setClaimDrawerMode,
  onAttachmentClickThrough,
}) => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [attachmentResponse, setAttachmentResponse] = useState<IAttachmentFileInfo[]>([]);

  const onSort = (column: any, direction: any, records: any) => {
    let sortingFieldArrayCopy = [...sortingFieldArray];
    const sorting = direction.sortingKey.split('|');
    const fieldName = sorting[0];
    const directionName =
      sorting[1] == tableSortingDirection.SORT_ASCENDING ? ESortingDirection.ASCENDING : ESortingDirection.DESCENDING;

    setSortingDirection(directionName);
    sortingFieldArrayCopy = [...sortingFieldArrayCopy, fieldName];
    setSortingFieldArray(sortingFieldArrayCopy);
  };

  useEffect(() => {
    const handleGetProcessedClaim = async () => {
      try {
        if (selectedClaim === undefined) {
          return;
        }
        setIsFetching(true);
        const processedClaimDetailResponse = await getProcessedClaimDetail(selectedClaim, dispatch);
        setClaimViewData(processedClaimDetailResponse);

        if (claimDrawerMode === EClaimSearchDrawerMode.VIEWATTACHMENT) {
          await handleOpenDrawer();
        } else {
          setClaimDrawerMode(EClaimSearchDrawerMode.VIEWCLAIM);
        }

        setIsFetching(false);
      } catch (err) {
        //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
        const error = err as Error;
        dispatch(setAppError({ message: error.message, isOpen: true }));

        setIsFetching(false);
      }
    };
    if (openClaimDetail) handleGetProcessedClaim();
  }, [selectedClaim]);

  const handleOpenDrawer = async () => {
    const handleGetAttachmentDetail = async () => {
      try {
        if (!selectedClaim?.attachmentId) return;

        setIsFetching(true);
        const rawClaimData = CreateRawClaimData(claimViewData);
        const attachmentContainer = await GetAttachmentContainer(parseInt(selectedClaim.attachmentId), rawClaimData);
        // for testing without worrying about the test data being linked together
        // const attachmentContainer = await GetAttachmentContainer(44825, rawClaimData);
        setAttachmentResponse(attachmentContainer.attachmentFileInfo);
        setClaimDrawerMode(claimDrawerMode);

        setIsFetching(false);
      } catch (err) {
        //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
        const error = err as Error;
        dispatch(setAppError({ message: error.message, isOpen: true }));

        setIsFetching(false);
      }
    };

    await handleGetAttachmentDetail();
  };

  const handleMapping = () =>
    claimSearchData.map((item, index) => {
      return {
        id: item.id,
        patientName: item.patientName,
        dateOfService: moment(item.dateOfService).format('MM/DD/YYYY'),
        processedOn: item.processedOn,
        displayProcessedOn: moment(item.processedOn).format('MM/DD/YYYY'),
        renderingProvider: item.renderingProvider,
        amount: item.amount,
        payer: item.payer,
        status: <ClaimStatus claimStatus={item.status} />,
        reason: item.reason,
        attachment: {
          domID: 'viewAttachment-button-' + index.toString(),
          dataTestId: 'viewAttachment-button-' + index.toString(),
          label: 'AttachmentOverview',
          name: item.attachmentId ? <Paperclip fillColor={'red'} /> : <Paperclip />,
          type: 'button',
          buttonType: 'deEmphasized',
          disabled: !item.attachmentId,
          onClick: (e: SyntheticEvent) => onAttachmentClickThrough(e, item),
        },
      };
    });

  return (
    <div>
      <Grid
        dataTestId="claim-search-grid"
        domID="claim-search-grid"
        isConfigurable={false}
        onClickThrough={onClickThrough}
        onSortGridColumn={onSort}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={claimSearchColumns}
        maxHeight="500px"
        isFixedHeader={true}
      />
      {openClaimDetail && (
        <ViewClaimDetail
          onCloseDrawer={onCloseDrawer}
          claimViewData={claimViewData}
          isFetching={isFetching}
          claimDrawerMode={claimDrawerMode}
          attachmentResponse={attachmentResponse}
        />
      )}
    </div>
  );
};

export default memo(TableData);
