import React, { memo } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { IEligibilityDeductiblesResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import { historicalDeductiblesTableColumns } from './HistoricalGridDetailsTableColumns';
import { convertToDollar } from '../../../utils/constants';

interface IProps {
  eligibilityDeductibleMaximumResponse: IEligibilityDeductiblesResponse | undefined;
  isFixedHeader: boolean;
}
const HistoricalDeductiblesDetailData: React.FC<IProps> = ({ eligibilityDeductibleMaximumResponse, isFixedHeader }) => {
  const handleMapping = () =>
    eligibilityDeductibleMaximumResponse?.deductible
      ? eligibilityDeductibleMaximumResponse?.deductible.map((item) => {
          return {
            deductible: item.deductibleType,
            timeOfCoverage:
              item.timeOfCoverage && item.timeOfCoverage != 'Remaining'
                ? item.amountType + '' + ' for ' + item.timeOfCoverage
                : item.amountType,
            amount: item.benefitAmount == undefined ? 'Not Returned by Payer' : convertToDollar(item.benefitAmount),
          };
        })
      : [];

  return (
    <div style={{ marginTop: 20, paddingBottom: 50, paddingLeft: 50, paddingRight: 20 }}>
      <Grid
        dataTestId="eligibility-deductiblesdetail-grid"
        domID="eligibility-deductibles-grid"
        isConfigurable={false}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={historicalDeductiblesTableColumns}
        isFixedHeader={isFixedHeader}
        emptyGridMessage="Not Returned by Payer"
      />
    </div>
  );
};
export default memo(HistoricalDeductiblesDetailData);
