import React from 'react';
import { Text, Input } from '@uicl/ui-core/dist';
import { StyledBox } from './style';
import { IAccountInfo } from '../../api/onboarding/interface';

const accountInfoInputArray = (accountInfo?: IAccountInfo) => {
  return [
    {
      key: 'accountName',
      label: 'Account Name',
      domID: 'account-name',
      width: '49%',
      marginBottom: 10,
      value: accountInfo?.accountName,
      display: true,
    },
    {
      key: 'phone',
      label: 'Phone',
      domID: 'phone',
      width: '0%',
      marginBottom: 10,
      value: '555-555-5555',
      display: false,
    },
    {
      key: 'customerId',
      label: 'Customer Id / MDM Id',
      domID: 'customer-id',
      width: '49%',
      marginBottom: 10,
      value: accountInfo?.customerId,
      display: true,
    },
    {
      key: 'adminName',
      label: 'Admin Name',
      domID: 'admin-name',
      width: '0%',
      marginBottom: 10,
      value: 'Not Important',
      display: false,
    },
    {
      key: 'adminPhone',
      label: 'Admin Phone',
      domID: 'admin-phone',
      width: '0%',
      marginBottom: 10,
      value: '555-555-5555',
      display: false,
    },
    {
      key: 'adminEmail',
      label: 'Admin Email',
      domID: 'admin-email',
      width: '0%',
      marginBottom: 10,
      value: 'bogus_email@optum.com',
      display: false,
    },
    {
      key: 'addressLine1',
      label: 'Address Line 1',
      domID: 'address-line-1',
      width: '0%',
      marginBottom: 10,
      value: '123 Elm St.',
      display: false,
    },
    {
      key: 'addressLine2',
      label: 'Address Line 2',
      domID: 'address-line-2',
      width: '0%',
      marginBottom: 10,
      value: 'Suite 500',
      display: false,
    },
    {
      key: 'city',
      label: 'City',
      domID: 'city',
      width: '0%',
      marginBottom: 10,
      value: 'Anytown',
      display: false,
    },
    {
      key: 'zipCode',
      label: 'Zip Code',
      domID: 'zip-code',
      width: '0%',
      marginBottom: 10,
      value: '90210',
      display: false,
    },
  ];
};

interface IProps {
  accountInfo?: IAccountInfo;
  setAccountInfo: any;
}

const RenderAccountInfo: React.FC<IProps> = ({ accountInfo, setAccountInfo }) => {
  const handleOnChange = (e: any, key: string) => {
    const newValues = { [key]: e.target.value } as Partial<IAccountInfo>;
    setAccountInfo(newValues);
  };

  const addressStateChangedHandler = (e: any) => {
    const newValues = { ['stateCode']: e.value } as Partial<IAccountInfo>;
    setAccountInfo(newValues);
  };

  return (
    <>
      <StyledBox>
        <Text variant="large">Account Info</Text>
      </StyledBox>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {accountInfoInputArray(accountInfo).map((item) => (
          <div
            key={item.key}
            style={{ display: item.display ? 'inline' : 'none', width: item.width, marginBottom: item.marginBottom }}
          >
            <Input
              autoComplete="off"
              dataTestId={item.domID}
              domID={item.domID}
              label={item.label}
              initialValue={item.value}
              onChange={(e: any) => handleOnChange(e, item.key)}
            />
          </div>
        ))}
        {/*<RenderStateDropdown initialValue={'CA'} onChangeHandler={addressStateChangedHandler} />*/}
      </div>
    </>
  );
};

export default RenderAccountInfo;
