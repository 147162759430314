import React, { ChangeEvent, useEffect, useState } from 'react';
import HistoricalSearchComponent from '../../components/HistoricalTransactions/HistoricalSearchComponent';
import HistoricalTransactionsaSearchTableData from '../../components/HistoricalTransactions/HistoricalTransactionsTableData';
import { getHistoricalTransactionsSearchDetails } from '../../api/HistoricalTransactions';
import {
  IHistoricalTransactionsSearchRequestBody,
  IHistoricalTransactionsSearchRequestFrontBody,
  DateFilter,
  IHistoricalTransactionsSerchResults,
  ESortingDirectionTransactionsSearch,
} from '../../api/HistoricalTransactions/interfaces';
import { handleDocumentBodyScroll } from '../../utils/constants';
import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';

import {
  initialHistoricalSearchBody,
  initialHistoricalSearchFrontBody,
} from '../../api/HistoricalTransactions/mockResponses';
import moment from 'moment';
import { currentTransactionsOption, currentErrorOption } from '../../utils/constants';
import { Button, Next, Previous, Text } from '@uicl/ui-core/dist';
import { IEligibilityOverviewResponse } from '../../api/HistoricalTransactionDetailView/interfaces';
import { SelectDropdownValue } from '@uicl/ui-core/dist-es/Atoms/SelectDropdown/types';
const limit = 20;
const defaultSortingColumn = 'timeoftransaction';

const HistoricalTransactions = () => {
  const [historicalearchRequestBody, sethistoricalearchRequestBody] =
    useState<IHistoricalTransactionsSearchRequestFrontBody>(initialHistoricalSearchFrontBody);
  const [historicalearchRequestDbBody, sethistoricalearchRequestDBBody] =
    useState<IHistoricalTransactionsSearchRequestBody>(initialHistoricalSearchBody);
  const [historicalSearchResult, setHistoricalSearchResult] = useState<IHistoricalTransactionsSerchResults[]>([]);
  const [sortingDirection, setSortingDirection] = useState<ESortingDirectionTransactionsSearch>(
    ESortingDirectionTransactionsSearch.DESCENDING
  );
  const [sortingField, setSortingField] = useState<string>(defaultSortingColumn);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pagingOptions, setPagingOptions] = useState<IPagingOptions>({} as IPagingOptions);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [initialOption, setInitiOption] = useState<{
    label: string;
    value: string;
    dataTestId: string;
    id: string;
  } | null>({
    dataTestId: 'trans-testid',
    id: 'retrans1d',
    label: 'Last 1 hour',
    value: '1',
  });

  const [initialErrorOption, setinitialErrorOption] = useState<{
    label: string;
    value: string;
    dataTestId: string;
    id: string;
  } | null>({
    dataTestId: 'false-id',
    id: 'falseid',
    label: 'False',
    value: '0',
  });
  const [currentTransactions, setCurrentTransactions] = useState('1');
  const [openX12Transaction, setOpenX12Transaction] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [timeOfTransaction, setTimeOfTransaction] = useState('');
  const [showValidationMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentSetErrorOption, setCurrentErrorOption] = useState('');
  const [eligibilityResponseDetailBody, setEligibilityResponseDetailsBody] = useState<IEligibilityOverviewResponse>();
  const listOfPanels = {
    payer: true,
    provider: true,
    subscriber: true,
    dependents: true,
    coveragetype: true,
    coveragedates: true,
    deductiblemaximum: true,
    planprovision: true,
    coverage: true,
    frequencylimitation: true,
    agelimitation: true,
    aaaerror: true,
  };
  const onUpdatePreviewPanel = () => {
    const copy = { ...defaultExpand };
    copy['coverage'] = true;
    copy['payer'] = true;
    copy['provider'] = true;
    copy['planprovision'] = true;
    copy['deductiblemaximum'] = true;
    copy['dependents'] = true;
    copy['frequencylimitation'] = true;
    copy['coveragetype'] = true;
    copy['agelimitation'] = true;
    copy['subscriber'] = true;
    copy['coveragedates'] = true;
    copy['aaaerror'] = true;
    setDefaultExpand(copy);
  };

  const [openPrintPreview, setPrintPreview] = useState(false);
  const [isFixedHeader, setisFixedHeader] = useState(true);
  const [defaultExpand, setDefaultExpand] = useState(listOfPanels);
  const onPrintPreviewClick = () => {
    setPrintPreview(true);
    setisFixedHeader(false);
  };
  const onClosePrintPreview = () => {
    setPrintPreview(false);
    setisFixedHeader(true);
    onUpdatePreviewPanel();
  };

  const onClickThrough = async (e: any, item: any) => {
    e.stopPropagation();
    console.log('onClickThrough');
    setOpenX12Transaction(true);
    setTransactionId(item.transactionId);
    setTimeOfTransaction(item.timeoftransaction);
  };

  useEffect(() => {
    handleOnSearch();
  }, [sortingDirection, sortingField, page]);

  const onCloseDrawer = () => {
    handleDocumentBodyScroll(true);
    setOpenX12Transaction(false);
    onUpdatePreviewPanel();
  };

  const handleOnMainReset = () => {
    setInitiOption({
      dataTestId: 'trans-testid',
      id: 'retrans1d',
      label: 'Last 1 hour',
      value: '1',
    });

    setStartDate(moment(new Date()));
    setEndDate(moment(new Date()));
    setCurrentTransactions('');
    setErrorMessage('');
    setShowErrorMessage(false);

    setinitialErrorOption({
      dataTestId: 'false-id',
      id: 'falseid',
      label: 'False',
      value: '0',
    });
    const copy = { ...initialHistoricalSearchFrontBody };
    copy.sorting.fields.length = 0;

    sethistoricalearchRequestBody(copy);
    setCurrentErrorOption('');
    const copyDB = { ...initialHistoricalSearchBody };

    copyDB.filters.vendorIds.length = 0;

    copyDB.filters.taxId.length = 0;
    copyDB.sorting.fields.length = 0;

    sethistoricalearchRequestDBBody(copyDB);
    setSortingField(defaultSortingColumn);
    setPage(1);
  };
  const handleOnReset = () => {
    setinitialErrorOption({
      dataTestId: 'false-id',
      id: 'falseid',
      label: 'False',
      value: '0',
    });
    const copy = { ...initialHistoricalSearchFrontBody };
    copy.sorting.fields.length = 0;
    copy.isErrorsOnly = false;
    sethistoricalearchRequestBody(copy);
    setCurrentErrorOption('');
    const copyDB = { ...initialHistoricalSearchBody };

    copyDB.filters.vendorIds.length = 0;
    copyDB.filters.isErrorsOnly = false;
    copyDB.filters.taxId.length = 0;
    copyDB.sorting.fields.length = 0;

    sethistoricalearchRequestDBBody(copyDB);
    setCurrentTransactions('0');
  };

  const handleOnSearch = async (newSearchOffset?: number) => {
    try {
      const copy = { ...historicalearchRequestBody };

      //inputcheck used to check if there are values.
      //Not validating for transactionid.
      const currentdate = moment(new Date());
      const start = moment(startDate, 'MM/DD/YYYY');
      const end = moment(endDate, 'MM/DD/YYYY');
      const endDateDiff = end.diff(currentdate, 'days', true);
      const startDateDiff = start.diff(currentdate, 'days', true);
      const startMonthiff = start.diff(currentdate, 'months', true);
      const endMonthiff = end.diff(currentdate, 'months', true);

      const endMonth = moment(endDate);

      const monthDiff = start.diff(endMonth, 'days', true);
      // const endMonthiff = start.diff(endMonth, 'months', true);

      const daysInMonths = moment(startDate).daysInMonth();

      if (copy.transactionId != '') {
        //expect a different validation

        if (startMonthiff < -15) {
          setShowErrorMessage(true);
          setErrorMessage("The start and end dates provided must be within 15 months of today's date");
          return;
        }
      } else {
        // if there are no values in the input field, you can search only within a day date range
        if (
          //const usStateCodesArray: string[] = [
          copy.payerId == '' &&
          copy.vendorIds.length == 0 &&
          copy.taxId.length == 0 &&
          copy.insuredId == '' &&
          copy.patientFirstName == '' &&
          copy.patientLastName == '' &&
          !copy.isErrorsOnly
        ) {
          if (!start.isSame(end, 'day')) {
            setShowErrorMessage(true);
            setErrorMessage('Please narrow your search to 1 day, or enter additional search filters');
            // setFirstLoad(false);
            return;
          }
        } else {
          //start and end dates must be within 15months of today's date
          if (startMonthiff < -15) {
            setShowErrorMessage(true);
            setErrorMessage("The start date provided must be within 15 months of today's date");
            //  setFirstLoad(false);
            return;
          }
          //does not apply
          // if (endMonthiff < -15) {
          //   setShowErrorMessage(true);
          //   setErrorMessage("The end dates provided must be within 15 months of today's date");
          //   return;
          // }

          //start date or end date cannot go over 30 days
          // start date cannot go back over 30days
          if (daysInMonths >= 31) {
            //has 31 days
            if (monthDiff < -30) {
              setShowErrorMessage(true);
              setErrorMessage('Please narrow your search to 30 days');
              return;
            }
          } else {
            //has 30 days
            if (monthDiff < -29) {
              setShowErrorMessage(true);
              setErrorMessage('Please narrow your search to 30 days');
              return;
            }
          }
        }
      }

      const offset = (page - 1) * limit;

      historicalearchRequestDbBody.filters.transactionId = copy.transactionId;
      historicalearchRequestDbBody.filters.payerId = copy.payerId;
      if (copy.vendorIds != '') {
        historicalearchRequestDbBody.filters.vendorIds = [copy.vendorIds];
      }
      if (copy.taxId != '') {
        historicalearchRequestDbBody.filters.taxId = [copy.taxId];
      }
      historicalearchRequestDbBody.filters.insuredId = copy.insuredId;
      historicalearchRequestDbBody.filters.patientFirstName = copy.patientFirstName;
      historicalearchRequestDbBody.filters.patientLastName = copy.patientLastName;
      historicalearchRequestDbBody.filters.isErrorsOnly = copy.isErrorsOnly;
      historicalearchRequestDbBody.currentTransactionDurationInHours =
        copy.transactionId != '' ? 0 : Number(currentTransactions);
      const requestBody = {
        ...historicalearchRequestDbBody,
        sorting: {
          direction: sortingDirection,
          fields: [sortingField],
        },
        dateRangeFilter: {
          field: 'TrxDate',
          startDate:
            historicalearchRequestDbBody.currentTransactionDurationInHours > 0
              ? moment(startDate).toISOString()
              : moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        },
        offset: newSearchOffset == 0 ? newSearchOffset : offset,
        limit,
      };
      if (newSearchOffset == 0) {
        setPage(1);
      }
      const response = await getHistoricalTransactionsSearchDetails(requestBody);
      setHistoricalSearchResult(response.results);
      setTotalCount(response.totalCount);
      setPagingOptions(response.pagingOptions);
      setShowErrorMessage(false);
      setErrorMessage('');
    } catch (err) {
      const error = err as Error;
      console.log(error);
    }
  };

  const handleOnChange = (e?: ChangeEvent<HTMLInputElement>, dateFilterOption?: DateFilter) => {
    const copy = { ...historicalearchRequestBody };

    if (e) {
      const {
        target: { name, value },
      } = e;
      copy[name] = value;
    }

    sethistoricalearchRequestBody(copy);
  };
  const handleOnTransactionChange = (opt: SelectDropdownValue) => {
    const value = opt ? opt.value : null;
    setCurrentTransactions(value as string);
    const currentdate = moment(new Date()).format('L');
    const startD = startDate.format('L');
    const endD = endDate.format('L');

    if (startD != currentdate || endD != currentdate) {
      setInitiOption({
        dataTestId: 'nofilter-testid',
        id: 'filter',
        label: 'No filter',
        value: '0',
      });
      setCurrentTransactions('0');
    }
  };
  const handleOnClose = () => {
    if (currentSetErrorOption == '1') {
      setinitialErrorOption({
        dataTestId: 'true_id',
        id: 'trueid',
        label: 'True',
        value: '1',
      });
    } else {
      setinitialErrorOption({
        dataTestId: 'false-id',
        id: 'falseid',
        label: 'False',
        value: '0',
      });
    }
    setIsOpen(false);
  };
  const handleOnSearchFilterClick = () => {
    setIsOpen(true);
  };
  const handlePageNext = () => {
    setPage((prev) => prev + 1);
  };

  const handlePagePrevious = () => {
    /* istanbul ignore next */
    setPage((prev) => prev - 1);
  };

  const handlePanelChange =
    (
      panel:
        | 'payer'
        | 'provider'
        | 'subscriber'
        | 'dependents'
        | 'coveragetype'
        | 'coveragedates'
        | 'deductiblemaximum'
        | 'planprovision'
        | 'coverage'
        | 'frequencylimitation'
        | 'agelimitation'
        | 'aaaerror'
    ) =>
    (event: React.SyntheticEvent, expanded: boolean) => {
      const copy = { ...defaultExpand };
      copy[panel] = expanded;
      setDefaultExpand(copy);
    };

  return (
    <div data-testid="historical-search-page-testid" style={{ marginLeft: 100 }}>
      <div style={{ marginTop: 60 }}>
        <HistoricalSearchComponent
          historicalTransactionsSearchRequestBody={historicalearchRequestBody}
          handleOnReset={handleOnReset}
          handleOnSearch={handleOnSearch}
          handleOnChange={handleOnChange}
          handleOnMainReset={handleOnMainReset}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          initialOption={initialOption}
          setCurrentTransactions={setCurrentTransactions}
          handleOnClose={handleOnClose}
          handleOnSearchFilterClick={handleOnSearchFilterClick}
          isOpen={isOpen}
          initialErrorOption={initialErrorOption}
          currentTransactionsOption={currentTransactionsOption}
          currentErrorOption={currentErrorOption}
          setCurrentErrorOption={setCurrentErrorOption}
          setInitiOption={setInitiOption}
          handleOnTransactionChange={handleOnTransactionChange}
        />
      </div>

      {showValidationMessage && (
        <p
          data-testid="eligibilityErrorMessage"
          style={{ display: 'flex', justifyContent: 'center', color: 'red', marginTop: 30 }}
        >
          {errorMessage}
        </p>
      )}
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {/* @ts-ignore */}

        <Text variant="small" className="pagination-text-color">
          {pagingOptions.offset} of {totalCount}
        </Text>

        <div style={{ display: 'flex' }}>
          <Button
            buttonType="deEmphasized"
            dataTestId={'left-arrow'}
            domID={'left-arrow'}
            icon={Previous}
            size="small"
            type="button"
            className="action-buttons"
            onClick={handlePagePrevious}
            disabled={page === 1 ? true : false}
          />
          <Button
            buttonType="deEmphasized"
            dataTestId={'right-arrow'}
            domID={'right-arrow'}
            icon={Next}
            size="small"
            type="button"
            className="action-buttons"
            disabled={pagingOptions.morePages ? false : true}
            onClick={handlePageNext}
          />
        </div>
      </div>
      {!showValidationMessage && (
        <div>
          <HistoricalTransactionsaSearchTableData
            setSortingDirection={setSortingDirection}
            historicalSearchResult={historicalSearchResult}
            onClickThrough={onClickThrough}
            openX12Transaction={openX12Transaction}
            onCloseDrawer={onCloseDrawer}
            transactionId={transactionId}
            timeOfTransaction={timeOfTransaction}
            setSortingField={setSortingField}
            sortingField={sortingField}
            setEligibilityResponseDetailsBody={setEligibilityResponseDetailsBody}
            eligibilityResponseDetailsBody={eligibilityResponseDetailBody}
            isFixedHeader={isFixedHeader}
            defaultExpand={defaultExpand}
            setDefaultExpand={setDefaultExpand}
            openPrintPreview={openPrintPreview}
            onPrintPreviewClick={onPrintPreviewClick}
            onClosePrintPreview={onClosePrintPreview}
            handlePanelChange={handlePanelChange}
          />
        </div>
      )}
    </div>
  );
};
export default HistoricalTransactions;
