import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv, ClaimGridEndDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../../api/claimView/interface';
import { handleParseAddress } from '../../../../utils/constants';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const PayerIdentification: React.FC<IProps> = ({ claimViewData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="payeridentificationContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="large">Payer Identification</Text>
      </div>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'PayerIdentification-payerinfo-name'}>
          <Text color="secondary" variant="small">
            Payer
          </Text>

          <Text>{claimViewData.payerInfo.name}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'PayerIdentification-payerinfo-address'}>
            <div>
              <Text color="secondary" variant="small">
                Address
              </Text>

              <Text>{handleParseAddress(claimViewData.payerInfo.address)}</Text>
            </div>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridEndDiv></ClaimGridEndDiv>
        </ClaimGridDiv>
      </InsuredStyledDiv>
    </div>
  );
};

export default PayerIdentification;
