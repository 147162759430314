import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  height: 100vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Error = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '70px' }} />
      <div style={{ width: '85%' }}>
        <StyledDiv data-testid="error-page">
          <div>
            An unexpected error has occured. Please check the Notification center at the top right of the screen for
            more details.
            <br />
            Try closing the browser and reopening the web application. If issues persist, please contact the
            administrator.
          </div>
        </StyledDiv>
      </div>
    </div>
  );
};

export default Error;
