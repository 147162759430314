import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserPermission } from '../../api/user/interface';
import { IInitialUserPermissionState } from './interfaces';

const initialState = {
  permissions: undefined,
} as IInitialUserPermissionState;

export const userPermissionsSlice = createSlice({
  name: 'userPermissionsSlice',
  initialState,
  reducers: {
    setUserPermissions: (state, action: PayloadAction<IUserPermission[]>) => {
      state.permissions = action.payload;
    },
  },
});

export const { setUserPermissions } = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;
