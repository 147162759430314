import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TokenData } from '../../model/UserDetail';
import { IInitialStateUserSlice, IUserPayload } from './interfaces';
import { IUserAccountInfo } from 'api/user/interface';

const defaultState = {
  user: undefined,
  ciamUserInfo: undefined,
  userTokenData: undefined,
  account: undefined,
};

const initialState = {
  ...defaultState,
} as IInitialStateUserSlice;

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserPayload>) => {
      const { payload: user } = action;

      state.user = {
        company: user?.company,
        email: user?.email,
        firstName: user?.given_name,
        lastName: user?.family_name,
        roles: user?.roles,
        //TODO: DCP2-7322 remove isAdmin
        isAdmin: user.isAdmin,
      };
    },

    setAccountDetails: (state, action: PayloadAction<IUserAccountInfo>) => {
      state.account = action.payload;
    },

    setTokenData: (state, action: PayloadAction<TokenData>) => {
      state.userTokenData = action.payload;
    },

    clearUserStoredData: (state, action: PayloadAction) => {
      state = defaultState;
    },
  },
});

export const { setTokenData, clearUserStoredData, setUser, setAccountDetails } = userSlice.actions;

export default userSlice.reducer;
