import React, { SyntheticEvent, useRef } from 'react';
import Drawer from '../../Drawer';
import { Button, Text } from '@uicl/ui-core/dist';
import { Disclaiamer } from '../../../utils/constants';
import { useReactToPrint } from 'react-to-print';
import { IEligibilityOverviewResponse } from '../../../api/HistoricalTransactionDetailView/interfaces';
import OverViewTabSection from './OverViewTabSection';
import { DefaultExpandList } from '../../../api/HistoricalTransactionDetailView/interfaces';

interface IProps {
  onClosePrintPreview: () => void;
  transactionId: string;
  timeOfTransaction: string;
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  isFixedHeader: boolean;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}

const PrintPreview: React.FC<IProps> = ({
  onClosePrintPreview,
  transactionId,
  timeOfTransaction,
  eligibilityResponse,
  isFixedHeader,
  defaultExpand,
  setDefaultExpand,
  handlePanelChange,
}) => {
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderButtonSection = () => {
    return (
      <div className="no-print" style={{ display: 'flex' }}>
        <div style={{ marginRight: 10 }}>
          <Button onClick={handlePrint} buttonType="emphasized" dataTestId="print-btn">
            Print
          </Button>
        </div>
        <Button
          onClick={onClosePrintPreview}
          buttonType="emphasized"
          domID="close-print-view"
          dataTestId="close-print-view"
        >
          Close
        </Button>
      </div>
    );
  };

  const renderHeaderView = () => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <Text color="secondary" variant="xLarge">
            Transaction ID {transactionId}
          </Text>
          {renderButtonSection()}
        </div>
        <div style={{ justifyContent: 'space-between', marginTop: 10, marginLeft: 5 }}>
          <Text color="secondary" variant="small">
            {timeOfTransaction}
          </Text>
        </div>
      </div>
    );
  };

  const renderView = () => {
    return (
      <div>
        <OverViewTabSection
          setDefaultExpand={setDefaultExpand}
          handlePanelChange={handlePanelChange}
          defaultExpand={defaultExpand}
          eligibilityResponse={eligibilityResponse}
          isFixedHeader={isFixedHeader}
        />
      </div>
    );
  };
  const renderfooterView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Text color="secondary" variant="small">
          {Disclaiamer}
        </Text>
      </div>
    );
  };
  return (
    <Drawer percentageView={true} containerWidth={'80vw'}>
      <div className="printable-section tab-content-spacing" ref={componentRef} id="printable-section">
        {renderHeaderView()}
        {renderView()}
        {renderfooterView()}
      </div>
    </Drawer>
  );
};

export default PrintPreview;
