import { Input, DateRangePicker } from '@uicl/ui-core/dist';
import moment from 'moment/moment';
import { Button, ButtonGroup } from '@uicl/ui-core/dist';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { getAttachmentDetails, searchClaimAttachments } from '../../../api/claimAttachments';
import AttachmentsSearchTableData from './AttachmentSearchTableData';
import {
  IHasClaimAttachmentSearchInputErrors,
  IClaimAttachmentsSearchRequest,
  IClaimAttachmentsSearchResponse,
  IClaimAttachmentsSearchResults,
} from '../../../api/claimAttachments/interface';
import { setAppError } from '../../../reduxStore/authSlice';
import { useAppDispatch } from '../../../reduxStore/reduxHooks';
import { EPayerAttachmentSortingDirection, TPayerAttachmentSortingField } from './interface';
import { isFormIsValid } from '../../../utils/payerAttachmentSearch';
import PagingControls from '../../../components/Paging/PagingControls';
import { Moment } from 'moment';
import { searchInputs } from './SearchInputs';
import SearchBy from './searchByEnum';
import { LoadingIndicator } from '@uicl/ui-core/dist';

const dateRangeField = 'DateOfService';
const StyledBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

// initial States
// const testDataDate = moment(new Date(2024, 8, 23)); // for local testing, avoids clicking on date picker
export const initialClaimAttachmentsSearchRequest: IClaimAttachmentsSearchRequest = {
  payerId: '',
  attachmentId: '',
  billingProviderTaxId: '',
  insuredId: '',
  patientLastName: '',
  dateRangeFilter: {
    field: ' ',
    startDate: moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD'),
    // startDate: moment(testDataDate).subtract(7, 'days').format('YYYY-MM-DD'), // for local testing, avoiding clicking on date picker
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    // endDate: moment(testDataDate).format('YYYY-MM-DD'), // for local testing, avoiding clicking on date picker
  },
  sorting: {
    direction: '',
    fields: [],
  },
  offset: 0,
  limit: 20,
};

const initialErrState = {
  hasErrorPAPayerID: false,
  hasErrorPAAttachmentId: false,
  hasErrorPABillingProviderTaxID: false,
  hasErrorPAInsuredID: false,
  hasErrorPatientLastName: false,
  hasErrorDateRangePicker: false,
};

const ClaimAttachments: React.FC = () => {
  const dispatch = useAppDispatch();

  // handles the different search states we can be in
  const [searchBy, setSearchBy] = useState<SearchBy>(SearchBy.Unknown);
  // controls performing the API call in the useEffect
  const [performSearch, setPerformSearch] = useState(false);
  // disables paging buttons if the user starts creating a new request (inputting new values in search fields)
  const [disablePagingControls, setDisablePagingControls] = useState(false);

  const [payerAttachmentSearchRequest, setPayerAttachmentSearchRequest] = useState<IClaimAttachmentsSearchRequest>(
    initialClaimAttachmentsSearchRequest
  );
  const [searchResponse, setSearchResponse] = useState<IClaimAttachmentsSearchResponse | undefined>(undefined);
  const [payerAttachmentError, setPayerAttachmentErrors] = useState<IHasClaimAttachmentSearchInputErrors>({
    ...initialErrState,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [sortingDirection, setSortingDirection] = useState<EPayerAttachmentSortingDirection>(
    EPayerAttachmentSortingDirection.DESCENDING
  );
  const [sortingFieldArray, setSortingFieldArray] = useState<TPayerAttachmentSortingField[]>([
    TPayerAttachmentSortingField.SERVICEDATELOW,
  ]);

  // useEffects
  useEffect(() => {
    // this is set up in this useEffect this way to avoid a race condition when updating state data.
    //  Previously we had it set up to call the API on button clicks but the API call would happen before
    //  the state request data got updated and make the wrong request.
    if (performSearch) void getAttachmentSearchResults();
  }, [performSearch]);

  // input functions
  const handleOnReset = () => {
    const copy = { ...initialClaimAttachmentsSearchRequest };
    const clearErrors = { ...initialErrState };
    setPayerAttachmentSearchRequest(copy);
    setPayerAttachmentErrors(clearErrors);
    setSearchResponse(undefined);
    setSearchBy(SearchBy.Unknown);
  };

  const handleSetErrors = (key: string, value: boolean) => {
    const copy = { ...payerAttachmentError };
    copy[key] = value;
    setPayerAttachmentErrors(copy);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    regex: string,
    validationKey: string
  ) => {
    if (e.target.value && key === 'attachmentId') {
      setSearchBy(SearchBy.GetDetails);
    } else if (e.target.value && key !== 'attachmentId') {
      setSearchBy(SearchBy.Search);
    } else {
      setSearchBy(SearchBy.Unknown);
    }

    const copy = { ...payerAttachmentSearchRequest } as IClaimAttachmentsSearchRequest;
    const {
      target: { value },
    } = e;
    copy[key] = value;

    ValidateInputAgainstRegex(regex, validationKey, value);
    setPayerAttachmentSearchRequest(copy);
    setDisablePagingControls(true);
    setSearchResponse(undefined);
  };

  const ValidateInputAgainstRegex = (regex: string, validationKey: string, value: string) => {
    if (regex && validationKey) {
      const regexp = new RegExp(regex);
      if (!regexp.test(value)) {
        handleSetErrors(validationKey, true);
      } else {
        handleSetErrors(validationKey, false);
      }
    }
  };
  const onDatesChangeHandler = (date: { startDate: Moment; endDate: Moment }) => {
    const copy = {
      ...payerAttachmentSearchRequest,
      dateRangeFilter: { startDate: date?.startDate?.toISOString(), endDate: date?.endDate?.toISOString() },
    } as IClaimAttachmentsSearchRequest;
    setPayerAttachmentSearchRequest(copy);
    setDisablePagingControls(true);

    if (date?.endDate?.diff(date?.startDate, 'days', false) > 7) {
      handleSetErrors('hasErrorDateRangePicker', true);
    } else {
      handleSetErrors('hasErrorDateRangePicker', false);
    }
    setSearchResponse(undefined);
  };

  // if we're trying to search by attachmentId, disable the other input fields and vice versa
  const isDisabled = (key: string) => {
    // 3 states: initial (undefined), searching by attachmentId (getDetails), or searching general (searchPayerAttachments)
    if (searchBy === SearchBy.Unknown) {
      return false;
    } else if (searchBy === SearchBy.GetDetails && key === 'attachmentId') {
      return false;
    } else if (searchBy === SearchBy.Search && key !== 'attachmentId') {
      return false;
    }
    return true;
  };

  // paging
  enum Page {
    Prev,
    Next,
  }

  const GetCurrentPage = (): number => {
    if (searchResponse?.offset && searchResponse?.limit) return Math.ceil(searchResponse.offset / searchResponse.limit);
    else return 0;
  };

  const GetTotalPages = (): number => {
    if (searchResponse?.offset && searchResponse?.limit)
      return Math.ceil(searchResponse.totalCount / searchResponse.limit);
    else return 0;
  };

  const CyclePage = async (page: Page) => {
    const copy = { ...payerAttachmentSearchRequest };
    if (page === Page.Prev) {
      const prevOffset = copy.offset - copy.limit;
      copy.offset = prevOffset < 0 ? 0 : prevOffset;
    } else {
      copy.offset = copy.offset + copy.limit;
    }
    setPayerAttachmentSearchRequest(copy);
    setPerformSearch(true);
  };

  // API call
  const getAttachmentSearchResults = async () => {
    try {
      setIsLoading(true);
      const request = { ...payerAttachmentSearchRequest };

      if (isFormIsValid(payerAttachmentError)) {
        // if its search by details, get attachment details (is this what the detail drawer is fetching?)
        if (searchBy === SearchBy.GetDetails && request.attachmentId) {
          const response = await getAttachmentDetails(request.attachmentId);
          setSearchResponse({
            offset: 1,
            limit: 20,
            morePages: false,
            totalCount: 1,
            results: [
              {
                attachmentId: response.attachmentId,
                patientFirstName: response.patientDetails.patientFirstName,
                patientLastName: response.patientDetails.patientLastName,
                requestDate: response.requestDate,
                dateOfService: response.dateOfService,
                amount: response.amount,
                billingProvider: response.providerDetails.providerName,
              },
            ] as IClaimAttachmentsSearchResults,
          } as IClaimAttachmentsSearchResponse);
        } else {
          // else we're doing the attachment payer search
          const requestBody = {
            payerId: request.payerId ? request.payerId : undefined,
            billingProviderTaxId: request.billingProviderTaxId ? request.billingProviderTaxId : undefined,
            insuredId: request.insuredId ? request.insuredId : undefined,
            patientLastName: request.patientLastName ? request.patientLastName : undefined,
            dateRangeFilter: {
              field: dateRangeField,
              startDate: request.dateRangeFilter.startDate,
              endDate: request.dateRangeFilter.endDate,
            },
            sorting: {
              direction: sortingDirection,
              fields: sortingFieldArray,
            },
            offset: request.offset,
            limit: request.limit,
          } as IClaimAttachmentsSearchRequest;
          const response = await searchClaimAttachments(requestBody);
          setSearchResponse(response);
          return response;
        }
      }
    } catch (err) {
      //TODO: https://jira.healthcareit.net/browse/DCP2-7345 proper handling of error in catch block
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
      setSearchResponse(undefined);
    } finally {
      setPerformSearch(false);
      setIsLoading(false);
      setDisablePagingControls(false);
    }
  };

  // render methods
  const renderSearchCriteria = () => {
    return (
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '80%' }}>
            {searchInputs(payerAttachmentSearchRequest, payerAttachmentError).map((item) => {
              return (
                <div style={{ width: item.width, marginBottom: item.marginBottom }}>
                  <Input
                    autoComplete="off"
                    size="medium"
                    dataTestId={item.domID}
                    domID={item.domID}
                    label={item.label}
                    disabled={isDisabled(item.key)}
                    value={item.initialValue}
                    errorMessage={item.errorMessage}
                    alertMessage={item.errorMessage}
                    hasError={item.hasError}
                    maxLength={item.maxLength}
                    initialValidationState={item.initialValidationState}
                    regex={item.regex}
                    onChange={(e: any) => handleOnChange(e, item.key, item.regex, item.validationKey)}
                    onBlur={(e: any) => {
                      const value = e.currentTarget.value;
                      ValidateInputAgainstRegex(item.regex, item.validationKey, value);
                    }}
                  />
                </div>
              );
            })}
            <div style={{ width: '23%', marginBottom: 10 }}>
              <DateRangePicker
                dataTestId="test-dateOfService"
                domID="test-dateOfService"
                minimumNights={0}
                numberOfMonths={1}
                openDirection="down"
                showCalendarIcon
                initialEndDate={moment(payerAttachmentSearchRequest.dateRangeFilter.endDate)}
                initialStartDate={moment(payerAttachmentSearchRequest.dateRangeFilter.startDate)}
                size="medium"
                maxDate={moment()}
                label="Date of Service"
                errorMessage="Date range must be within 7 days"
                hasError={payerAttachmentError.hasErrorDateRangePicker}
                onDatesChange={onDatesChangeHandler}
                disabled={isDisabled('dateOfService')}
              />
            </div>
            <div style={{ width: '49%', marginBottom: 10, marginTop: 15 }}>
              {/*@ts-ignore*/}
              <ButtonGroup>
                <Button
                  dataTestId="test-submit"
                  domID="test-submit"
                  name="Search"
                  buttonType="emphasized"
                  size="medium"
                  type="submit"
                  onClick={() => setPerformSearch(true)}
                />
                <Button
                  dataTestId="test-reset"
                  domID="test-reset"
                  name="Reset"
                  buttonType="unstyled"
                  size="medium"
                  type="reset"
                  onClick={handleOnReset}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <PagingControls
              previousPageHandler={() => CyclePage(Page.Prev)}
              nextPageHandler={() => CyclePage(Page.Next)}
              isFirstPage={disablePagingControls || GetCurrentPage() <= 1}
              isLastPage={disablePagingControls || !searchResponse?.morePages}
              currentPage={GetCurrentPage()}
              totalPages={GetTotalPages()}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSearchResults = () => (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 50 }}>
      <div style={{ justifyContent: 'space-between' }}>
        <AttachmentsSearchTableData data={searchResponse?.results}></AttachmentsSearchTableData>
      </div>
    </div>
  );

  return (
    <>
      <StyledBox>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100px' }} />
          <div style={{ width: '90vw' }}>
            {renderSearchCriteria()}
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <LoadingIndicator />
              </div>
            ) : (
              renderSearchResults()
            )}
          </div>
        </div>
      </StyledBox>
    </>
  );
};
export default ClaimAttachments;
