const constants = {
  ...process.env,
  ...(window._env || window.__env || window.__ENV),
};

export const {
  REACT_APP_SENTINEL_URL,
  REACT_APP_SENTINEL_CLIENT_ID,
  REACT_APP_SENTINEL_REDIRECT_URL,
  REACT_APP_BACKEND_API,
  REACT_APP_IDLE_POPUP_TIME,
  REACT_APP_REFRESH_TOKEN_RENEW_TIME,
  REACT_APP_STAGE,
  REACT_APP_REACTOR_URL,
  REACT_APP_REACTOR_DAR_ID,
} = constants;
