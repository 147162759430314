import React, { SyntheticEvent } from 'react';
import CoverageInformationComponent from './CoverageInformationComponent';
import HistoricalTransactionsGridsComponent from './HistoricalTransactionsGridsComponent';
import AaaErrorComponent from './AaaErrorComponent';
import { IEligibilityOverviewResponse } from 'api/HistoricalTransactionDetailView/interfaces';
import {  DefaultExpandList} from '../../../api/HistoricalTransactionDetailView/interfaces';
interface IProps {
  eligibilityResponse: IEligibilityOverviewResponse | undefined;
  isFixedHeader:boolean;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (panel: "payer" | "provider" | "subscriber" | "dependents" | "coveragetype" | "coveragedates" | "deductiblemaximum" | "planprovision" | "coverage" | "frequencylimitation" | "agelimitation" | "aaaerror") => (event: React.SyntheticEvent, expanded: boolean) => void;
}

const OverViewTabSection: React.FC<IProps> = ({ eligibilityResponse ,isFixedHeader, defaultExpand,  setDefaultExpand,
  handlePanelChange}) => {
  return (
    <div>
      <AaaErrorComponent handlePanelChange={handlePanelChange} eligibilityResponse={eligibilityResponse} defaultExpand={defaultExpand}
          setDefaultExpand={setDefaultExpand}/>
      <CoverageInformationComponent handlePanelChange={handlePanelChange}
      defaultExpand={defaultExpand}
          setDefaultExpand={setDefaultExpand}
           eligibilityResponse={eligibilityResponse}></CoverageInformationComponent>
      <HistoricalTransactionsGridsComponent defaultExpand={defaultExpand}
          setDefaultExpand={setDefaultExpand} isFixedHeader={isFixedHeader}
        eligibilityResponse={eligibilityResponse}  handlePanelChange={handlePanelChange}
      ></HistoricalTransactionsGridsComponent>
    </div>
  );
};

export default OverViewTabSection;
