export const historicalSearchTableColumns = new Set([
    {
      dataName: 'transactionId',
      text: 'Trx ID',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 35,
      align: 'center',
    },
    {
      dataName: 'patientLastName',
      text: 'Patient Last Name',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 120,
      align: 'center',
    },
    {
      dataName: 'patientFirstName',
      text: 'Patient First Name',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 120,
      align: 'center',
    },
    {
      dataName: 'insuredId',
      text: 'Insured ID',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 60,
      align: 'center',
    },
    {
      dataName: 'payerId',
      text: 'Payer ID',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 48,
      align: 'center',
    },
    {
      dataName: 'payerName',
      text: 'Payer Name',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 70,
      width:120,
      align: 'center',
    },
    {
      dataName: 'errorCode',
      text: 'Error Code',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 70,
      align: 'center',
    },
    {
      dataName: 'categoryCode',
      text: 'Category Code',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 65,
      align: 'center',
    },
    {
      dataName: 'category',
      text: 'Category',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 54,
      align: 'center',
    },
    {
      dataName: 'hippaCode',
      text: 'HIPAA Code',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 68,
      align: 'center',
    },
    {
      dataName: 'timeoftransaction',
      text: 'Time of Transaction',
      sortable: true,
      isSorted: 0,
      width: 40,
      align: 'center',
    },
    {
      dataName: 'vendorId',
      text: 'Vendor ID',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 60,
      align: 'center',
    },
    {
      dataName: 'userName',
      text: 'User Name',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 70,
      align: 'center',
    },
    {
      dataName: 'taxId',
      text: 'Tax ID',
      sortable: true,
      isSorted: 0,
      isHeaderTruncated: true,
      headerWidth: 50,
      align: 'center',
    },
    {
      dataName: 'renderingNpi',
      text: 'NPI',
      sortable: true,
      isSorted: 0,
      headerwidth: 50,
      align: 'center',
    },
    {
      dataName: 'totalseconds',
      text: 'Total Seconds',
      sortable: true,
      isSorted: 0,
      headerwidth: 100,
      align: 'center',
      isHeaderTruncated:false
    
    },
    {
        dataName: 'payerseconds',
        text: 'Payer Seconds',
        sortable: true,
        isSorted: 0,
        headerwidth: 120,
        align: 'center',
      },      
      {
        dataName: 'instance',
        text: 'Instance',
        sortable: true,
        isSorted: 0,
        headerwidth: 50,
        align: 'center',
      },
      {
        dataName: 'payerPayloadID',
        text: 'Payer Payload ID',
        sortable: true,
        isSorted: 0,
        headerwidth: 40,
        align: 'center',
      },{
        dataName: 'payerTimeStamp',
        text: 'Payer Time Stamp',
        sortable: true,
        isSorted: 0,
        headerwidth: 40,
        align: 'center',
      }
  ]);
  