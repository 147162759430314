export const eraSearchTableColumns = new Set([
    {
        dataName: 'transactionId',
        text: 'Transaction ID',
        sortable: true,
        isSorted: 0,
    },

    {
        dataName: 'checkEftDate',
        text: 'Check/EFT Date',
        sortable: true,
        isSorted: 0,
    },

   
    {
        dataName: 'payerId',
        text: 'Payer ID',
        dataTestId: "payerId",
        sortable: true,
        isSorted: 0,
    },

    
    {
        dataName: 'payeeName',
        text: 'Payee Name',
        sortable: true,
        isSorted: 0,
    },
     
    {
        dataName: 'charged',
        text: 'Charged',
        sortable: true,
        isSorted: 0,
    },
    {
        dataName: 'paid',
        text: 'Paid',
        sortable: true,
        isSorted: 0,
    },
    {
        dataName: 'checkEftNumber',
        text: 'CHK/EFT No',
        sortable: true,
        isSorted: 0,
    },
    {
        dataName: 'is835Data',
        text: 'View 835',
        sortable: false,
        isSorted: 0,
        cellType: 'button',
    },

]);
