import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  IClaimProvider,
  ISubscriberInfo,
  IClaimAddress,
  IPersonName,
  IUnsubmittedClaimDetails,
  IRecordOfServices,
  IUnsubmittedClaimPatientInfo,
} from '../../api/unsubmittedClaim/interface';
import { ComponentFlags, DateFlags } from '../../utils/enums';
import { DateFormat } from '../../utils/constants';
import { DateWrapper } from '../../utils/DateWrapper';
import { IInitialClaimDetailState } from './interface';

const initialDefaultState = {
  detail: {} as IUnsubmittedClaimDetails,
};

const initialState = { ...initialDefaultState } as IInitialClaimDetailState | undefined;

interface IPayload {
  key: string;
  value: string;
  index?: number;
  updateType?: ComponentFlags;
}

export const claimDetailSlice = createSlice({
  name: 'claimDetailSlice',
  initialState,
  reducers: {
    updateSubscriberInfo: (state, action: PayloadAction<IPayload>) => {
      const key = action.payload.key;
      const updateType = action.payload.updateType;

      const dob = new DateWrapper(state!.detail!.subscriberInfo!.dateOfBirth, DateFormat.MMDDYYYYNoDelimiters);

      switch (updateType) {
        case ComponentFlags.SubscriberNameInfo:
          state!.detail!.subscriberInfo!.name![key as keyof IPersonName] = action.payload.value;
          break;
        case ComponentFlags.SubscriberInfoAddress:
          state!.detail!.subscriberInfo!.address![key as keyof IClaimAddress] = action.payload.value;
          break;
        case ComponentFlags.SubscriberInfo:
          state!.detail!.subscriberInfo![key] = action.payload.value;
          break;
        case ComponentFlags.SubscriberInfoDOB:
          switch (key) {
            case DateFlags.Date:
              dob.setDay(action.payload.value);
              break;
            case DateFlags.Month:
              dob.setMonth(action.payload.value);
              break;
            case DateFlags.Year:
              dob.setYear(parseInt(action.payload.value));
              break;
          }

          state!.detail!.subscriberInfo!.dateOfBirth = dob.formatDateString(DateFormat.MMDDYYYYNoDelimiters);
          break;
        default:
          console.warn('Unhandled updateType in updateSubscriberInfoReducer', updateType);
      }
    },
    setSubscriberInfo: (state, action: PayloadAction<ISubscriberInfo>) => {
      state!.detail.subscriberInfo = action.payload;
    },
    setBillingProvider: (state, action: PayloadAction<IClaimProvider>) => {
      state!.detail.billingProvider = action.payload;
    },
    setUpdateBillingProviderForm: (state, action: PayloadAction<IPayload>) => {
      const key = action.payload.key;
      const updateType = action.payload.updateType;

      state!.detail!.billingProvider![key] = action.payload.value;
    },
    setRenderingProvider: (state, action: PayloadAction<IClaimProvider>) => {
      state!.detail!.renderingProvider = action.payload;
    },
    setUpdateRenderingProviderForm: (state, action: PayloadAction<IPayload>) => {
      const key = action.payload.key;
      const updateType = action.payload.updateType;

      state!.detail!.renderingProvider![key] = action.payload.value;
    },
    setRecordOfServices: (state, action: PayloadAction<IRecordOfServices>) => {
      state!.detail!.recordOfServices = action.payload;
    },

    setUpdateRecordOfServicesForm: (state, action: PayloadAction<IPayload>) => {
      const key = action.payload.key;
      const updateType = action.payload.updateType;
      const index = action.payload.index as number;
      const record = state!.detail!.recordOfServices!.serviceRecords!.at(index);
      record![key] = action.payload.value;
    },

    setPatientInfo: (state, action: PayloadAction<IUnsubmittedClaimPatientInfo>) => {
      state!.detail!.patientInfo = action.payload;
    },
    updatePatientInfo: (state, action: PayloadAction<IPayload>) => {
      const key = action.payload.key;
      const updateType = action.payload.updateType;

      state!.detail!.patientInfo![key] = action.payload.value;
    },
  },
});

export const {
  updateSubscriberInfo,
  setSubscriberInfo,
  setBillingProvider,
  setUpdateBillingProviderForm,
  setRenderingProvider,
  setUpdateRenderingProviderForm,
  setRecordOfServices,
  setUpdateRecordOfServicesForm,
  setPatientInfo,
  updatePatientInfo,
} = claimDetailSlice.actions;

export default claimDetailSlice.reducer;
