import { IUserAccountInfo } from 'api/user/interface';
import { TokenData } from '../../model/UserDetail';

export enum UserActionTypes {
  CREATECUSTOMERROUTE = 'CreateCustomerRoute',
  LISTCUSTOMERROUTES = 'ListCustomerRoutes',
  UPDATECUSTOMERROUTE = 'UpdateCustomerRoute',
  INTAKECLAIMS = 'IntakeClaim',
  PING = 'Ping',
  CREATEUSER = 'CreateUser',
  LISTACCOUNTUSERS = 'ListAccountUsers',
  GETCURRENTUSERSUMMARY = 'GetCurrentUserSummary',
  UPDATEUSER = 'UpdateUser',
  LISTACCOUNTLEGALENTITIES = 'ListAccountLegalEntities',
  LISTACCOUNTS = 'ListAccounts',
  UPDATELEGALENTITY = 'UpdateLegalEntity',
  CREATEACCOUNT = 'CreateAccount',
  UPDATEACCOUNT = 'UpdateAccount',
  SEARCHPROCESSEDCLAIMS = 'SearchProcessedClaims',
  GETPROCESSEDCLAIMDETAIL = 'GetProcessedClaimDetail',
  CREATELEGALENTITY = 'CreateLegalEntity',
  GETACCOUNTDETAIL = 'GetAccountDetail',
  MANAGEMULTIPLEACCOUNTS = 'ManageMultipleAccounts',
  ONBOARDACCOUNT = 'OnboardAccount',
}

export interface LoggedInUser {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[] | string;
  company: object;
  isAdmin: boolean;
}

export interface IUserPayload extends LoggedInUser {
  given_name: string;
  family_name: string;
}

export interface IInitialStateUserSlice {
  user: LoggedInUser | undefined;
  ciamUserInfo: object | undefined;
  userTokenData: TokenData | undefined;
  account: IUserAccountInfo | undefined;
}
