import React from 'react';
import { FlexDiv } from '../../../utils/constants';
import { TextWithLabel } from '../../../utils/TextWithLabel';
import PatientDetails from './Components/PatientDetails';
import ProviderDetails from './Components/ProviderDetails';
import AttachmentFileDetails from './Components/AttachmentFileDetails';
import {
  IAttachmentDetailResponse,
  IAttachmentFileResponse,
  IClaimAttachmentsSearchResults,
} from '../../../api/claimAttachments/interface';

interface IProps {
  attachmentDetails: IAttachmentDetailResponse;
  attachmentDetailImages: IAttachmentFileResponse[];
  attachmentSearchResult: IClaimAttachmentsSearchResults;
  isLoadingImages: boolean;
  isImageError: boolean;
  closeDrawer: () => void;
}
const AttachmentDetailContainer: React.FC<IProps> = ({
  attachmentDetails,
  attachmentDetailImages,
  attachmentSearchResult,
  isLoadingImages,
  isImageError,
}) => {
  const AttachmentDetailsHeader = () => (
    <FlexDiv>
      <TextWithLabel text={attachmentDetails?.payerId} label={'Payer ID'} labelVariant={'xxSmall'} />
      <TextWithLabel text={attachmentDetails?.attachmentId} label={'Attachment ID'} labelVariant={'xxSmall'} />
      <TextWithLabel
        text={attachmentDetails?.payerReferenceNumber}
        label={'Payer Reference Number'}
        labelVariant={'xxSmall'}
      />
      <TextWithLabel text={attachmentDetails?.claimId} label={'Optum Claim ID'} labelVariant={'xxSmall'} />
      <TextWithLabel text={attachmentSearchResult?.dateOfService} label={'Service Date'} labelVariant={'xxSmall'} />
      <TextWithLabel
        text={attachmentSearchResult?.requestDate}
        label={'Optum Processing Date'}
        labelVariant={'xxSmall'}
      />
      <TextWithLabel text={'$' + attachmentDetails?.amount} label={'Charge Amount'} labelVariant={'xxSmall'} />
    </FlexDiv>
  );

  const renderView = () => {
    return (
      <>
        {AttachmentDetailsHeader()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            marginLeft: '-35px',
            paddingTop: '20px',
          }}
        >
          <PatientDetails patientDetails={attachmentDetails?.patientDetails} />
          <ProviderDetails
            providerDetails={attachmentDetails?.providerDetails}
            attachmentSearchResult={attachmentSearchResult}
          />
          <AttachmentFileDetails
            isImageError={isImageError}
            isLoadingImages={isLoadingImages}
            attachmentImages={attachmentDetailImages}
          />
        </div>
      </>
    );
  };

  return <>{renderView()}</>;
};

export default AttachmentDetailContainer;
