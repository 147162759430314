import { apiInstance } from '../apiInstance';
import { IListUserPermissionResponse, IUserAccountInfo } from './interface';

export const getUserAccountInfo = (dispatch?: any): Promise<IUserAccountInfo> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch, { notificationFilter: (apiResult) => apiResult.status?.toString() === '403' })
      .get('/user/me')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserPermissions = (dispatch?: any): Promise<IListUserPermissionResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch, { notificationFilter: (apiResult) => apiResult.status?.toString() === '403' })
      .get('/user/me/permission')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
