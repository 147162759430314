import React, { ChangeEvent } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  ModalFooter,
  ButtonGroup,
  Button,
  Input,
  SelectDropdown,
} from '@uicl/ui-core/dist';
import { IClaimFilterRequestBody } from '../../../api/claimSearch/interface';
import { EClaimStatus } from './ClaimStatus';

interface InitialOptions {
  label: string;
  value: string;
  dataTestId: string;
  id: string;
}

interface IProps {
  isOpen: boolean;
  onCloseSearchModal: () => void;
  onHandleReset: () => void;
  onHandleApplySearch: () => void;
  claimFilterRequestBody: IClaimFilterRequestBody;
  onHandleClaimFilterOnChange: (e?: ChangeEvent<HTMLInputElement>, key?: string, value?: string) => void;
  initialClaimStatusOption: InitialOptions | null;
  claimFilterErrors: Record<string, string>;
}

export const claimStatus = [
  {
    dataTestId: 'select-claim-status',
    id: 'select-claim-status',
    label: 'Select Claim Status',
    value: EClaimStatus.All,
  },
  {
    dataTestId: 'accepted-by-clearinghouse',
    id: 'accepted-by-clearinghouse',
    label: 'Accepted by Clearinghouse',
    value: EClaimStatus.AcceptedByClearinghouse,
  },
  {
    dataTestId: 'rejected-by-clearinghouse',
    id: 'rejected-by-clearinghouse',
    label: 'Rejected by Clearinghouse',
    value: EClaimStatus.RejectedByClearinghouse,
  },
  {
    dataTestId: 'sent-to-payer',
    id: 'sent-to-payer',
    label: 'Sent to Payer',
    value: EClaimStatus.SentToPayer,
  },

  {
    dataTestId: 'accepted-by-payer',
    id: 'accepted-by-payer',
    label: 'Accepted by Payer',
    value: EClaimStatus.AcceptedByPayer,
  },
  {
    dataTestId: 'rejected-by-payer',
    id: 'rejected-by-payer',
    label: 'Rejected by Payer',
    value: EClaimStatus.RejectedByPayer,
  },
];

const ClaimFilterModal: React.FC<IProps> = ({
  isOpen,
  onCloseSearchModal,
  onHandleReset,
  onHandleApplySearch,
  claimFilterRequestBody,
  onHandleClaimFilterOnChange,
  initialClaimStatusOption,
  claimFilterErrors,
}) => {
  return (
    <Modal isOpen={isOpen} domID="claim-modal-filter" size="small" onClickOut={onCloseSearchModal}>
      <ModalHeader title="Search Filter" description="Additional search filters" onClose={onCloseSearchModal} />
      {/*@ts-ignore*/}
      <ModalBody>
        <Container>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="payerId"
                    domID="payerId"
                    placeholder="Payer ID "
                    label="Payer ID  "
                    size="medium"
                    name="payerId"
                    maxLength={5}
                    value={claimFilterRequestBody.payerId}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="cpsCarrierId"
                    domID="cpsCarrierId"
                    placeholder="CPS Carrier ID"
                    label="CPS Carrier ID"
                    size="medium"
                    name="cpsCarrierId"
                    maxLength={5}
                    value={claimFilterRequestBody.cpsCarrierId}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="claimId"
                    domID="claimId"
                    placeholder="Claim ID"
                    label="Claim ID"
                    size="medium"
                    name="claimId"
                    maxLength={10}
                    value={claimFilterRequestBody.claimId}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="db2Key"
                    domID="db2Key"
                    placeholder="DB2 Key"
                    label="DB2 Key"
                    size="medium"
                    name="db2Key"
                    maxLength={10}
                    value={claimFilterRequestBody.db2Key}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="submitterId"
                    domID="submitterId"
                    placeholder="Submitter ID"
                    label="Submitter ID"
                    size="medium"
                    name="submitterId"
                    maxLength={9}
                    value={claimFilterRequestBody.submitterId}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
                <div style={{ marginRight: 5, marginBottom: 10, width: 180 }}>
                  <SelectDropdown
                    ariaLabel="ARIA label"
                    dataTestId="claim-status"
                    domID="claim-status"
                    size="medium"
                    label="Claim Status"
                    initialValue={initialClaimStatusOption!}
                    name="claimStatus"
                    onChange={(opt) => {
                      const value = opt ? opt.value : null;
                      onHandleClaimFilterOnChange(undefined, 'claimStatus', value as string);
                    }}
                    menuWidth={400}
                    options={claimStatus}
                    isClearable={false}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="patientInsuredId"
                    domID="patientInsuredId"
                    placeholder="Patient Insured ID"
                    label="Patient Insured ID"
                    size="medium"
                    name="patientInsuredId"
                    maxLength={50}
                    value={claimFilterRequestBody.patientInsuredId}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="controlNumber"
                    domID="controlNumber"
                    placeholder="Control Number"
                    label="Control Number"
                    size="medium"
                    name="controlNumber"
                    maxLength={50}
                    value={claimFilterRequestBody.controlNumber}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="patientLastName"
                    domID="patientLastName"
                    placeholder="Patient Last Name"
                    label="Patient Last Name"
                    size="medium"
                    name="patientLastName"
                    maxLength={60}
                    value={claimFilterRequestBody.patientLastName}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="patientFirstName"
                    domID="patientFirstName"
                    placeholder="Patient First Name"
                    label="Patient First Name"
                    size="medium"
                    name="patientFirstName"
                    maxLength={50}
                    value={claimFilterRequestBody.patientFirstName}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="billingProviderNpi"
                    domID="billingProviderNpi"
                    placeholder="Billing Provider NPI"
                    label="Billing Provider NPI"
                    size="medium"
                    name="billingProviderNpi"
                    maxLength={10}
                    value={claimFilterRequestBody.billingProviderNpi}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
                <div style={{ marginRight: 5, marginBottom: 10 }}>
                  <Input
                    aria-label="ARIA Label"
                    autoComplete="off"
                    dataTestId="renderingProviderNpi"
                    domID="renderingProviderNpi"
                    placeholder="Rendering Provider NPI"
                    label="Rendering Provider NPI"
                    size="medium"
                    name="renderingProviderNpi"
                    maxLength={10}
                    value={claimFilterRequestBody.renderingProviderNpi}
                    onChange={(e) => onHandleClaimFilterOnChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </ModalBody>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {/*@ts-ignore*/}
        <ModalFooter>
          {/*@ts-ignore*/}
          <ButtonGroup>
            {/*@ts-ignore*/}
            <ButtonGroup>
              <Button
                buttonType="diminished"
                dataTestId="claim-filter-button-cancel"
                name="Cancel"
                size="medium"
                onClick={onCloseSearchModal}
                domID="claim-filter-button-cancel"
              />
              <Button
                dataTestId="claim-filter-button-reset"
                name="Reset"
                buttonType="standard"
                size="small"
                type="reset"
                onClick={onHandleReset}
                domID="claim-filter-button-reset"
              />
              <Button
                buttonType="emphasized"
                name="Apply"
                domID="claim-filter-button-apply"
                dataTestId="claim-filter-button-apply"
                size="large"
                onClick={onHandleApplySearch}
              />
            </ButtonGroup>
          </ButtonGroup>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default ClaimFilterModal;
