import { ESortingDirectionEraSearch, IEraSearchResponse } from './interfaces';
import moment from 'moment';

export const initialEraSearchBody = {
  limit: 0,
  offset: 0,
  transactionId: '',
  taxId: '',
  payerId: '',
  insuredId: '',
  checkEftNumber: '',
  checkEftAmount: null,
  billingNpi: '',
  sorting: {
    direction: ESortingDirectionEraSearch.DESCENDING,
    fields: ['transactionId'],
  },
  dateRangeFilter: {
    field: 'checkEftDate',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
};

export const viewMock835: string =
  'ISA*00*          ' +
  '*00*          *ZZ*061267267' +
  '      *ZZ*337' +
  '            *121015*1152' +
  '*^*00501*000000001*0*T*:~' +
  'GS*HP*337*061267267*20121015*1152*1*X*005010X221A1~' +
  'ST*835*112343~' +
  'BPR*I*386.9*C*CHK************20080428~' +
  'TRN*1*12345*1512345678*199999999~' +
  'REF*EV*133052274~' +
  'DTM*405*20080428~' +
  'N1*PR*Payer~' +
  'N3*1 Test Street~' +
  'N4*Anytown*PA*352039998~' +
  'REF*2U*12345~' +
  'PER*BL*Marytech Smithcontact*TE*2006649555*TE*1005545675~' +
  'N1*PE*Provider Name DMD*XX*1000000004~' +
  'N3*2 Test Road~' +
  'N4*Testown*AL*011239998~' +
  'REF*PQ*334057300~' +
  'REF*TJ*987654321~' +
  'LX*1~' +
  'TS3*1265533608*99*20081231*3*424~' +
  'CLP*777777*1*107*85.6*21.4*12*729932404007801*11*1~' +
  'NM1*QC*1*TEST*PATIENT*J***MI*SJD11111~' +
  'NM1*82*1******XX*1000000004~' +
  'REF*F8*R123~' +
  'REF*1L*739456~' +
  'REF*CE*739456~' +
  'DTM*050*20240423~' +
  'DTM*232*20240421~' +
  'DTM*233*20240421~' +
  'PER*CX*Janetech Doecontact2*TE*2006649555~' +
  'SVC*AD:D0230*20*16**1~' +
  'DTM*472*20080421~' +
  'CAS*PR*2*4~' +
  'REF*6R*777777-1~' +
  'AMT*B6*20~' +
  'SVC*AD:D0220*25*20**1~' +
  'DTM*472*20240421~' +
  'CAS*PR*2*5~' +
  'REF*6R*777777-2~' +
  'AMT*B6*25~' +
  'SVC*AD:D0140*62*49.6**1~' +
  'DTM*472*20240421~' +
  'CAS*PR*2*12.4~' +
  'REF*6R*777777-3~' +
  'AMT*B6*62~' +
  'SE*94*112343~' +
  'GE*27*1~' +
  'IEA*1*000000001~';

export const mockEraResponse: IEraSearchResponse = {
  filters: {
    field: 'checkEftDate',
    startDate: '05092024',
    endDate: '05202024',
  },
  resultCount: 100,
  totalCount: 100,
  pagingOptions: {
    offset: 0,
    limit: 20,
    morePages: true,
    totalCount: 0,
    resultCount: 0,
  },
  results: [
    {
      transactionId: 'Trans1043101',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1301',
      payerId: '11',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans133001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1032301',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1d001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1424001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Tran42s1001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans142001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans144001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans4241001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans42431001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1342001',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1013101',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1003231',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans1012301',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans104301',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans101301',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans101101',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans10301',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
    {
      transactionId: 'Trans10201',
      payerId: '10',
      payeeName: 'Ritesh',
      checkEftDate: '05/20/2014',
      charged: 100,
      paid: 90,
      checkEftNumber: '10AB',
      is835Data: false,
      view835Data: viewMock835,
    },
  ],
};
