import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@uicl/ui-core/dist';
import { title, DisplayText } from '../../utils/constants';
import './Welcome.scss';

const Welcome = () => {
  const getMoreInfo = 'https://www.changehealthcare.com/solutions/dental-network/dental-connect';

  useEffect(() => {
    document.title = title.Title;
  }, []);

  return (
    <>
      <div className="welcome-container" data-testid="welcome-container-testid">
        <div className="row mx-0 welcomeVh">
          <div className="col-lg-12 welcomeBg d-flex justify-content-center align-items-center pt-3">
            <div>
              <div>
                <Text className="secondaryGreenColor semiboldFont  mb-2 h1Font" variant="xLarge">
                  {DisplayText.DentalConverge}
                </Text>
              </div>
              <div>
                <ul className="whiteColor welcomeList">
                  <li>
                    <Text variant="medium" className="color-white">
                      Eliminate paper and create a seamless virtual office environment
                    </Text>
                  </li>
                  <li>
                    <Text variant="medium" className="color-white">
                      Improve cash flow and streamline productivity with ACH payments
                    </Text>
                  </li>
                  <li>
                    <Text variant="medium" className="color-white">
                      Access your Explanation of Payment (EOP) data via our secure online multi-payer portal
                    </Text>
                  </li>
                  <li>
                    <Text variant="medium" className="color-white">
                      Manage your claim attachments for all payers in one place
                    </Text>
                  </li>
                  <li>
                    <Text variant="medium" className="color-white">
                      Enable patients to pay medical bills electronically
                    </Text>
                  </li>
                </ul>
              </div>
              <div className="py-4">
                <div className="d-flex">
                  <a href={getMoreInfo} target="_blank" className="getInfoButton">
                    Get More Info
                  </a>
                  <Link to="/" className="howItWorks mx-3">
                    <span>See how it works</span>
                    <span className="px-3">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM11.8622 5.85355C12.0459 5.65829 12.0459 5.34171 11.8622 5.14645C11.6784 4.95118 11.3804 4.95118 11.1967 5.14645L6.82353 10.7929L4.80334 8.64645C4.61957 8.45118 4.32161 8.45118 4.13783 8.64645C3.95406 8.84171 3.95406 9.15829 4.13783 9.35355L6.49077 11.8536C6.67455 12.0488 6.97251 12.0488 7.15629 11.8536L11.8622 5.85355Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
