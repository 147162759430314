import {
  DrawerBox,
  DrawerContatiner,
  DrawerContent,
} from '../../Routes/Claims/ProcessedSearch/ClaimDrawer/styles';
import React from 'react';

type DrawerProps = {
  percentageView: boolean;
  children: React.ReactNode;
  containerWidth?: string;
};

const Drawer: React.FC<DrawerProps> = ({
  children,
  percentageView,
  containerWidth,
}) => {
  return (
    <DrawerBox data-testid="drawerContainer" id="drawerContainer" className='drawerContainer'>
      <DrawerContatiner>
        <DrawerContent
          percentageView={percentageView}
          containerWidth={containerWidth}
        >
          {children}
        </DrawerContent>
      </DrawerContatiner>
    </DrawerBox>
  );
};

export default Drawer;
