//TODO https://jira.healthcareit.net/browse/DCP2-7022
import React, { useCallback, useEffect, useState } from 'react';
import LoadingPage from './LoadingSpinner';
import { useAppDispatch } from '../reduxStore/reduxHooks';
import { setAccountDetails, setUser } from '../reduxStore/userSlice';
import { getUserAccountInfo, getUserPermissions } from '../api/user';
import { mapUserSummaryPayload, setUserSummary } from '../reduxStore/userSummarySlice';
import { IUserPayload } from '../reduxStore/userSlice/interfaces';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { RouteNames } from '../Routes/Navigation';
import { setUserPermissions } from '../reduxStore/userPermissionsSlice';
import { IApiError } from '../api/errorHandling/interfaces';

interface IProps {
  children: React.ReactNode;
}

const AuthContainer: React.FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth();

  const [navRouteName, setNavRouteName] = useState(RouteNames.Home);

  const [isUserSummaryLoader, setUserSummaryLoader] = useState(false);
  const [isUserPermissionLoader, setUserPermissionLoader] = useState(false);
  const navigate = useNavigate();

  const handleApiError = (err: any) => {
    const apiError = err as IApiError;

    if (apiError?.status?.toString() !== '403') setNavRouteName(RouteNames.Error); //they can still go to the Home page when 403, since they may not yet be onboarded.
  };

  const handleFetch = useCallback(async () => {
    setUserSummaryLoader(true);
    setUserPermissionLoader(true);

    getUserAccountInfo(dispatch)
      .then((userAccountInfo) => {
        dispatch(setAccountDetails(userAccountInfo));
        dispatch(setUserSummary(mapUserSummaryPayload(userAccountInfo)));
        dispatch(
          setUser({
            email: userAccountInfo.username,
            given_name: userAccountInfo.firstName,
            family_name: userAccountInfo.lastName,
            isAdmin: false,
          } as IUserPayload)
        );
      }, handleApiError)
      .finally(() => setUserSummaryLoader(false));

    getUserPermissions(dispatch)
      .then((permissionResponse) => dispatch(setUserPermissions(permissionResponse.permissions)), handleApiError)
      .finally(() => setUserPermissionLoader(false));
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      handleFetch();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    navigate(navRouteName);
  }, [navRouteName]);

  return isUserSummaryLoader && isUserPermissionLoader ? <LoadingPage /> : <div>{children} </div>;
};

export default AuthContainer;
