export type TAttachmentType = EAttachmentType;
export type TOrientationType = EOrientationType;
export type TSupportedFileType = ESupportedFileType;

export enum EAttachmentType {
  EOB_COB = 1,
  NARRATIVE = 2,
  STUDENT_VERIFICATION = 3,
  REFERRAL_FORM = 4,
  DIAGNOSIS = 5,
  REPORTS = 6,
  PERIODONTAL_CHARTS = 7,
  PROGRESS_NOTES = 8,
  INTRAORAL_IMAGE = 9,
  FULL_MOUTH_SERIES = 10,
  BITEWINGS = 11,
  PERIAPICAL = 12,
  PANORAMIC_FILM = 13,
  PARTIAL_MOUNT = 14,
  CEPHALOMETRIC = 15,
  RADIOGRAPHIC_IMAGES = 16,
}

export enum EOrientationType {
  NOT_APPLICABLE = 0,
  LEFT = 1,
  RIGHT = 2,
}

export const OrientationTypes = {
  [EOrientationType.NOT_APPLICABLE]: 'Not Applicable',
  [EOrientationType.LEFT]: 'Left',
  [EOrientationType.RIGHT]: 'Right',
};

export const AttachmentTypes = {
  [EAttachmentType.EOB_COB]: 'EOB/COB',
  [EAttachmentType.NARRATIVE]: 'Narrative',
  [EAttachmentType.STUDENT_VERIFICATION]: 'Student Verification',
  [EAttachmentType.REFERRAL_FORM]: 'Referral Form',
  [EAttachmentType.DIAGNOSIS]: 'Diagnosis',
  [EAttachmentType.REPORTS]: 'Reports',
  [EAttachmentType.PERIODONTAL_CHARTS]: 'Periodontal Charts',
  [EAttachmentType.PROGRESS_NOTES]: 'Progress Notes',
  [EAttachmentType.INTRAORAL_IMAGE]: 'Intraoral Image',
  [EAttachmentType.FULL_MOUTH_SERIES]: 'Full Mouth Series',
  [EAttachmentType.BITEWINGS]: 'Bitewings',
  [EAttachmentType.PERIAPICAL]: 'Periapical',
  [EAttachmentType.PANORAMIC_FILM]: 'Panoramic Film',
  [EAttachmentType.PARTIAL_MOUNT]: 'Partial Mount',
  [EAttachmentType.CEPHALOMETRIC]: 'Cephalometric',
  [EAttachmentType.RADIOGRAPHIC_IMAGES]: 'Radiographic Images',
};

export enum ESupportedFileType {
  BMP = 'BMP',
  JPG = 'JPG/JPEG',
  TIFF = 'TIFF',
  PNG = 'PNG',
  PDF = 'PDF',
  TXT = 'TXT',
  DOC_DOCX = 'DOC/DOCX',
  DICOM = 'DICOM',
  GIF = 'GIF',
  PER = 'PER',
  RFT = 'RFT',
}
