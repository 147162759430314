import { IApiError } from './interfaces';
import { IAddNotificationPayload, IconType } from '../../reduxStore/notificationSlice/interfaces';
import { addNotification } from '../../reduxStore/notificationSlice';

const mapAxiosErrorToApiError = (errorResponse?: any): IApiError => {
  let title = errorResponse?.code ?? defaultErrorTitle;
  let description = errorResponse?.message ?? defaultErrorDescription;
  if (errorResponse?.code === 'ERR_NETWORK') {
    title = 'Network Error';
    description =
      'There was a problem accessing the network. Please check that there is an active internet connection. If the problem persists contact your system administrator.';
  }
  return {
    title: title,
    detail: description,
  };
};

const mapAttachmentErrorToApiError = (errorResponse?: any): IApiError => {
  const msg = errorResponse?.data?.Message ?? defaultErrorDescription;
  const defaultTitle = 'Issue Managing Attachments';
  const status = errorResponse?.status;
  const errors = errorResponse?.data?.errors;
  let newErrors: Record<string, string> = {};
  if (errors && Array.isArray(errors) && errors.length > 0) {
    newErrors = {};

    for (let i = 0; i < errors.length; i++) {
      const obj = errors[i];
      let code = i.toString();
      let message = defaultErrorDescription;
      if (Object.hasOwn(obj, 'code')) {
        code = obj.code;
      }

      if (Object.hasOwn(obj, 'message')) {
        message = obj.message;
      }

      newErrors[code] = message;
    }
    return {
      title: defaultTitle,
      detail: msg,
      status: status,
      errors: newErrors,
    };
  }

  return {
    title: defaultTitle,
    detail: msg,
    status: status,
  };
};

const getDefaultApiError = (): IApiError => {
  return {
    title: defaultErrorTitle,
    detail: defaultErrorDescription,
  };
};

const mapPortalErrorToApiError = (errorResponse?: any): IApiError => {
  return {
    traceId: errorResponse?.data?.traceId,
    detail: errorResponse?.data?.detail ?? defaultErrorDescription,
    errors: errorResponse?.data?.errors,
    title: errorResponse?.data?.title ?? defaultErrorTitle,
    status: errorResponse?.data?.status ?? errorResponse?.status,
  };
};

const mapApigeeErrorToApiError = (errorResponse: any): IApiError => {
  return {
    detail: errorResponse?.data?.error_description ?? defaultErrorDescription,
    title: errorResponse?.data?.error ?? defaultErrorTitle,
    traceId: errorResponse?.data?.traceId,
    status: errorResponse?.status,
  };
};

const mapApiErrorToAddNotification = (error: IApiError): IAddNotificationPayload => {
  const errorMap = Object.entries(error?.errors ?? []).map(([key, value]) => `${key}: ${value}`);
  const additionalInfo: string[] = [...errorMap];

  if (error?.traceId) additionalInfo.push(`Trace Id: ${error?.traceId}`);

  if (error?.status) additionalInfo.push(`Status Code: ${error?.status.toString()}`);

  return {
    title: error?.title ?? defaultErrorTitle,
    description: error?.detail ?? defaultErrorDescription,
    icon: IconType.Warning,
    additionalInfo: additionalInfo.length > 0 ? additionalInfo : undefined,
  };
};

export const defaultErrorDescription =
  'An unexpected error prevents the completion of this request. Please try again. If the problem persists, please contact customer support.';
export const defaultErrorTitle = 'Unexpected Issue';

export const raiseErrorNotification = (dispatch: any, apiError: IApiError) => {
  const payload: IAddNotificationPayload = mapApiErrorToAddNotification(apiError);

  dispatch(addNotification(payload));
};

export const mapResponseDataToApiError = (errorResponse?: any): IApiError => {
  const data = errorResponse?.response?.data;

  if (data) {
    if (Object.hasOwn(data, 'error_description')) {
      return mapApigeeErrorToApiError(errorResponse?.response);
    }

    if (Object.hasOwn(data, 'title') && Object.hasOwn(data, 'detail')) {
      return mapPortalErrorToApiError(errorResponse?.response);
    }

    if (Object.hasOwn(data, 'Message') || (Object.hasOwn(data, 'errors') && Array.isArray(data?.errors))) {
      return mapAttachmentErrorToApiError(errorResponse?.response);
    }
  }

  if (errorResponse?.code) {
    return mapAxiosErrorToApiError(errorResponse);
  }

  return getDefaultApiError();
};
