import styled from 'styled-components';

export const DrawerBox = styled.div`
  height: 100%;
  margin-top: 20px;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
`;

export const DrawerContatiner = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DrawerContent = styled.div<{
  percentageView: boolean;
  containerWidth?: string;
}>`
  width: 40vw;
  background-color: #fff;
  padding: 30px;
  background-color: #fff;
  margin-right: 25%;
  margin-top: 6%;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.5;
  color: #000000;
  height: 420px;
  overflow-y: auto;
  white-space: pre;
  border-radius:25px;
`;

export const DrawerDisplayContent = styled.div`
  display: flex;
  justify-content: flex-center;
  padding-top: 22px;
`;

export const DrawerContatinerSaveClose = styled.div`
  display: flex;
  justify-content: flex-end;
`;