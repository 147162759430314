import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  height: 100vh;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ResourcePermissionDeniedComponent = () => {
  return (
    <StyledDiv data-testid="resource-permission-denied">
      You are not authorized to view this page. Please contact your internal Dental Converge administrator for
      permission.
    </StyledDiv>
  );
};
