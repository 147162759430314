export enum ESortingDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export type TSortingField =
  | 'dateOfService'
  | 'processedOn'
  | 'amount'
  | 'reason'
  | 'payer'
  | 'status';
