import React from 'react';
import { IApiError } from '../../api/errorHandling/interfaces';

interface IProps {
  errorResponse: IApiError | undefined | null;
  style?: object;
}

const ErrorInfo: React.FC<IProps> = ({ errorResponse, style }) => {
  const entries = Object.entries(errorResponse?.errors ?? {});

  return (
    <div>
      <ul style={style}>
        {entries.map((errorCodeThenErrorMessage) => {
          return <li>{errorCodeThenErrorMessage[1]}</li>;
        })}
      </ul>
      <div style={{ ...style, fontSize: 'small' }}>Trace ID: {errorResponse?.traceId}</div>
    </div>
  );
};

export default ErrorInfo;
