import React from 'react';
import { InsuredStyledDiv, EraGridDiv, EraGridCenterDiv, EraGridEndDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IEraSearchDetailResponse } from 'api/EraSearchDetailView/interface';

interface IProps {
  eraSearchDetailData: IEraSearchDetailResponse;
}

const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) return match[1] + '-' + match[2] + '-' + match[3];
  return phoneNumberString;
};

const PayerIdentificationComponent: React.FC<IProps> = ({ eraSearchDetailData }) => {
  const chcTransactionView = () => {
    return (
      <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="payeridentificationContainer">
        <InsuredStyledDiv>
          <EraGridDiv>
            <Text color="secondary" variant="small">
              Transaction ID
            </Text>
            <Text variant="smallBold">{eraSearchDetailData.transactionId}</Text>
          </EraGridDiv>
          <EraGridCenterDiv></EraGridCenterDiv>
          <EraGridEndDiv></EraGridEndDiv>
        </InsuredStyledDiv>
      </div>
    );
  };

  const payerIdentificationView = () => {
    return (
      <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="payeridentificationContainer">
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Text variant="largeBold">Payer Identification</Text>
        </div>
        <InsuredStyledDiv>
          <EraGridDiv>
            <Text color="secondary" variant="small">
              Payer
            </Text>

            <Text variant="smallBold">{eraSearchDetailData.payerIdentification.payer}</Text>
          </EraGridDiv>
          <EraGridDiv>
            <EraGridCenterDiv></EraGridCenterDiv>
          </EraGridDiv>
          <EraGridDiv>
            <EraGridEndDiv>
              <Text color="secondary" variant="small">
                Tax ID
              </Text>

              <Text variant="smallBold">{eraSearchDetailData.payerIdentification.taxId}</Text>
            </EraGridEndDiv>
          </EraGridDiv>
        </InsuredStyledDiv>

        <InsuredStyledDiv>
          <EraGridDiv>
            <Text color="secondary" variant="small">
              Payer ID
            </Text>

            <Text variant="smallBold">{eraSearchDetailData.payerIdentification.payerId}</Text>
          </EraGridDiv>
          <EraGridDiv>
            <EraGridEndDiv></EraGridEndDiv>
          </EraGridDiv>
        </InsuredStyledDiv>
      </div>
    );
  };

  return (
    <>
      {chcTransactionView()}
      {payerIdentificationView()}
    </>
  );
};

export default PayerIdentificationComponent;
