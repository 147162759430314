import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../../api/claimView/interface';
import moment from 'moment';
import { handleParseAddress } from '../../../../utils/constants';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const InsuredComponent: React.FC<IProps> = ({ claimViewData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="insuredContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="large">Insured</Text>
      </div>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'InsuredComponent-insured-name'}>
          <Text color="secondary" variant="small">
            Insured Name
          </Text>

          <Text>
            {claimViewData.primarySubscriberInfo.name.firstName} {claimViewData.primarySubscriberInfo.name.lastName}
          </Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'InsuredComponent-gender-code'}>
            <Text color="secondary" variant="small">
              Gender
            </Text>

            <Text>{claimViewData.primarySubscriberInfo.genderCode}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv data-testId={'InsuredComponent-group-policy'}>
          <Text color="secondary" variant="small">
            Group Policy #
          </Text>

          <Text>{claimViewData.primarySubscriberInfo.groupPolicyNumber}</Text>
        </ClaimGridDiv>
      </InsuredStyledDiv>
      <InsuredStyledDiv data-testId={'InsuredComponent-address'}>
        <ClaimGridDiv>
          <Text color="secondary" variant="small">
            Address
          </Text>

          <Text>{handleParseAddress(claimViewData.primarySubscriberInfo.address)}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'InsuredComponent-insured-id'}>
            <Text color="secondary" variant="small">
              Insured ID
            </Text>

            <Text>{claimViewData.primarySubscriberInfo.memberId}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv data-testId={'InsuredComponent-dob'}>
          <Text color="secondary" variant="small">
            Insured DOB
          </Text>

          <Text>{moment(claimViewData.primarySubscriberInfo.dateOfBirth).format('M/DD/YYYY')}</Text>
        </ClaimGridDiv>
      </InsuredStyledDiv>
    </div>
  );
};

export default InsuredComponent;
