import { apiInstance } from '../apiInstance';
import { IOnboardAccountRequestBody, IOnboardAccountResponse } from './interface';

export const onboardAccount = (
  requestBody: IOnboardAccountRequestBody,
  dispatch?: any
): Promise<IOnboardAccountResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch, { notificationFilter: (apiResult) => apiResult?.status !== 400 })
      .put('/onboarding', requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
