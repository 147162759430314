import React from 'react';
import { InsuredStyledDiv, ClaimGridDiv, ClaimGridCenterDiv } from './styles';
import { Text } from '@uicl/ui-core/dist';
import { IClaimViewResponse } from '../../../../api/claimView/interface';
import moment from 'moment';
import { handleParseAddress } from '../../../../utils/constants';

interface IProps {
  claimViewData: IClaimViewResponse;
}

const InsuredComponent: React.FC<IProps> = ({ claimViewData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="patientContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="large">Patient</Text>
      </div>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'PatientComponent-patient-name'}>
          <Text color="secondary" variant="small">
            Patient Name
          </Text>

          <Text>
            {claimViewData.patientInfo.name.firstName} {claimViewData.patientInfo.name.lastName}
          </Text>
        </ClaimGridDiv>
        <ClaimGridDiv>
          <ClaimGridCenterDiv data-testId={'PatientComponent-patient-relationship'}>
            <Text color="secondary" variant="small">
              Relationship
            </Text>

            <Text>{claimViewData.patientInfo.relationship}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv data-testId={'PatientComponent-patient-gender'}>
          <Text color="secondary" variant="small">
            Gender
          </Text>

          <Text>{claimViewData.patientInfo.genderCode}</Text>
        </ClaimGridDiv>
      </InsuredStyledDiv>
      <InsuredStyledDiv>
        <ClaimGridDiv data-testId={'PatientComponent-patient-address'}>
          <Text color="secondary" variant="small">
            Address
          </Text>

          <Text>{handleParseAddress(claimViewData.patientInfo.address)}</Text>
        </ClaimGridDiv>
        <ClaimGridDiv data-testId={'PatientComponent-patient-dob'}>
          <ClaimGridCenterDiv>
            <Text color="secondary" variant="small">
              Patient DOB
            </Text>

            <Text>{moment(claimViewData.patientInfo.dateOfBirth).format('M/DD/YYYY')}</Text>
          </ClaimGridCenterDiv>
        </ClaimGridDiv>
        <ClaimGridDiv />
      </InsuredStyledDiv>
    </div>
  );
};

export default InsuredComponent;
