import React from 'react';
import { Text } from '@uicl/ui-core/dist';
import { InsuredStyledDiv, EraGridDiv, EraGridCenterDiv, EraGridEndDiv } from './styles';
import { IClaimService } from 'api/EraSearchDetailView/interface';
import { convertToDollar } from '../../../utils/constants';
import moment from 'moment';
import { DataItem } from '@uicl/ui-core/dist';

interface IProps {
  claimService: IClaimService;
}

const ClaimServiceComponent: React.FC<IProps> = ({ claimService }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimPaymentServiceInfoContainer">
      <InsuredStyledDiv>
        <EraGridDiv>
          <Text color="secondary" variant="small">
            Date Of Service
          </Text>

          <Text variant="smallBold">
            {claimService.dateOfService ? moment(claimService.dateOfService).format('M/DD/YYYY') : null}
          </Text>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridCenterDiv>
            <Text color="secondary" variant="small">
              Procedure Code
            </Text>

            <Text variant="smallBold">{claimService.procedureCode}</Text>
          </EraGridCenterDiv>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridEndDiv>
            <Text color="secondary" variant="small">
              Control Number
            </Text>

            <Text variant="smallBold">{claimService.controlNumber}</Text>
          </EraGridEndDiv>
        </EraGridDiv>
      </InsuredStyledDiv>

      <InsuredStyledDiv>
        <EraGridDiv>
          <Text color="secondary" variant="small">
            Charge Amount
          </Text>

          <Text variant="smallBold">
            {' '}
            {claimService.chargeAmount ? convertToDollar(claimService.chargeAmount) : null}
          </Text>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridCenterDiv>
            <Text color="secondary" variant="small">
              Payment Amount
            </Text>

            <Text variant="smallBold">
              {claimService.paymentAmount ? convertToDollar(claimService.paymentAmount) : null}
            </Text>
          </EraGridCenterDiv>
        </EraGridDiv>
        <EraGridDiv>
          <EraGridEndDiv>
            <Text color="secondary" variant="small">
              Allowed Amount
            </Text>

            <Text variant="smallBold">
              {claimService.allowedActualAmount ? convertToDollar(claimService.allowedActualAmount) : null}
            </Text>
          </EraGridEndDiv>
        </EraGridDiv>
      </InsuredStyledDiv>
      {(claimService.claimAdjustmentsCARC && claimService.claimAdjustmentsCARC.length > 0) ||
      (claimService.claimAdjustmentsRARC && claimService.claimAdjustmentsRARC.length > 0) ? (
        <>
          {' '}
          <Text variant="mediumBold">Service Adjustments</Text>
          <DataItem
            className="margin-top-bottom-10px"
            content={claimService.claimAdjustmentsCARC}
            size="small"
          ></DataItem>
          <DataItem
            className="margin-top-bottom-10px"
            content={claimService.claimAdjustmentsRARC}
            size="small"
          ></DataItem>
        </>
      ) : null}
    </div>
  );
};
export default ClaimServiceComponent;
