import { Deserializable } from './Deserializable';
import { stringToBoolean, stringToNumber } from '../utils/helpers';
export class UserDetail implements Deserializable {
  accountId: number | undefined = undefined;
  accountIsActive: boolean | undefined = undefined;
  allowAdvancedClaims: boolean | undefined = undefined;
  allowClaimInput: boolean | undefined = undefined;
  allowClaimStatusFree: boolean | undefined = undefined;
  allowCreateSubUsers: boolean | undefined = undefined;
  allowEFT: boolean | undefined = undefined;
  allowERA: boolean | undefined = undefined;
  allowEcfClaimUpload: boolean | undefined = undefined;
  allowEditEFTBankingInfo: boolean | undefined = undefined;
  allowEligibilityRetail: boolean | undefined = undefined;
  allowEraFree: boolean | undefined = undefined;
  allowPurchaseProducts: boolean | undefined = undefined;
  allowRevenueCycleInsights: boolean | undefined = undefined;
  allowSimpleAttachmentAllPayer: boolean | undefined = undefined;
  allowSimpleAttachmentSinglePayer: boolean | undefined = undefined;
  allowUpdateAccountProfile: boolean | undefined = undefined;
  allowUpdateEFTEnrollment: boolean | undefined = undefined;
  allowUpdateTaxIds: boolean | undefined = undefined;
  allowWebAppFree: boolean | undefined = undefined;
  allowX12ClaimUpload: boolean | undefined = undefined;
  claimGrabber: boolean | undefined = undefined;
  corporationId: number | undefined = undefined;
  enableEFTEnrollment: boolean | undefined = undefined;
  partnerCode: [] | undefined = undefined;
  privilegeIds: string | undefined = undefined;
  roleId: number | undefined = undefined;
  roleName: string | undefined = undefined;
  securityTokenId: number | undefined = undefined;
  taxIdArray: string[] | undefined = undefined;
  termsAndConditionsUpToDate: boolean | undefined = undefined;
  userId: number | undefined = undefined;
  userName: string | undefined = undefined;
  vendorId: number | undefined = undefined;
  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export class TokenData implements Deserializable {
  username: string | undefined = undefined;
  AccountIsActivated: boolean | undefined = undefined;
  AllowAdvancedClaims: boolean | undefined = undefined;
  AllowERA: boolean | undefined = undefined;
  AllowSimpleAttachmentAllPayer: boolean | undefined = undefined;
  AllowSimpleAttachmentSinglePayer: boolean | undefined = undefined;
  AllowClaimResubmission: boolean | undefined = undefined;
  AllowDBA: boolean | undefined = undefined;
  AllowRevenueCycleInsights: boolean | undefined = undefined;
  AllowClaimStatusFree: boolean | undefined = undefined;
  AllowEraFree: boolean | undefined = undefined;
  AllowEligibilityRetail: boolean | undefined = undefined;
  AllowWebAppFree: boolean | undefined = undefined;
  AllowPurchaseProducts: boolean | undefined = undefined;
  AllowCreateSubUsers: boolean | undefined = undefined;
  AllowUpdateAccountProfile: boolean | undefined = undefined;
  AllowUpdateTaxIds: boolean | undefined = undefined;
  AllowEditEFTBankingInfo: boolean | undefined = undefined;
  AllowUpdateEFTEnrollment: boolean | undefined = undefined;
  AllowEFT: boolean | undefined = undefined;
  EnableEFTEnrollment: boolean | undefined = undefined;
  AllowEcfClaimUpload: boolean | undefined = undefined;
  AllowX12ClaimUpload: boolean | undefined = undefined;
  ClaimGrabber: boolean | undefined = undefined;
  AllowClaimInput: boolean | undefined = undefined;
  AllowAttachmentUpload: boolean | undefined = undefined;
  TermsAndConditionsAccepted: boolean | undefined = undefined;
  RoleId: number | undefined = undefined;
  deserialize(input: any) {
    Object.assign(this, {
      ...input,
      AccountIsActivated: stringToBoolean(input.AccountIsActivated),
      AllowERA: stringToBoolean(input.AllowERA),
      AllowAdvancedClaims: stringToBoolean(input.AllowAdvancedClaims),
      AllowSimpleAttachmentAllPayer: stringToBoolean(
        input.AllowSimpleAttachmentAllPayer,
      ),
      AllowSimpleAttachmentSinglePayer: stringToBoolean(
        input.AllowSimpleAttachmentSinglePayer,
      ),
      AllowClaimResubmission: stringToBoolean(input.AllowClaimResubmission),
      AllowDBA: stringToBoolean(input.AllowDBA),
      AllowRevenueCycleInsights: stringToBoolean(
        input.AllowRevenueCycleInsights,
      ),
      AllowClaimStatusFree: stringToBoolean(input.AllowClaimStatusFree),
      AllowEraFree: stringToBoolean(input.AllowEraFree),
      AllowEligibilityRetail: stringToBoolean(input.AllowEligibilityRetail),
      AllowWebAppFree: stringToBoolean(input.AllowWebAppFree),
      AllowPurchaseProducts: stringToBoolean(input.AllowPurchaseProducts),
      AllowCreateSubUsers: stringToBoolean(input.AllowCreateSubUsers),
      AllowUpdateAccountProfile: stringToBoolean(
        input.AllowUpdateAccountProfile,
      ),
      AllowUpdateTaxIds: stringToBoolean(input.AllowUpdateTaxIds),
      AllowEditEFTBankingInfo: stringToBoolean(input.AllowEditEFTBankingInfo),
      AllowUpdateEFTEnrollment: stringToBoolean(input.AllowUpdateEFTEnrollment),
      AllowEFT: stringToBoolean(input.AllowEFT),
      EnableEFTEnrollment: stringToBoolean(input.EnableEFTEnrollment),
      AllowEcfClaimUpload: stringToBoolean(input.AllowEcfClaimUpload),
      AllowX12ClaimUpload: stringToBoolean(input.AllowX12ClaimUpload),
      ClaimGrabber: stringToBoolean(input.ClaimGrabber),
      AllowClaimInput: stringToBoolean(input.AllowClaimInput),
      AllowAttachmentUpload: stringToBoolean(input.AllowAttachmentUpload),
      TermsAndConditionsAccepted: stringToBoolean(
        input.TermsAndConditionsAccepted,
      ),
      RoleId: stringToNumber(input.roleid),
    });
    return this;
  }
}
