import { apiClient } from './setup';
import { IApiClient, IApiResult } from './interfaces';
import ApiClientWrapper from './ApiClientWrapper';

export const apiInstance = (dispatch?: any, config?: IApiConfiguration): IApiClient =>
  new ApiClientWrapper(apiClient, dispatch, config);

type NotificationFilter = (apiResult: IApiResult) => boolean;
export interface IApiConfiguration {
  suppressNotification?: boolean;
  notificationFilter?: NotificationFilter;
}
