import React from 'react';
import useScript from '../../hooks/useScript';
import styled from 'styled-components';
import { OneTrustFunction } from '../../utils/oneTrust';

const PrivacyPolicy = () => {
  useScript(
    'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js',
    OneTrustFunction(
      'https://privacyportal-cdn.onetrust.com/245e6dbf-4480-4d87-a1c2-ab41d35145c1/privacy-notices/20fb0477-e8b7-4128-a710-b871b4e957e2.json'
    )
  );
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '70px' }} />
        <div style={{ width: '85%' }}>
          <StyledDiv
            id="otnotice-20fb0477-e8b7-4128-a710-b871b4e957e2"
            className="otnotice"
            data-testid="privacy-policy-testid"
          ></StyledDiv>
        </div>
      </div>
    </>
  );
};

const StyledDiv = styled.div`
  width: 100vw;
  min-height: 100vh;
`;
export default PrivacyPolicy;
