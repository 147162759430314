import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  IAddNotificationPayload,
  IArchiveNotificationPayload,
  IInitialStateNotificationSlice,
  IMarkNotificationAsReadPayload,
  IMarkNotificationAsShownPayload,
  INotificationState,
} from './interfaces';

const defaultState = {
  lastNotificationId: 0,
  notifications: [],
};

const initialState = {
  ...defaultState,
} as IInitialStateNotificationSlice;

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    markNotificationAsShown: (state, action: PayloadAction<IMarkNotificationAsShownPayload>) => {
      const { payload } = action;
      state.notifications = state.notifications.map((n) =>
        n.id === payload.notificationId ? { ...n, isShown: true } : n
      );
    },
    addNotification: (state, action: PayloadAction<IAddNotificationPayload>) => {
      const { payload } = action;

      const newId = state.lastNotificationId + 1;
      const notification: INotificationState = {
        id: newId.toString(),
        isShown: false,
        isRead: false,
        title: payload.title,
        description: payload.description,
        dateTime: Date.now(),
        icon: payload.icon,
        additionalInfo: payload.additionalInfo,
      };
      state.notifications = [...state.notifications, notification];
      state.lastNotificationId = newId;
    },

    archiveNotification: (state, action: PayloadAction<IArchiveNotificationPayload>) => {
      const { payload } = action;

      state.notifications = state.notifications.filter((n) => n.id !== payload.notificationId);
    },

    archiveAllNotifications: (state) => {
      state.notifications = [];
    },

    markNotificationAsRead: (state, action: PayloadAction<IMarkNotificationAsReadPayload>) => {
      const { payload } = action;

      state.notifications = state.notifications.map((n) =>
        n.id === payload.notificationId ? { ...n, isRead: true } : n
      );
    },

    markAllNotificationsAsRead: (state) => {
      state.notifications = state.notifications.map((n) => ({ ...n, isRead: true }));
    },
  },
});

export const {
  addNotification,
  archiveNotification,
  archiveAllNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  markNotificationAsShown,
} = notificationSlice.actions;

export default notificationSlice.reducer;
