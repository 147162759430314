import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserSummary } from './interfaces';
import { IUserAccountInfo } from '../../api/user/interface';

const initialState = {
  firstName: '',
  lastName: '',
  accountId: '',
  allowableActions: [],
} as IUserSummary;

export const userSummarySlice = createSlice({
  name: 'userSummarySlice',
  initialState,
  reducers: {
    setUserSummary: (state, action: PayloadAction<IUserSummary>) => {
      const payload = action?.payload;

      state.firstName = payload?.firstName;
      state.lastName = payload?.lastName;
      state.allowableActions = payload?.allowableActions;
      state.accountId = payload?.accountId;
    },
  },
});

export const { setUserSummary } = userSummarySlice.actions;

export default userSummarySlice.reducer;

export const mapUserSummaryPayload = (payload: IUserAccountInfo): IUserSummary => {
  return {
    firstName: payload.firstName,
    lastName: payload.lastName,
    accountId: payload.accountId,
    allowableActions: payload.allowableActions?.map((a) => a.actionName),
  };
};
