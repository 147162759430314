import React from 'react';
import { Group, Card } from '@uicl/ui-core/dist';
import { DateFormat, FlexDiv } from '../../../../utils/constants';
import { TextWithLabel } from '../../../../utils/TextWithLabel';
import DateWrapper from '../../../../utils/DateWrapper';
import { IAttachmentFileResponse } from '../../../../api/claimAttachments/interface';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { Button } from '@uicl/ui-core/dist';
import LoadingPage from '../../../../components/LoadingSpinner';

interface IProps {
  attachmentImages: IAttachmentFileResponse[];
  isLoadingImages: boolean;
  isImageError: boolean;
}

const AttachmentFileDetails: React.FC<IProps> = ({ attachmentImages, isLoadingImages, isImageError }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [reactViewerImgSrc, setReactViewerImgSrc] = React.useState({ src: '' } as ImageDecorator);

  const mapAttachmentImageToViewerImage = (attachment: IAttachmentFileResponse): ImageDecorator => {
    const src: string = 'data:image/' + attachment?.fileMetadata?.fileExtension + ';base64,' + attachment.fileData;
    return { src } as ImageDecorator;
  };

  const handleViewClick = (attachment: IAttachmentFileResponse) => {
    setReactViewerImgSrc(mapAttachmentImageToViewerImage(attachment));
    setIsVisible(true);
  };

  const Details = isLoadingImages ? (
    <LoadingPage />
  ) : (
    <>
      {isImageError && (
        <p data-testid={'claim-attachment-files-error'}>
          There was an error fetching the image data. Please try again.
        </p>
      )}
      {!isImageError && (
        <div id={'attachment-files-container'} style={{ display: 'flex', flexDirection: 'column', gap: '35px' }}>
          {attachmentImages?.length === 0 ? <div>No attachments available</div> : null}
          {attachmentImages?.map((attachment, index) => {
            if (!attachment || !attachment.fileMetadata) return;

            const metadata = attachment.fileMetadata;
            const imageDateWrapper = new DateWrapper(metadata.imageDate);
            const fileSize = Math.ceil(metadata.fileSize / 1024);

            return (
              <Card key={'attachment-file-card' + index}>
                <FlexDiv
                  id={'attachment-details-' + index}
                  style={{ gap: '35px' }}
                  title={`File Id: ${metadata.attachmentFileId}`}
                >
                  <TextWithLabel
                    label={'Type of Attachment'}
                    text={metadata.typeOfAttachment}
                    labelVariant={'xxSmall'}
                  />
                  <TextWithLabel label={'Orientation'} text={metadata?.orientation} labelVariant={'xxSmall'} />
                  <TextWithLabel
                    label={'Image Date'}
                    text={imageDateWrapper.formatDateString(DateFormat.DisplayDate)}
                    labelVariant={'xxSmall'}
                  />
                  <TextWithLabel label={'File Name'} text={metadata?.fileName} labelVariant={'xxSmall'} />
                  <TextWithLabel label={'File Size'} text={fileSize + ' KB'} labelVariant={'xxSmall'} />
                  <Button
                    dataTestId={'view-attachment-image-button'}
                    buttonType="emphasized"
                    size="small"
                    name={'View'}
                    onClick={() => handleViewClick(attachment)}
                  />
                </FlexDiv>
              </Card>
            );
          })}
          {isVisible ? (
            <Viewer visible={isVisible} onClose={() => setIsVisible(false)} images={[reactViewerImgSrc]} />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );

  return (
    <Group
      dataTestId={'attachment-file-details'}
      title={'Attachment Details'}
      initialIsCollapsed
      isAccordion
      description={'All details of the attachments associated to this claim'}
    >
      {Details}
    </Group>
  );
};

export default AttachmentFileDetails;
