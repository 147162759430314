import React from "react";
import { Row, Col, Input, Text, RadioGroup, DateRangePicker } from "@uicl/ui-core/dist";
import { IEligibilityInquiryRequest } from "../../api/eligibilityInquiry/interface";
import moment from "moment";

interface IProps {
    eligibilityInquiryRequestBody: IEligibilityInquiryRequest;
    handleInputChange: (e: any) => void;
    isServiceType: boolean;
    setIsServiceType: (value: boolean) => void;
}

const EncounterDetailsComponent: React.FC<IProps> = ({ eligibilityInquiryRequestBody, handleInputChange, isServiceType, setIsServiceType }) => {
    return (
        <>
            <Row>
                <Col sm={3}>
                    <Text variant="largeBold" className="eligibility-inquiry-header">Encounter Details</Text>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <DateRangePicker
                        aria-label="dateOfService"
                        dataTestId="test-dateOfService"
                        domID="dateOfService"
                        label="Date of Service *"
                        size="medium"
                        openDirection="down"
                        showCalendarIcon={true}
                        initialStartDate={eligibilityInquiryRequestBody?.encounterDetails?.dateOfService?.startDate
                            ? moment(eligibilityInquiryRequestBody?.encounterDetails?.dateOfService?.startDate)
                            : undefined}
                        initialEndDate={eligibilityInquiryRequestBody?.encounterDetails?.dateOfService?.endDate
                            ? moment(eligibilityInquiryRequestBody?.encounterDetails?.dateOfService?.endDate)
                            : undefined}
                        /* istanbul ignore next */
                        onDatesChange={(e) =>
                        /* istanbul ignore next */ {
                            const event = { target: { value: e } };
                            if (event.target.value.startDate == null)
                                eligibilityInquiryRequestBody.encounterDetails.dateOfService.startDate = null;
                            else
                                eligibilityInquiryRequestBody.encounterDetails.dateOfService.startDate = event.target.value.startDate.format('YYYY-MM-DD');
                            if (event.target.value.endDate == null)
                                eligibilityInquiryRequestBody.encounterDetails.dateOfService.endDate = null;
                            else
                                eligibilityInquiryRequestBody.encounterDetails.dateOfService.endDate = event.target.value.endDate.format('YYYY-MM-DD');
                        }
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <RadioGroup
                        dataTestId="test-serviceTypeOrProcedureCode"
                        checkedItem={{ id: isServiceType ? '1' : '2' }}
                        orientation="horizontal"
                        size="small"
                        items={[
                            { id: '1', label: 'Service Type Code' },
                            { id: '2', label: 'Procedure Code' }
                        ]}
                        /* istanbul ignore next */
                        onChange={(e) => { e.id === '1' ? setIsServiceType(true) : setIsServiceType(false); }}
                    />
                </Col>
            </Row>
            <Row>
                {isServiceType && <Col sm={3}>
                    <Input
                        aria-label="encounterServiceTypeCode"
                        dataTestId="test-encounterServiceTypeCode"
                        domID="encounterServiceTypeCode"
                        name="encounterDetails.serviceTypeCode"
                        label="Service Type Code *"
                        placeholder="Service Type Code"
                        size="medium"
                        maxLength={2}
                        errorMessage="Invalid Service Type Code"
                        regex="^[a-zA-Z0-9]{1,2}$"
                        value={eligibilityInquiryRequestBody?.encounterDetails?.serviceTypeCode}
                        onChange={handleInputChange} />
                </Col>}
                {!isServiceType && <Col sm={3}>
                    <Input
                        aria-label="encounterProcedureCode"
                        dataTestId="test-encounterProcedureCode"
                        domID="encounterProcedureCode"
                        name="encounterDetails.procedureId"
                        label="Procedure Code *"
                        placeholder="Procedure Code"
                        size="medium"
                        maxLength={5}
                        errorMessage="Invalid Procedure Code"
                        regex="^D[0-9]{4}$"
                        value={eligibilityInquiryRequestBody?.encounterDetails?.procedureId}
                        onChange={handleInputChange} />
                </Col>}
            </Row>
        </>
    )
};

export default EncounterDetailsComponent;