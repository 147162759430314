import { apiInstance } from '../apiInstance';
import { IEraView835Data, IEraView835DataResponse } from './interface';

export const getView835Data = (transactionId: string, dispatch?: any): Promise<IEraView835DataResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .get(`/era/${transactionId}/x12`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
