import { IUsersInfo } from '../../api/onboarding/interface';
import XCircle from '../../components/XCircle';
import { StyledBox } from './style';
import { Text, Button, Input } from '@uicl/ui-core/dist';
import React from 'react';

const userInfoInputArray = (userInfo: IUsersInfo) => {
  return [
    {
      key: 'username',
      label: 'Username',
      domID: 'username',
      width: '30%',
      marginBottom: 10,
      value: userInfo.username,
    },
    {
      key: 'firstName',
      label: 'First Name',
      domID: 'first-name',
      width: '30%',
      marginBottom: 10,
      value: userInfo.firstName,
    },
    {
      key: 'lastName',
      label: 'Last Name',
      domID: 'last-name',
      width: '30%',
      marginBottom: 10,
      value: userInfo.lastName,
    },
  ];
};

interface IProps {
  users: IUsersInfo[];
  updateUser: any;
  deleteUserByIndex: any;
  addUser: any;
}

export const RenderUserInfo: React.FC<IProps> = ({ users, updateUser, deleteUserByIndex, addUser }) => {
  const handleOnChange = (e: any, key: string, userIndex: number) => {
    const newValues = { [key]: e.target.value } as Partial<IUsersInfo>;
    updateUser(newValues, userIndex);
  };

  const renderAddUser = (userInfo: IUsersInfo, userIndex: number) => (
    <div key={`userInfoKey-${userIndex}`}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          border: 'solid',
          borderRadius: '5px',
          padding: '1%',
          margin: '1%',
          position: 'relative',
        }}
      >
        {userInfoInputArray(userInfo).map((item) => {
          return (
            <div key={`userInfoInputKey-${item.key}`} style={{ width: item.width, marginBottom: item.marginBottom }}>
              <Input
                autoComplete="off"
                dataTestId={`userInfoInputKey-${item.domID}-${userIndex}`}
                domID={`userInfoInputKey-${item.domID}-${userIndex}`}
                label={item.label}
                initialValue={item.value}
                onChange={(e: any) => handleOnChange(e, item.key, userIndex)}
              />
            </div>
          );
        })}

        <XCircle dataTestId={`delete-user-button-${userIndex}`} onClick={() => deleteUserByIndex(userIndex)} />
      </div>
    </div>
  );

  return (
    <>
      <StyledBox>
        <Text variant="large">User Info</Text>
      </StyledBox>
      <div style={{ paddingTop: '1%', paddingBottom: '1%' }}>
        <Button
          dataTestId="test-addUserButton"
          domID="addUserButton-id"
          name="Add User"
          onClick={addUser}
          size="medium"
          type="button"
        />
      </div>
      {users.map((user, index) => renderAddUser(user, index))}
    </>
  );
};
