import React from 'react';
import { Text } from '@uicl/ui-core/dist';
import { IEraSearchDetailResponse } from 'api/EraSearchDetailView/interface';
import ClaimPaymentPanelComponent from './ClaimPaymentPanelComponent';

interface IProps {
  eraSearchDetailData: IEraSearchDetailResponse;
}

const ClaimPaymentInfoComponent: React.FC<IProps> = ({ eraSearchDetailData }) => {
  return (
    <div style={{ borderBottom: '2px solid #e0e0e0' }} data-testid="claimPaymentInfoContainer">
      <div style={{ marginBottom: 10, marginTop: 10 }}>
        <Text variant="largeBold">Claim Payment Information</Text>
      </div>
      {eraSearchDetailData?.claimPaymentInformation?.map((claimPayment, index) => (
        <ClaimPaymentPanelComponent claimPayment={claimPayment} indexNumber={index + 1}></ClaimPaymentPanelComponent>
      ))}
    </div>
  );
};
export default ClaimPaymentInfoComponent;
