import { IEligibilityOverviewResponse } from 'api/HistoricalTransactionDetailView/interfaces';
import { apiInstance } from '../apiInstance';
import { IEligibilityInquiryRequestBody } from './interface';


export const postEligibilityInquiry = (
  eligibilityInquiryRequestBody: IEligibilityInquiryRequestBody,
  dispatch?: any
): Promise<IEligibilityOverviewResponse> => {
  return new Promise((resolve, reject) => {
    apiInstance(dispatch)
      .post(`/eligibilityinquiry/`, eligibilityInquiryRequestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
