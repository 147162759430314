import React, { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../reduxStore/reduxHooks';
import { useAuth } from 'react-oidc-context';
import { checkUserPermission } from '../reduxStore/userPermissionsSlice/checkUserPermission';
import { IPermissionResourceType } from '../reduxStore/userPermissionsSlice/interfaces';

interface TProps {
  children: React.ReactElement;
  permissionResourceType?: IPermissionResourceType;
}

const PrivateRoute: React.FC<TProps> = ({ children, permissionResourceType }) => {
  const permissions = useAppSelector((state) => state.userPermissionsSlice.permissions);

  const navigate = useNavigate();

  // this is a react-router-dom hook
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, user, isAuthenticated } = useAuth();

  useEffect(() => {
    if (user?.access_token) {
      setSearchParams({});
    }

    // TODO when all routes are configured we need to deny when these are undefined
    if (permissionResourceType && permissions) {
      const hasPermission = checkUserPermission(permissionResourceType, permissions);

      if (!hasPermission) {
        navigate('/permission-denied');
      }
    }
  }, [user?.access_token, isAuthenticated, permissionResourceType]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    if (isAuthenticated) {
      return children;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

export default PrivateRoute;
