import React, { useState } from 'react';
import { Button, IconButton, Close, Text } from '@uicl/ui-core/dist';
import ClaimInformation from './ClaimInformation';
import PayerIdentification from './PayerIdentification';
import InsuredComponent from './InsuredComponent';
import PatientComponent from './PatientComponent';
import ProviderComponent from './ProviderComponent';
import ClaimFormComponent from './ClaimFormComponent';
import ClaimTreatmentComponent from './ClaimTreatmentComponent';
import { IClaimViewResponse } from '../../../../api/claimView/interface';
import LoadingPage from '../../../../components/LoadingSpinner';
import Drawer from '../../../../components/Drawer';
import ClaimStatusComponent from './ClaimStatusComponent';

interface IProps {
  onCloseDrawer: () => void;
  claimViewData: IClaimViewResponse;
  isFetching: boolean;
}

const ClaimDrawer: React.FC<IProps> = ({ onCloseDrawer, claimViewData, isFetching }) => {
  const [isClaimDetailView, setIsClaimDetailView] = useState(true);

  const handleClaimToggleView = () => setIsClaimDetailView((prev) => !prev);

  const renderView = () => {
    if (isClaimDetailView) {
      return (
        <>
          <ClaimInformation claimViewData={claimViewData} />
          <PayerIdentification claimViewData={claimViewData} />
          <InsuredComponent claimViewData={claimViewData} />
          <PatientComponent claimViewData={claimViewData} />
          <ProviderComponent claimViewData={claimViewData} />
          <ClaimFormComponent claimViewData={claimViewData} />
          <ClaimTreatmentComponent claimViewData={claimViewData} />
        </>
      );
    } else {
      return <ClaimStatusComponent claimViewData={claimViewData} />;
    }
  };
  return (
    <Drawer percentageView={isClaimDetailView} containerWidth={'60vw'}>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Text variant="large">{isClaimDetailView ? 'Claim Details' : 'Claim Status'}</Text>
            <div style={{ display: 'flex' }}>
              <Button buttonType="emphasized" onClick={handleClaimToggleView} dataTestId="claimViewButton-testid">
                {isClaimDetailView ? 'Claim Status' : 'Claim Detail'}
              </Button>
              <div style={{ marginLeft: 5, marginTop: 5 }}>
                <IconButton
                  dataTestId="claimdrawer-close-drawer-button"
                  icon={Close}
                  size="medium"
                  onClick={onCloseDrawer}
                />
              </div>
            </div>
          </div>
          {Object.entries(claimViewData).length > 0 && renderView()}
        </>
      )}
    </Drawer>
  );
};

export default ClaimDrawer;
