import integrations from '../integrations';

export const sessionToken =
  `oidc.user:${integrations.sentinel.authority}:${integrations.sentinel.client_id}`;

export const getToken = () => {
  const sessionStorageString = sessionStorage.getItem(sessionToken);
  let data = undefined;
  if (sessionStorageString) {
    data = JSON.parse(sessionStorageString);
    return data.access_token;
  }
};
