import { IPagingOptions } from '../../allOtherInterfaces/pagingOptionInterface';

export enum ESortingDirectionEraSearch {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export type TSortingFieldEraSearch =
  | 'dateOfService'
  | 'taxId'
  | 'payerName'
  | 'payeeName'
  | 'claimNumber'
  | 'charged'
  | 'paid'
  | 'checkEftNo'
  | 'is835Data';

interface IDateFilter {
  startDate: string;
  endDate: string;
}

interface ISorting {
  direction: string;
  fields: string[];
}

export interface IEraSearchRequestBody {
  limit: number;
  offset: number;
  transactionId: string;
  taxId: string;
  payerId: string;
  insuredId: string;
  checkEftNumber: string;
  checkEftAmount: number | null;
  billingNpi: string;
  sorting: ISorting;
  dateRangeFilter: {
    field: string;
    startDate: string;
    endDate: string;
  };
  [key: string]: string | number | IDateFilter | ISorting | null | boolean;
}

export interface IEraSearchResponse {
  filters: {
    field: string;
    startDate: string;
    endDate: string;
  };
  totalCount: number;
  resultCount: number;
  pagingOptions: IPagingOptions;
  results: IEraSerchResults[];
}

export interface IEraSerchResults {
  transactionId: string;
  payerId: string;
  payeeName: string;
  checkEftDate: string;
  charged: number;
  paid: number;
  checkEftNumber: string;
  is835Data: boolean;
  view835Data: string;
}

export enum EDateFilter {
  STRART = 'start',
  END = 'end',
}

export interface DateFilter {
  option: EDateFilter;
  value: string;
}
export interface IHasEraSearchInputErros {
  hasErrorMPayerID: boolean;
  hasErrorMEftNo: boolean;
  hasErrorMEftAmt: boolean;
  hasErrorMInsureID: boolean;
  hasErrorMBillingNPI: boolean;
  hasErrorMTaxID: boolean;
  hasErrorMTransID: boolean;
  [key: string]: boolean;
}
