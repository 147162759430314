import React, { Dispatch, memo, useState, useEffect, SyntheticEvent } from 'react';
import { Grid } from '@uicl/ui-core/dist';
import { historicalSearchTableColumns } from './HistoricalTransactionsTableColumns';
import {
  ESortingDirectionTransactionsSearch,
  IHistoricalTransactionsSerchResults,
} from '../../api/HistoricalTransactions/interfaces';
import { DefaultExpandList } from '../../api/HistoricalTransactionDetailView/interfaces';
import HistoricalTransactionDetailDrawer from './HistoricalTransactionDetailDrawer';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { setAppError } from '../../reduxStore/authSlice';
import { x12TraceType } from '../../utils/constants';
import {
  getEligibilityOverviewDetails,
  getHistoricalTransactionFileData,
} from '../../api/HistoricalTransactionDetailView';
import { IEligibilityOverviewResponse } from 'api/HistoricalTransactionDetailView/interfaces';
import moment from 'moment';

enum tableSortingDirection {
  SORT_ASCENDING = 'SORT_ASCENDING',
  SORT_DESCENDING = 'SORT_DESCENDING',
}
interface IProps {
  onClickThrough: (e: any, row: any) => void;
  historicalSearchResult: IHistoricalTransactionsSerchResults[];
  openX12Transaction: boolean;
  openPrintPreview: boolean;
  isFixedHeader: boolean;
  onCloseDrawer: () => void;
  onPrintPreviewClick: () => void;
  onClosePrintPreview: () => void;
  transactionId: string;
  timeOfTransaction: string;
  setSortingDirection: Dispatch<React.SetStateAction<ESortingDirectionTransactionsSearch>>;
  setSortingField: Dispatch<React.SetStateAction<string>>;
  sortingField: string;
  setEligibilityResponseDetailsBody: Dispatch<React.SetStateAction<IEligibilityOverviewResponse | undefined>>;
  eligibilityResponseDetailsBody?: IEligibilityOverviewResponse | undefined;
  defaultExpand: DefaultExpandList;
  setDefaultExpand: any;
  handlePanelChange: (
    panel:
      | 'payer'
      | 'provider'
      | 'subscriber'
      | 'dependents'
      | 'coveragetype'
      | 'coveragedates'
      | 'deductiblemaximum'
      | 'planprovision'
      | 'coverage'
      | 'frequencylimitation'
      | 'agelimitation'
      | 'aaaerror'
  ) => (event: React.SyntheticEvent, expanded: boolean) => void;
}
const HistoricalTransactionsaSearchTableData: React.FC<IProps> = ({
  onClickThrough,
  historicalSearchResult,
  openX12Transaction,
  openPrintPreview,
  isFixedHeader,
  onCloseDrawer,
  onPrintPreviewClick,
  onClosePrintPreview,
  transactionId,
  timeOfTransaction,
  setSortingDirection,
  setSortingField,
  sortingField,
  setEligibilityResponseDetailsBody,
  eligibilityResponseDetailsBody,
  defaultExpand,
  setDefaultExpand,
  handlePanelChange,
}) => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [tabContent, setTabContent] = useState('');
  const [traceType, setTraceType] = useState('');

  useEffect(() => {
    if (openX12Transaction) {
      const firstLoadX12Select = x12TraceType.at(0);
      if (firstLoadX12Select) {
        handleFileData(firstLoadX12Select.value);
        if (firstLoadX12Select.value == 'Overview') {
          getEligibilityDetails(firstLoadX12Select.value);
        }
      }
    }
  }, [openX12Transaction]);

  const getEligibilityDetails = async (traceTypeParam: string) => {
    try {
      if (traceTypeParam == 'Overview') {
        const responseDetails = await getEligibilityOverviewDetails(transactionId, dispatch);
        setEligibilityResponseDetailsBody(responseDetails);
      }
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
      setTabContent('');
      setIsFetching(false);
      setEligibilityResponseDetailsBody(undefined);
    }
  };
  const handleFileData = async (traceTypeParam: string) => {
    try {
      setIsFetching(true);
      setTraceType(traceTypeParam);
      if (traceTypeParam != 'Overview') {
        const response = await getHistoricalTransactionFileData(transactionId, traceTypeParam, dispatch);
        const base64Conversion = decodeURIComponent(escape(atob(response.fileData)));
        const splitX12Data = base64Conversion.split('~');
        const fileData = splitX12Data.join('~' + '\n');

        setTabContent(fileData);
      }
      setIsFetching(false);
    } catch (err) {
      const error = err as Error;
      dispatch(setAppError({ message: error.message, isOpen: true }));
      setTabContent('');
      setIsFetching(false);
    }
  };

  const handleSave = () => {
    const content = tabContent;
    const blob = new Blob([content], { type: 'text/plain' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${traceType}_${transactionId}_X12.txt`;
    document.body.appendChild(link);
    link.click();
  };

  const handleMapping = () =>
    historicalSearchResult.map((item, index) => {
      return {
        transactionId: item.transactionId,
        patientLastName: item.patientLastName,
        patientFirstName: item.patientFirstName,
        insuredId: item.insuredId,
        payerId: item.payerId,
        payerName: item.payerName,
        errorCode: item.errorCode,
        categoryCode: item.categoryCode,
        category: item.category,
        hippaCode: item.hippaCode,
        timeoftransaction: moment.utc(item.timeOfTransaction).local().format('MM/DD/yyyy HH:mm:ss'),
        vendorId: item.vendorId,
        userName: item.userName,
        taxId: item.taxId,
        renderingNpi: item.renderingNpi,
        totalseconds: item.totalSeconds + 's',
        payerseconds: item.payerSeconds + 's',
        instance: item.instance,
        payerPayloadID: item.payerPayloadID,
        payerTimeStamp: item.payerTimeStamp,
      };
    });
  const onSort = (column: any, direction: any, records: any) => {
    const sorting = direction.sortingKey.split('|');
    const fieldName = sorting[0];
    const directionName =
      sorting[1] == tableSortingDirection.SORT_ASCENDING
        ? ESortingDirectionTransactionsSearch.ASCENDING
        : ESortingDirectionTransactionsSearch.DESCENDING;

    setSortingDirection(directionName);
    setSortingField(fieldName);
  };
  return (
    <div>
      <Grid
        dataTestId="historical-transaction-grid"
        domID="historical-transaction-grid"
        initialSortingKey={`${sortingField}|SORT_DESCENDING`}
        isConfigurable={false}
        onClickThrough={onClickThrough}
        records={handleMapping()}
        selectAllCheckboxDomID="select-all-test-id"
        selectionKey="text"
        supportSelection={false}
        // @ts-ignore
        columns={historicalSearchTableColumns}
        isFixedHeader={true}
        onSortGridColumn={onSort}
        maxHeight="480px"
      />
      {openX12Transaction && (
        <HistoricalTransactionDetailDrawer
          openPrintPreview={openPrintPreview}
          onCloseDrawer={onCloseDrawer}
          onPrintPreviewClick={onPrintPreviewClick}
          onClosePrintPreview={onClosePrintPreview}
          transactionId={transactionId}
          timeOfTransaction={timeOfTransaction}
          handleFileData={handleFileData}
          isFetching={isFetching}
          tabContent={tabContent}
          traceType={traceType}
          onSave={handleSave}
          eligibilityResponse={eligibilityResponseDetailsBody}
          isFixedHeader={isFixedHeader}
          defaultExpand={defaultExpand}
          setDefaultExpand={setDefaultExpand}
          handlePanelChange={handlePanelChange}
        />
      )}
    </div>
  );
};
export default memo(HistoricalTransactionsaSearchTableData);
