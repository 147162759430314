import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '@uicl/ui-core/dist';
import webstorageManager from '../../utils/webstorage-manager';
import { useAppDispatch } from '../../reduxStore/reduxHooks';
import { clearAuthStoredData } from '../../reduxStore/authSlice';
import { clearUserStoredData } from '../../reduxStore/userSlice';

export const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearAuthStoredData());
    dispatch(clearUserStoredData());
    webstorageManager.clear();
  }, []);

  return (
    <Modal domID="no-auth" isOpen>
      <Container>
        <p data-testid="logout-container">You are being logged out</p>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 2rem;
`;

export default Logout;
